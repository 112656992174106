const samplePaymentMethodData = [
  {
    id: "src_123",
    type: "card",
    billing_details: {
        address: {
            postal_code: "12345",
        },
        name: "ABC",
    },
    card: {
        brand: "Visa",
        exp_month: 11,
        exp_year: 2023,
        fingerprint: "lk23qr",
        last4: 1234,
    },
  },
  {
    id: "src_246",
    type: "card",
    billing_details: {
        address: {
            postal_code: "12345",
        },
        name: "ABC",
    },
    card: {
        brand: "Visa",
        exp_month: 11,
        exp_year: 2019,
        fingerprint: "lk2j3q5",
        last4: 1234,
    },
  },
  {
    id: "src_369",
    type: "card",
    billing_details: {
        address: {
            postal_code: null,
        },
        name: "ABC",
    },
    card: {
        brand: "MC",
        exp_month: 11,
        exp_year: 2020,
        fingerprint: "2qo35j",
        last4: 5678,
    },
  },
];

export default samplePaymentMethodData;