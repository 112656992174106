import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import ErrorDisplay from './ErrorDisplay';

import { ENDPOINTS, postData, fakeSetAuthenticated } from '../utils/fetching';
import { LOCAL_MODE, PAGES } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

class Login extends Component {

  state = {
    isPosting: false,
    error: null,
    redirectToReferrer: false,
  };

  submitLoginForm = (event) => {
    event.preventDefault();

    if (LOCAL_MODE) {
      fakeSetAuthenticated(true);
      this.setState({ error: null, redirectToReferrer: true });
      return;
    }

    this.setState({ isPosting: true });
    const formData = new FormData(event.target);
    postData(ENDPOINTS.login, formData)
      .then((json) => {
        console.log(json);
        this.setState({ error: null, isPosting: false, redirectToReferrer: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error, isPosting: false });
      });
  };

  render() {
    const { source } = this.props.location.state || { source: { pathname: PAGES.home } };
    const { redirectToReferrer, isPosting, error } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={source} push />;
    }

    return (
      <section className="Login section jnf-first">
        <div className="container jnf-narrow-center-wrapper">
          <h1>Log in</h1>
          <form name="loginform" onSubmit={this.submitLoginForm}>

            <div className="field">
              <label className="label" htmlFor="email">Email</label>
              <div className="control">
                <input className="input" type="email" name="email" autoComplete="user email" required />
              </div>
              <div className="help"></div>
            </div>
            <div className="field">
              <label className="label" htmlFor="password">Password</label>
              <div className="control">
                <input className="input" type="password" name="password" autoComplete="current-password" required />
              </div>
              <div className="help is-danger"><ErrorDisplay errors={[error]} /></div>
            </div>

            <div>
              <button type="submit" name="submitbutton" className="button is-primary">Log in</button>
            </div>

          </form>
          <Link to={PAGES.forgotPassword}>Forgot password?</Link>
          <LoadingIndicator isLoading={isPosting} />
        </div>
      </section>
    );
  }
}

export default Login;
