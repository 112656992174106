import React, { Component } from 'react';

import { getURL, ENDPOINTS } from '../utils/fetching.js';
import LoadingIndicator from '../utils/LoadingIndicator';
import { LOCAL_MODE } from '../utils/constants';

import ErrorDisplay from './ErrorDisplay';


import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();


class StoreNumberPicker extends Component {

  state = {
    numbers: [],
    isPending: false,
    error: null,
  };

  componentDidUpdate(prevProps) {
    const prevAC = prevProps.areaCode ? prevProps.areaCode.area_code : null;
    const curAC = this.props.areaCode ? this.props.areaCode.area_code : null;
    if (prevAC !== curAC) {
      this.fetchAvailableNumbers();
    }
  }

  // TWILIO response: {"available_phone_numbers": { "phone_number": "+15552121234", "friendly_name": "+1 555-212-1234"}}
  fetchAvailableNumbers = () => {

    console.log("fetching available numbers");

    if(LOCAL_MODE) {
      this.setState({ error: null, isPending: false, numbers: [
        {
          phone_number: "+15552121234",
          friendly_name: "+1 555-212-1234",
        },
        {
          phone_number: "+15557778900",
          friendly_name: "+1 555-777-8900",
        },
      ]});

      return;
    }

    const { areaCode } = this.props;

    console.log("fetching for areaCode:", areaCode);

    if(!areaCode) {
      this.setState({ number: [] });
      return;
    }

    const { area_code, region_code } = areaCode;
    this.setState({ isPending: true });
    getURL(ENDPOINTS.availableNumbers, { area_code, region_code })
      .then((json) => {
        console.log("result from fetch area codes:", json);
        const { available_phone_numbers: numbers } = json;
        this.setState({ numbers, isPending: false, error: null});
      })
      .catch((error) => {
        this.setState({ isPending: false, error })
      });
  };

  render() {
    const { areaCode, cartNumber, handleNumberClicked } = this.props;
    const { region_code } = areaCode || { region_code: null };
    const { numbers, isPending, error } = this.state;
    console.log("rendering number picker", areaCode, numbers, isPending, error);

    const nbNumber = cartNumber ? phoneUtil.parseAndKeepRawInput(cartNumber, region_code) : null;
    const cartNumberFormatted = nbNumber ? phoneUtil.format(nbNumber, PhoneNumberFormat.INTERNATIONAL) : null;

    if(cartNumber) {
      return (
        <div className="StoreNumberPicker">
          <h3>Selected Number</h3>
          <div className="level is-mobile">
            <div className="level-left">
              {cartNumberFormatted}
            </div>
            <div className="level-right">
              <button className="jnf-outline-button" type="button" onClick={handleNumberClicked}>Change</button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="StoreNumberPicker">
        <h3>Available Numbers</h3>
        <ErrorDisplay errors={[error]} type="bar" />
        <LoadingIndicator isLoading={isPending} />
        { !areaCode &&
          <p>Please choose an area code to see available numbers.</p>
        }
        { areaCode && numbers &&
          <ul className="jnf-clickable-list">
            {numbers.map((num) => (
              <li key={num.phone_number}>
                <a href="/" data-fax-number={num.phone_number} onClick={handleNumberClicked}>
                  <div className="jnf-clickable-list-item">
                    {num.friendly_name}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        }
        { areaCode && !isPending && numbers && numbers.length === 0 &&
          <div className="jnf-area-code-no-numbers">
            <p>Sorry, but currently no numbers are available in this area code.</p>
            <p>Please choose a different area code and try again.</p>
          </div>
        }
      </div>
    );
  }
}

export default StoreNumberPicker;
