import React from 'react';

import Download from './Download';
import Inbox from './Inbox';


const defaultProps = {
  className: 'jnf-icon',
  fill: 'currentColor', // https://stackoverflow.com/questions/13000682/how-do-i-have-an-svg-image-inherit-colors-from-the-html-document
  //fill: '#2FA5FE',
  pointerEvents: 'none', // https://www.smashingmagazine.com/2018/05/svg-interaction-pointer-events-property/
};


function Icon(props) {
  props = {...defaultProps, ...props};

  switch(props.name) {
    case "download": return <Download {...props} />;
    case "inbox": return <Inbox {...props} />;
    default: return null;
  };
}

export default Icon;


