import React from 'react';

function ModalFooter({ handleCancel, printContent, downloadURL, filename }) {
  const fname = filename ? filename : "fax.pdf";
  return (
    <div className="ModalFooter level is-mobile">
      <div className="level-left">
        <button className="button" type="button" onClick={handleCancel}>Cancel</button>
      </div>
      {downloadURL &&
        <div>
          <a className="button" type="button" href={downloadURL} download={fname}>Download</a>
        </div>
      }
      <div className="level-right">
        <button className="button is-primary is-outlined" type="button" onClick={printContent}>
          <span>Print</span>
          <span className="icon">
            <i className="jnf-icon-print" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ModalFooter;
