import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

import { LOCAL_MODE } from './constants';
import { CREDIT_PACKS, getTotalCredits, getTotalPrice } from '../utils/creditPacks';
import SUBSCRIPTIONS from '../utils/subscriptions';

// react-ga: https://github.com/react-ga/react-ga
// Google Analytics Event Tracking: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
// Google Analytics Page Tracking: https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
// Google Analytics Enhanced Ecommerce: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce

const GA_ID = "UA-5448337-12";
ReactGA.initialize(GA_ID, { debug: LOCAL_MODE });


class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

const RouteTracker = () => (
  <Route component={GoogleAnalytics} />
);


const gaEvent = {
  purchase: (cart, promoCode, promoAmount, promoRate) => {
    console.log("Promo data:", promoCode, promoAmount, promoRate);
    const credits = getTotalCredits(cart);
    const priceInDollars = (getTotalPrice(cart) * (1 - promoRate) - promoAmount).toFixed(2);
    const priceInCents = Math.round(priceInDollars * 100);

    const twoDigitString = value => { return value < 10 ? '0' + value : value.toString() };
    const d = new Date();
    const dateTimeTransactionID =
      d.getFullYear() + "-" +
      twoDigitString(d.getMonth() + 1) + "-" +
      twoDigitString(d.getDate()) + " " +
      twoDigitString(d.getHours()) + ":" +
      twoDigitString(d.getMinutes()) + ":" +
      twoDigitString(d.getSeconds()) + " " +
      Math.random().toString(36).substring(2, 15); // https://gist.github.com/6174/6062387

    console.log("EC SETACTION PURCHASE:");
    // console.log("cart", cart);
    ReactGA.plugin.require('ec');
    for (let packid in cart) {
      console.log("packid", packid);
      console.log("cart[packid]", cart[packid]);
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: packid,
        name: CREDIT_PACKS[packid].name,
        category: 'Fax Credit Pack',
        price: CREDIT_PACKS[packid].price,
        quantity: cart[packid]
      });
    }
    ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
      id: dateTimeTransactionID,
      affiliation: 'JotNot Fax Web App',
      revenue: priceInDollars,
      coupon: promoCode,
    });
    ReactGA.event({
      category: 'Purchase',
      action: 'Purchase completed',
      label: (LOCAL_MODE ? 'local mode ' : '') + 'purchase of ' + credits + ' credits',
      value: priceInCents,
      nonInteraction: true,
    });
  },

  subscribe: (subID, promoCode, promoAmount, promoRate) => {
    console.log("Promo data:", promoCode, promoRate);
    console.log("Subscription ID:", subID);

    if(!subID) {
      return;
    }
    const subData = SUBSCRIPTIONS[subID];
    console.log("Subscription data:", subData);
    const { price, name, duration } = subData;
    const priceInDollars = (price * (1 - promoRate) - promoAmount).toFixed(2);
    const priceInCents = Math.round(priceInDollars * 100);
    console.log("price in dollars:", priceInDollars);

    const twoDigitString = value => { return value < 10 ? '0' + value : value.toString() };
    const d = new Date();
    const dateTimeTransactionID =
      d.getFullYear() + "-" +
      twoDigitString(d.getMonth() + 1) + "-" +
      twoDigitString(d.getDate()) + " " +
      twoDigitString(d.getHours()) + ":" +
      twoDigitString(d.getMinutes()) + ":" +
      twoDigitString(d.getSeconds()) + " " +
      Math.random().toString(36).substring(2, 15); // https://gist.github.com/6174/6062387

    console.log("EC SETACTION PURCHASE:");
    ReactGA.plugin.require('ec');
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: subID,
      name: name,
      category: 'Fax Subscription',
      price: price,
      quantity: 1
    });
    ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
      id: dateTimeTransactionID,
      affiliation: 'JotNot Fax Web App',
      revenue: priceInDollars,
      coupon: promoCode,
    });
    ReactGA.event({
      category: 'Purchase',
      action: 'Subscription purchased',
      label: (LOCAL_MODE ? 'local mode ' : '') + 'purchase of ' + name + (duration === "year" ? ' annual ' : ' monthly ') + 'subscription',
      value: priceInCents,
      nonInteraction: true,
    });
  },
};

export { gaEvent };
export default RouteTracker;
