import React, { Component } from 'react';

import HomeStatus from './HomeStatus';
import Balance from './Balance';
import FaxNumberList, { getUnexpiredNumbers } from './FaxNumberList';
import ErrorDisplay from './ErrorDisplay';

class Home extends Component {

  state = {
    statusError: null,
  };

  componentDidMount() {
    this.props.fetchUser && this.props.fetchUser();
  }

  handleStatusError = (error) => {
    this.setState({ statusError: error });
  };

  render() {
    const { statusError } = this.state;
    const { credits, creditsError, faxNumbers, faxNumbersError } = this.props;

    const filteredNumbers = getUnexpiredNumbers(faxNumbers);

    return (
      <div className="Home">
        <ErrorDisplay errors={[faxNumbersError, creditsError, statusError]} type="bar" />

        <section className="section jnf-first">
          <div className="container">
            <h1>Home</h1>
          </div>
        </section>

        {(filteredNumbers && filteredNumbers.length > 0)
          ? <FaxNumberList faxNumbers={filteredNumbers} />
          : <Balance credits={credits} showAddCreditsButton={true} />
        }

        <HomeStatus errorCallback={this.handleStatusError} />

      </div>
    );
  }
}

export default Home;
