
const SUBSCRIPTIONS = Object.freeze({
  "com.mobitech3000.JotNotFaxWeb.MONTHLY_FAX_TIER_1": Object.freeze({
    "pages": 1000,
    "price": 99.99,
    "name": "Business Plan",
    "duration": "month"
  }),
  "com.mobitech3000.JotNotFaxWeb.MONTHLY_FAX_TIER_2": Object.freeze({
    "pages": 300,
    "price": 39.99,
    "name": "Premium Plan",
    "duration": "month"
  }),
  "com.mobitech3000.JotNotFaxWeb.MONTHLY_FAX_TIER_3": Object.freeze({
    "pages": 100,
    "price": 14.99,
    "name": "Standard Plan",
    "duration": "month"
  }),
  "com.mobitech3000.JotNotFaxWeb.ANNUAL_FAX_TIER_1": Object.freeze({
    "pages": 1000,
    "price": 999.99,
    "name": "Business Plan",
    "duration": "year"
  }),
  "com.mobitech3000.JotNotFaxWeb.ANNUAL_FAX_TIER_2": Object.freeze({
    "pages": 300,
    "price": 399.99,
    "name": "Premium Plan",
    "duration": "year"
  }),
  "com.mobitech3000.JotNotFaxWeb.ANNUAL_FAX_TIER_3": Object.freeze({
    "pages": 100,
    "price": 149.99,
    "name": "Standard Plan",
    "duration": "year"
  })
});

export default SUBSCRIPTIONS;
