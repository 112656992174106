import React, { Component } from 'react';
import moment from 'moment-timezone';
import PDFJS from 'pdfjs-dist';

import ModalCoverPage from './ModalCoverPage';

function Receipt(props) {

  const { fax } = props.location.state;

  console.log("RECEIPT FAX", fax);

  const status = parseInt(fax.status, 10);
  let statusMessage, faxTime, faxTimeMessage;
  const localTZ = moment.tz.guess();
  if (status === 4) {
    statusMessage = 'Status: Success – Fax successfully sent';
    faxTime = moment.tz(fax.completetime, 'America/New_York');
    faxTimeMessage = 'Completed: ' + faxTime.tz(localTZ).format('lll');
  } else if (status < 4) {
    statusMessage = 'Status: Pending';
    faxTime = moment.tz(fax.posted, 'UTC');
    faxTimeMessage = 'Started: ' + faxTime.tz(localTZ).format('lll');
  } else if (status > 4) {
    statusMessage = 'Status: Error';
    faxTime = moment.tz(fax.posted, 'UTC');
    faxTimeMessage = 'Attempted: ' + faxTime.tz(localTZ).format('lll');
  }

  const style = (
    <style type="text/css">
      {`
        html { padding-top: 0; }
        .Modal .ModalFaxReceipt { border: none; }
      `}
    </style>
  );

  const faxReceiptContent = (
    <div className="Modal">
      <div className="ModalFaxReceipt">
        <h1>Fax Receipt</h1>
        <h2>
          <strong>{statusMessage}</strong>
        </h2>

        <div className="jnf-fax-receipt-details">
          <p className="columns">
            <span className="column"><strong>{faxTimeMessage}</strong></span>
          </p>
          <p className="columns">
            <span className="column">Recipient: {fax.recipient_name}</span>
            <span className="column">Pages: {fax.xmitpages}</span>
          </p>
          <p className="columns">
            <span className="column">Number: +{fax.recipient_country_code} {fax.recipient_fax}</span>
            <span className="column">Duration: {fax.xmittime}</span>
          </p>
          <p className="columns">
            <span className="column">Fax ID: {fax.fax_key}</span>
          </p>
        </div>

        {fax.cover_sheet
          ? <ModalCoverPage coverPageURL={fax.cp_url} isVisible={true} hasModal={false} />
          : <PdfFirstPage src={fax.file_url} />
        }
      </div>
      {style}
    </div>
  );

  return faxReceiptContent;
}


class PdfFirstPage extends Component {
  componentDidMount() {
    PDFJS.getDocument(this.props.src).promise.then(pdf => {
      // pdf page numbering starts at 1
      pdf.getPage(1).then((page) => {
        const viewport = page.getViewport({ scale: 1, });
        const canvas = document.getElementById("jnf-view-pdf-first-page-canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page.render(renderContext);
      });
    });
  }

  render() {
    return (
      <div className="PdfFirstPage">
        <canvas id="jnf-view-pdf-first-page-canvas"></canvas>
      </div>
    );
  }
}

export default Receipt;
