import React, { Component } from 'react';
import Mustache from 'mustache';

import Modal from './Modal';
import ModalFooter from './ModalFooter';

import { ENDPOINTS, getURL } from '../utils/fetching.js';
import { LOCAL_MODE } from '../utils/constants.js';


class ModalCoverPage extends Component {

  state = {
    isFetchingTemplate: false,
    templateStr: null,
  };

  componentDidMount() {
    this.updateIframe();
  }

  componentDidUpdate() {
    this.updateIframe();
  }

  iframeRef = React.createRef();

  writeIframe = () => {

    const iframe = this.iframeRef.current;
    const { coverPage = {}, recipientFax, pageCount } = this.props;
    const { templateStr } = this.state;

    const view = {
      recipient_fax: recipientFax,
      pages: pageCount + 1,
      recipient_name: coverPage.recipientName,
      subject: coverPage.subject,
      message: coverPage.message ? coverPage.message.split("\n").map((line) => ({ message_line: line })) : [],
      sender_name: coverPage.senderName,
      sender_organization: coverPage.senderOrganization,
      sender_address: coverPage.senderAddress ? coverPage.senderAddress.split("\n").map((line) => ({ sender_address_line: line })) : [],
      sender_phone: coverPage.senderPhone,
      sender_fax: coverPage.senderFax,
      sender_email: coverPage.senderEmail,
      sender_url: coverPage.senderWebsite,
      media_screen: 'body { padding: 1in; padding: 10vw; margin: 1.5vw auto; }',
    };

    if (templateStr && iframe) {
      const renderedStr = Mustache.render(templateStr, view);
      const doc = iframe.contentDocument;
      doc.open("text/html", "replace");
      // FIXME: suppress "violation: avoid using document.write" warnings?
      doc.write(renderedStr);
      doc.close();
    }
  };

  updateIframe = () => {

    if (this.state.isFetchingTemplate || this.props.coverPageURL) {
      return;
    }

    if (this.state.templateStr) {
      this.writeIframe();
      return;
    }

    this.setState({ "isFetchingTemplate": true, templateStr: null });

    let endpoint = LOCAL_MODE ? "/sample-fax-files/sample-cover.html.template" : ENDPOINTS.coverTemplate;

    getURL(endpoint, undefined, true)
      .then((text) => {
        this.setState(
          { "isFetchingTemplate": false, templateStr: text },
          () => {
            this.writeIframe();
          });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ "isFetchingTemplate": false, templateStr: null });
      });
  };

  printContent = (event) => {
    window.print();
  }

  render() {

    const { coverPageURL, isVisible, hasModal, handleCancel } = this.props;
    if (!isVisible) { return null; }

    const { printContent } = this;
    const src = coverPageURL ? coverPageURL : "";

    const printModalStyle = (
      <style type="text/css" media="print">
        {`
        .jnf-cover-page {
          padding-top: 129.412% !important;
          margin: 0 !important;
        }
        .jnf-cover-page-iframe {
          border: none !important;
        }
      `}
      </style>
    );

    const coverDiv = (
      <div className="ModalCoverPage">
        <div className="jnf-cover-page">
          <iframe className="jnf-cover-page-iframe" ref={this.iframeRef} title="cover-page" src={src}></iframe>
        </div>
        {hasModal && printModalStyle}
      </div>
    );

    const footer = <ModalFooter {...{ handleCancel, printContent }} />

    return (
      hasModal
        ? <Modal {...{ handleCancel, footer }} title="Cover Page">
            {coverDiv}
          </Modal>
        : coverDiv
    );
  }
}

export default ModalCoverPage;
