import React from 'react';

import Modal from './Modal';


function Alert({ alertType, isVisible, handleConfirm, handleCancel, messageDetail }) {

  if (!isVisible) { return null; }

  const alertTypes = {
    FaxNumberPage_ConfirmCancel: {
      title: 'Confirm Subscription Cancellation',
      message: 'If you cancel, your subscription will expire at the end of the current billing period, and your fax number will expire soon afterwards. We cannot guarantee that your fax number will still be available if you renew after it expires. Are you sure you want to continue?',
      buttonsLeft: <button className="button is-primary is-outlined" type="button" onClick={handleCancel}> Maybe Later </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-danger" type="button" onClick={handleConfirm}> Cancel Subscription </button>,
    },
    HomeStatusItem_ConfirmDeleteFax: {
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this fax? This cannot be undone.',
      buttonsLeft: <button className="button is-primary is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-danger" type="button" onClick={handleConfirm}> Delete </button>,
    },
    HomeStatusItem_FaxErrorInfo: {
      title: 'Fax Error Information',
      message: 'Error Message: ' + messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    HomeStatusItem_DeleteError: {
      title: 'Error with Delete',
      message: 'JotNot was unable to delete this fax. ' + messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    InboxListItem_ConfirmDeleteFax: {
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this fax? This cannot be undone.',
      buttonsLeft: <button className="button is-primary is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-danger" type="button" onClick={handleConfirm}> Delete </button>,
    },
    InboxListItem_DeleteError: {
      title: 'Error with Delete',
      message: 'JotNot was unable to delete this fax. ' + messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    InboxListItem_FaxProcessing: {
      title: 'Fax Is Processing',
      message: 'Please wait a few moments, then try again. If this issue persists, please contact support.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    InboxListItem_FaxUnpaidFor: {
      title: 'Fax Received During a Lapse in Subscription',
      message: 'Would you like to add this fax to the latest subscription period? If your subscription has expired, please renew it first.',
      buttonsLeft: <button className="button is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-primary" type="button" onClick={handleConfirm}> Add Fax to Current Period </button>,
    },
    InboxListItem_UpdateUsageError: {
      title: 'Error Updating Fax',
      message: 'JotNot was unable to update this fax. ' + messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    NewFax_ConfirmClearFax: {
      title: 'Clear Fax?',
      message: 'Are you sure you want to clear this fax and start over? This action cannot be undone.',
      buttonsLeft: <button className="button is-primary is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-danger" type="button" onClick={handleConfirm}> Clear </button>,
    },
    NewFax_NotEnoughCredits: {
      title: 'Not Enough Credits',
      message: messageDetail,
      buttonsLeft: <button className="button is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-primary" type="button" onClick={handleConfirm}> Go to Store </button>,
    },
    NewFax_NotEnoughPagesRemaining: {
      title: 'Not Enough Pages Left',
      message: messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    NewFax_ConfirmSend: {
      title: 'Confirm Sending',
      message: "Are you sure everything looks right? " + messageDetail,
      buttonsLeft: <button className="button is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-primary" type="button" onClick={handleConfirm}> Send </button>,
    },
    NewFax_SendFaxError: {
      title: 'Send Fax Error',
      message: 'JotNot was unable to send this fax. ' + messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    Store_ShowFaxPricingInfo: {
      title: 'Sending a Fax with Credits',
      message: 'Credits are for sending only; receiving requires a subscription.\n\nSending a fax costs 1 credit for every 5 pages.\n\nFor example:\n1-5 pages requires 1 credit\n6-10 pages requires 2 credits\n11-15 pages requires 3 credits\n\nThe cover page is free - it does not require any credits.\n\nPacks of 3, 10, 20, and 100 credits are available for purchase. Larger packs offer cheaper pricing per credit.\n\nWhen you send with credits, JotNot Fax sends through one of JotNot\'s shared numbers. To get your own fax number, which lets you send and receive, please see our Subscription Plan options rather than buying credits.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    Store_ShowSubscriptionInfo: {
      title: 'Subscription Plan Details',
      message: 'Subscription plans let you send and receive faxes from your own fax number.\n\nEach plan comes with a monthly allotment of pages that you can use for sending and receiving. If necessary, you can upgrade to a larger plan at any time, or downgrade to a smaller plan at the end of a billing cycle. You can also add pages to a single billing cycle using credits, at a rate of 5 pages per credit.\n\nIf you don\'t already have a fax number through JotNot, you\'ll be given a list of fax numbers to choose from before finalizing your purchase.\n\nIf you only need to send faxes, not receive, and you prefer a pay-as-you-go option, please see our credit pack options.\n\nIf you have any questions, please contact support.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    Store_DiscountError: {
      title: 'Error with Discount Code',
      message: messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    StoreCheckoutForm_ConfirmPurchase: {
      title: 'Confirm Purchase',
      message: messageDetail,
      buttonsLeft: <button className="button is-outlined" type="button" onClick={handleCancel}> Cancel </button>,
      buttonsCenter: null,
      buttonsRight: <button className="button is-primary" type="button" onClick={handleConfirm}> Purchase </button>,
    },
    StoreCheckoutForm_PurchaseError: {
      title: 'Error with Purchase',
      message: 'We could not complete your purchase. ' + messageDetail,
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    StoreCheckoutForm_PurchaseSuccess: {
      title: 'Purchase Successful',
      message: 'Thank you for your purchase. ' + (messageDetail ? messageDetail : ''),
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    ForgotPassword_EmailSent: {
      title: 'Email Sent',
      message: 'Please check your email for a message from JotNot, and follow the instructions there to reset your password.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    Settings_PasswordResetRequestSent: {
      title: 'Email Sent',
      message: 'Please check your email for a message from JotNot, and follow the instructions there to reset your password.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    Settings_EmailChangeRequestSent: {
      title: 'Email Sent',
      message: 'Please check your email for a message from JotNot, and follow the instructions there to complete the update of your email address.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    ResetPassword_PasswordUpdated: {
      title: 'Password Updated',
      message: 'You can now login with the new password.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
    ChangeEmail_EmailUpdated: {
      title: 'Email Updated',
      message: 'You can now login with the new email address.',
      buttonsLeft: null,
      buttonsCenter: <button className="button is-primary" type="button" onClick={handleConfirm}> OK </button>,
      buttonsRight: null,
    },
  };

  const { title, message, buttonsLeft, buttonsCenter, buttonsRight } = alertTypes[alertType];

  const footer = (
    <div className="level is-mobile jnf-alert-buttons">
      <div className="level-left"> {buttonsLeft} </div>
      <div> {buttonsCenter} </div>
      <div className="level-right"> {buttonsRight} </div>
    </div>
  );

  return (
    <div className="Alert">
      <Modal {...{ title, footer }}>
        <div className="jnf-alert-message">
          {message}
        </div>
      </Modal>
    </div>
  );
}

export default Alert;
