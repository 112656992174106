import React, { Component } from 'react';

import SUBSCRIPTIONS from '../utils/subscriptions';


class StoreSubscriptionPicker extends Component {

  state = {
    showBilledMonthly: true,
  };

  handleToggleBillingPeriod = (event) => {
    event.preventDefault();
    const showBilledMonthly = event.currentTarget.getAttribute('data-period') === "month";
    this.setState({ showBilledMonthly });
  };

  render() {
    const { cartSubscription, handleSubscriptionClicked } = this.props;
    const { showBilledMonthly } = this.state;
    const { handleToggleBillingPeriod } = this;

    const activeDuration = showBilledMonthly ? "month" : "year";
    const sortedFilteredSubs = Object.entries(SUBSCRIPTIONS).filter((sub) => {
      return sub[1].duration === activeDuration;
    }).sort((sub1, sub2) => {
      return sub1[1].price - sub2[1].price;
    });

    if(cartSubscription) {
      return (
        <div className="jnf-store-subscription-picker">
          <h3>Selected Plan</h3>
          <StoreSubscription subid={cartSubscription} isEditVisible={true} handleEditClicked={handleSubscriptionClicked} />
        </div>
      );
    } else {
      return (
        <div className="jnf-store-subscription-picker">
          <h3>Available Plans</h3>
          <StoreSubscriptionBillingToggle {...{showBilledMonthly, handleToggleBillingPeriod}} />
          <ul className="jnf-clickable-list">
            {sortedFilteredSubs.map(([subid, sub]) => (
              <li key={subid}>
                <a className="jnf-subscription-link" href="/" data-subid={subid} onClick={handleSubscriptionClicked}>
                  <StoreSubscription {...{subid}} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }
}


function StoreSubscriptionBillingToggle({ showBilledMonthly, handleToggleBillingPeriod }) {
  const monthClassName = showBilledMonthly ? "is-active" : "";
  const yearClassName = showBilledMonthly ? "" : "is-active";
  return (
    <div className="tabs is-centered is-toggle jnf-subscription-billing-toggle">
      <ul>
        <li className={monthClassName}><a href="/" data-period="month" onClick={handleToggleBillingPeriod}>Billed Monthly</a></li>
        <li className={yearClassName}><a href="/" data-period="year" onClick={handleToggleBillingPeriod}>Billed Annually</a></li>
      </ul>
    </div>
  );
}


function StoreSubscription({ subid, isEditVisible, handleEditClicked }) {
  const sub = SUBSCRIPTIONS[subid];
  const perDurationText = sub.duration === "month" ? "/mo" : "/yr";
  return (
    <div className="level is-mobile jnf-clickable-list-item jnf-subscription StoreSubscription">
      <div className="level-left jnf-subscription-description">
        <div>
          <p className="jnf-plan-name">{sub.name}</p>
          <span className="jnf-plan-price">${sub.price.toFixed(2)}</span><span className="jnf-plan-duration">{perDurationText}</span>
          <p className="jnf-pages-per-month">Send or receive {sub.pages} pages per month</p>
        </div>
      </div>
      { isEditVisible &&
        <div className="level-right jnf-subscription-edit">
          <button className="jnf-outline-button" type="button" onClick={handleEditClicked}>Change</button>
        </div>
      }
    </div>
  );
}


export { StoreSubscription, StoreSubscriptionBillingToggle };

export default StoreSubscriptionPicker;