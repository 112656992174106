import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { PhoneNumberUtil } from 'google-libphonenumber';
import Dropzone from 'react-dropzone';
import ReactGA from 'react-ga';

import NewFaxFileList from './NewFaxFileList';
import NewFaxNumber, { parseAndReturnNumberInfo } from './NewFaxNumber';
import NewFaxCoverPageForm from './NewFaxCoverPageForm';
//import Balance from './Balance';
import FaxNumberList, { FaxNumberDisplay, getUnexpiredNumbers, getSubscriptionStatus, SUBSCRIPTION_STATUS } from './FaxNumberList';
import ErrorDisplay from './ErrorDisplay';
import Alert from './Alert';

import { PAGES, LOCAL_MODE } from '../utils/constants';
import { ENDPOINTS, postData, getURL } from '../utils/fetching';
import { processDocument, docRep } from '../utils/documents';
import LoadingIndicator from '../utils/LoadingIndicator';
import { pluralize } from '../utils/misc';


const blankInternalFormData = Object.freeze({
  ratePages: 5,
  rateCredits: 1,
  creditsNeeded: 0,
  isLoading: false,
  sendFaxError: null,
  rateError: null,
  creditsNeededError: null,
  isValidNumber: false,
  numberError: null,
  acceptedFiles: Object.freeze([]),
  passwordFiles: Object.freeze([]), // password protected pdf file
  rejectedFiles: Object.freeze([]), // array of {file, error} objects: not a pdf, or couldn't read from file, or couldn't parse num pages
  isVisibleConfirmClearFaxAlert: false,
  isVisibleNotEnoughCreditsAlert: false,
  isVisibleConfirmSendAlert: false,
  isVisibleConfirmResendAlert: false,
  isVisibleNotEnoughPagesRemainingAlert: false,
});


const blankFaxData = Object.freeze({
  senderFaxNumber: null,
  recipientFax: '',
  recipientRegionCode: 'US',
  recipientCountryCode: '1',
  resendFaxKey: '',
  resendFiles: Object.freeze([]),
  coverSheet: false,
  coverPage: Object.freeze({
    recipientName: '',
    subject: '',
    message: '',
    senderName: '',
    senderOrganization: '',
    senderAddress: '',
    senderPhone: '',
    senderFax: '',
    senderEmail: '',
    senderWebsite: ''
  })
});


let cachedFaxFormState = { ...blankInternalFormData, ...blankFaxData };


class NewFax extends Component {

  constructor(props) {
    super(props);
    const redirect = { shouldRedirectToStore: false, shouldRedirectToHome: false };
    if (props.location && props.location.state) {
      this.state = Object.assign({ ...blankInternalFormData }, redirect, props.location.state);
    } else {
      this.state = Object.assign({}, cachedFaxFormState, redirect);
    }
    cachedFaxFormState = null;
  }

  componentWillUnmount() {
    // the destructuring use below doesn't count for the no-unused-vars warning, so i'm disabling it for this line
    let shouldRedirectToStore, shouldRedirectToHome; // eslint-disable-line no-unused-vars
    ({ shouldRedirectToStore, shouldRedirectToHome, ...cachedFaxFormState } = this.state); // NOTE: this isn't legal js syntax without the parens
    console.log("cached state at unmount:", cachedFaxFormState);
  }

  componentDidMount() {

    if (this.props.location && this.props.location.state) {
      // we got here from a link, possibly with updated state, so check a few things
      const pageCount = this.computePageCount(this.state.acceptedFiles) + this.computePageCount(this.state.resendFiles);
      this.fetchCreditsNeeded(pageCount);
      parseAndReturnNumberInfo(this.state.recipientRegionCode, this.state.recipientFax, this.handleChangeFaxNumber, this.phoneUtil);
    }

    this.props.fetchUser && this.props.fetchUser();

    const senderFaxNumber = !this.props.faxNumbers ? null : this.props.faxNumbers.find((num) => {
      return num.urlsafe_key === this.props.defaultFaxNumberID;
    });
    this.setState({ senderFaxNumber });
  }

  phoneUtil = PhoneNumberUtil.getInstance();

  fetchFaxRate = (regionCode) => {

    ReactGA.event({
      category: 'Fax Rate',
      action: 'Fetch',
      label: regionCode,
      nonInteraction: true
    });

    if (LOCAL_MODE) {
      this.setState({ ratePages: 5, rateCredits: 1 });
      ReactGA.event({
        category: 'Fax Rate',
        action: 'Fetch local',
        label: regionCode,
        nonInteraction: true
      });
      return;
    }

    getURL(ENDPOINTS.rate, { "recipient_region_code": regionCode })
      .then((json) => {
        if (!json || !json.pages || !json.credits) {
          throw new Error("Unable to connect with server to fetch fax rate.");
        }
        this.setState({ ratePages: json.pages, rateCredits: json.credits, rateError: null });
        ReactGA.event({
          category: 'Fax Rate',
          action: 'Fetch succeeded',
          label: regionCode,
          nonInteraction: true
        });
      })
      .catch((error) => {
        this.setState({ rateError: error });
        ReactGA.exception({ description: error });
      });
  };

  fetchCreditsNeeded = (pages) => {

    if (pages <= 0) {
      this.setState({ creditsNeeded: 0 });
      return;
    }

    ReactGA.event({
      category: 'Credits Needed',
      action: 'Fetch',
      label: pages + ' pages',
      nonInteraction: true
    });

    if (LOCAL_MODE) {
      const creditsNeeded = Math.ceil(pages / 5);
      this.setState({ creditsNeeded });
      ReactGA.event({
        category: 'Credits Needed',
        action: 'Fetch local',
        label: pages + ' pages',
        nonInteraction: true
      });
      return;
    }

    // server ignores recipient_fax field, and it's the wrong (or incomplete) information to send anyway,
    // since it doesn't include country/region info. so I'm just sending a dummy value until this is fixed.
    getURL(ENDPOINTS.creditsNeeded, { pages, "recipient_fax": "dummy-value" })
      .then((json) => {
        if (!json) {
          throw new Error("Unable to connect with server to fetch credits needed.");
        } else if(!Number.isInteger(json.balance)) {
          throw new Error("Unable to retrive credit balance from server.");
        } else if(!Number.isInteger(json.needed)) {
          throw new Error("Unable to retrive credits required for fax from server.");
        }
        this.setState({ creditsNeeded: json.needed, creditsNeededError: null });
        ReactGA.event({
          category: 'Credits Needed',
          action: 'Fetch succeeded',
          label: pages + ' pages, ' + json.needed + ' credits needed',
          nonInteraction: true
        });
        // TODO: call back to App to let it know latest credits value?
      })
      .catch((error) => {
        this.setState({ creditsNeededError: error });
        ReactGA.exception({ description: error.message });
      });
  };

  computePageCount = (filesWithPages) => {
    if (!filesWithPages) {
      return 0;
    }

    return filesWithPages.reduce((acc, val) => (acc + Number(val.pages)), 0);
  };

  handleChangeFaxNumber = (regionCode, countryCode, faxNumber, numberError, isValidNumber) => {
    const { recipientRegionCode: oldRegionCode } = this.state;
    this.setState({
      recipientFax: faxNumber,
      recipientRegionCode: regionCode,
      recipientCountryCode: countryCode,
      numberError: numberError,
      isValidNumber
    });

    if (regionCode !== oldRegionCode) {
      this.fetchFaxRate(regionCode);
    }
  };

  updateStateOrCache = (acceptedFilesArr, rejectedFilesArr, passwordFilesArr) => {
    const update = {
      acceptedFiles: this.state.acceptedFiles.concat(acceptedFilesArr),
      rejectedFiles: this.state.rejectedFiles.concat(rejectedFilesArr),
      passwordFiles: this.state.passwordFiles.concat(passwordFilesArr),
    };

    if (this) {
      this.setState(update);
      const pageCount = this.computePageCount(acceptedFilesArr);
      this.fetchCreditsNeeded(pageCount);
    } else if (cachedFaxFormState) {
      Object.assign(cachedFaxFormState, update);
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    console.log("rejectedFiles", rejectedFiles);
    // https://react-dropzone.js.org
    // https://stackoverflow.com/questions/22048395/how-to-open-a-local-pdf-in-pdfjs-using-file-input
    // https://stackoverflow.com/questions/10253669/how-to-get-the-number-of-pages-of-a-pdf-uploaded-by-user

    // if (acceptedFiles.length + rejectedFiles.length > 1) {
    //   this.setState({
    //     creditsNeeded: 0, resendFaxKey: "", sendFaxError: null,
    //     resendFiles: [], acceptedFiles: [], passwordFiles: [], rejectedFiles: [],
    //   });
    //   return;
    // }

    const acceptedFilesArr = [];
    const passwordFilesArr = [];
    const rejectedError = new Error('Not a PDF file. Please print to PDF or convert file to PDF before faxing.');
    const rejectedFilesArr = rejectedFiles.map((file) => docRep(file, null, rejectedError, null));

    // slice() without args does a shallow copy. Using it here so that later mods to rejectedFilesArr don't modify state.
    // this.setState((prevState) => ({
    //   creditsNeeded: 0, resendFaxKey: "", sendFaxError: null,
    //   resendFiles: [], acceptedFiles: [], passwordFiles: [], rejectedFiles: rejectedFilesArr.slice(),
    // }));

    Promise.all(acceptedFiles.map(file => processDocument({ file }, acceptedFilesArr, rejectedFilesArr, passwordFilesArr)))
      .then((values) => {
        this.updateStateOrCache(acceptedFilesArr, rejectedFilesArr, passwordFilesArr);
      })
      .catch((error) => {
        this.updateStateOrCache(acceptedFilesArr, rejectedFilesArr, passwordFilesArr);
      });
  };

  handleCheckboxChange = (event) => {
    this.setState((prevState) => ({ coverSheet: !prevState.coverSheet }));
  };

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({
      isVisibleConfirmClearFaxAlert: false,
      isVisibleNotEnoughCreditsAlert: false,
      isVisibleConfirmSendAlert: false,
      isVisibleConfirmResendAlert: false,
      isVisibleNotEnoughPagesRemainingAlert: false,
      sendFaxError: null,
    });
  };

  handleConfirmClearFaxAlert = (event) => {
    event.preventDefault();
    this.setState({ isVisibleConfirmClearFaxAlert: true });
  };

  clearFax = (event) => {
    event.preventDefault();
    this.setState({ isVisibleConfirmClearFaxAlert: false });
    this.setState({ ...blankInternalFormData, ...blankFaxData });
  };

  handleRemoveFile = (fileListName) => (ind) => {
    this.setState((prevState) => {
      const fileList = prevState[fileListName];
      const newFileList = fileList.slice();
      newFileList.splice(ind, 1);
      return { [[fileListName]]: newFileList };
    }, () => {
      const pageCount = this.computePageCount(this.state.acceptedFiles) + this.computePageCount(this.state.resendFiles);
      this.fetchCreditsNeeded(pageCount);
    });
  };

  handleCoverPageChange = (coverPageUpdate) => {
    this.setState((prevState) => {
      return { coverPage: Object.assign({}, prevState.coverPage, coverPageUpdate) };
    });
  };

  didSelectNumber = (senderFaxNumber) => {
    console.log("selected sender fax number:", senderFaxNumber);
    this.setState({ senderFaxNumber });
    const defaultFaxNumberID = senderFaxNumber ? senderFaxNumber.urlsafe_key : null;
    this.props.updateUser && this.props.updateUser({ defaultFaxNumberID });
  };

  createFormData = (isResend) => {
    const formData = new FormData();

    const files = isResend ? this.state.resendFiles : this.state.acceptedFiles;
    if (isResend) {
      formData.append("fax_key", this.state.resendFaxKey);
    }

    console.log("Files: ", files);

    const len = files.length;
    const pages_multi = [];
    const filename_multi = [];
    for(let i=0; i<len; i++) {
      pages_multi.push(Number(files[i].pages));
      filename_multi.push(files[i].file.name);
    }
    formData.append("pages_multi", JSON.stringify(pages_multi));
    formData.append("filename_multi", JSON.stringify(filename_multi));

    //const pageCount = this.computePageCount(this.state.acceptedFiles) + this.computePageCount(this.state.resendFiles);

    formData.append("recipient_fax", this.state.recipientFax);
    formData.append("recipient_country_code", this.state.recipientCountryCode);
    formData.append("recipient_region_code", this.state.recipientRegionCode);

    formData.append("cover_sheet", this.state.coverSheet ? "1" : "0");
    if (this.state.coverSheet) {
      formData.append("recipient_name", this.state.coverPage.recipientName || '');
      formData.append("subject", this.state.coverPage.subject || '');
      formData.append("message", this.state.coverPage.message || '');
      formData.append("sender_name", this.state.coverPage.senderName || '');
      formData.append("sender_organization", this.state.coverPage.senderOrganization || '');
      formData.append("sender_address", this.state.coverPage.senderAddress || '');
      formData.append("sender_phone", this.state.coverPage.senderPhone || '');
      formData.append("sender_fax", this.state.coverPage.senderFax || '');
      formData.append("sender_email", this.state.coverPage.senderEmail || '');
      formData.append("sender_url", this.state.coverPage.senderWebsite || '');
    }

    formData.append("app_id", "com.mobitech3000.JotNotFaxWeb");

    if(this.state.senderFaxNumber) {
      formData.append("fax_number", this.state.senderFaxNumber.urlsafe_key);
    }

    if (LOCAL_MODE) {
      // in non-local mode, we'll log as part of the post
      for (let pair of formData.entries()) {
        console.log(pair);
      }
    }

    return formData;
  };

  validateResend = () => {
    // FIXME
    // if (this.state.acceptedFiles && this.state.acceptedFiles.length > 0) {
    //   // clear resend and tell user to try again
    //   const message = "Internal error; please try again.";
    //   this.setState({ sendFaxError: new Error(message), resendFaxKey: null, resendFiles: [] });
    //   ReactGA.exception({ description: message });
    //   return;
    // }

    // if (!(this.state.resendFiles && this.state.resendFiles.length === 1)) {
    //   // clear resend and tell user to try again
    //   const message = "Internal error; please try again.";
    //   this.setState({ sendFaxError: new Error(message), resendFaxKey: null, resendFiles: [] });
    //   ReactGA.exception({ description: message });
    //   return;
    // }

    this.setState({ isVisibleConfirmResendAlert: true });
  };

  resendFax = (event) => {

    if (event) { event.preventDefault(); }

    // clear confirm resend alert, error messages, start spinner
    this.setState({ isVisibleConfirmResendAlert: false, isLoading: true, numberError: null, sendFaxError: null });

    const createFaxRecordData = this.createFormData(true);

    if (LOCAL_MODE) {
      const update = { ...blankInternalFormData, ...blankFaxData };
      this.setState(Object.assign(update, { shouldRedirectToHome: true }));

      ReactGA.event({
        category: 'Send',
        action: 'Resend successfully initiated',
        label: 'local mode resend succeeded',
        nonInteraction: true
      });
      return;
    }

    console.log("Resend fax post data: ");
    for (let pair of createFaxRecordData.entries()) {
      console.log(pair);
    }

    postData(ENDPOINTS.resend, createFaxRecordData)
      .then((json) => {

        // clear the fax form
        const update = { ...blankInternalFormData, ...blankFaxData };
        if (this) {
          this.setState(Object.assign(update, { shouldRedirectToHome: true }));
        } else {
          cachedFaxFormState = update;
        }
        ReactGA.event({
          category: 'Send',
          action: 'Resend successfully initiated',
          label: 'resend succeeded',
          nonInteraction: true
        });

      })
      .catch((error) => {
        const update = { sendFaxError: error, isLoading: false };
        if (this) {
          this.setState(update);
        } else if (cachedFaxFormState) {
          Object.assign(cachedFaxFormState, update);
        }
        ReactGA.exception({ description: error.message });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    ReactGA.event({
      category: 'Send',
      action: 'Send button clicked',
    });

    // resend & new send common vaidation
    const pageCount = this.computePageCount(this.state.acceptedFiles) + this.computePageCount(this.state.resendFiles);
    if (pageCount < 1) {
      const message = "No document added to fax. Please add a valid PDF document, and try again.";
      this.setState({ sendFaxError: new Error(message) });
      ReactGA.exception({ description: message });
      return;
    }

    if (!this.state.isValidNumber || !this.state.recipientFax || !this.state.recipientRegionCode || !this.state.recipientCountryCode) {
      const message = "Please enter a valid fax number to send to, and then try again.";
      this.setState({ numberError: new Error(message) });
      ReactGA.exception({ description: message });
      return;
    }

    const { senderFaxNumber } = this.state;
    if(senderFaxNumber && getSubscriptionStatus(senderFaxNumber) === SUBSCRIPTION_STATUS.active) {
      const { usage_period } = senderFaxNumber;
      const pagesRemaining = usage_period ? Number(usage_period.pages_remaining) : 0;
      if(!pagesRemaining || pagesRemaining <= 0) {
        this.setState({ isLoading: false, isVisibleNotEnoughPagesRemainingAlert: true });
        ReactGA.event({
          category: 'Send',
          action: 'Switch to credits instead of fax number',
          label: 'Prompt shown',
          nonInteraction: true
        });
        return;
      }
    } else if (this.state.creditsNeeded > this.props.credits) {
      this.setState({ isLoading: false, isVisibleNotEnoughCreditsAlert: true });
      ReactGA.event({
        category: 'Send',
        action: 'Redirect to store',
        label: 'Prompt shown',
        nonInteraction: true
      });
      return;
    }

    // handle resends
    if (this.state.resendFaxKey) {
      this.validateResend();
      return;
    }

    // validation for new sends only
    if (!(this.state.acceptedFiles && this.state.acceptedFiles.length > 0)) {
      const message = "Please upload a file to send.";
      this.setState({ sendFaxError: new Error(message) });
      ReactGA.exception({ description: message });
      return;
    }

    this.setState({ isVisibleConfirmSendAlert: true });
  };

  sendFax = (event) => {
    if (event) { event.preventDefault(); }

    // clear confirm alert, error messages, start spinner
    this.setState({ isVisibleConfirmSendAlert: false, isLoading: true, numberError: null, sendFaxError: null });

    const createFaxRecordData = this.createFormData(false);

    if (LOCAL_MODE) {
      console.log("local mode is set, so not posting");
      const update = { ...blankInternalFormData, ...blankFaxData };
      this.setState(Object.assign(update, { shouldRedirectToHome: true }));
      ReactGA.event({
        category: 'Send',
        action: 'File successfully uploaded',
        label: 'local mode send succeeded',
        nonInteraction: true
      });
      return;
    }

    postData(ENDPOINTS.geturl, createFaxRecordData)
      .then((json) => {

        if (!json || !json.upload_url || !json.new_fax_key) {
          throw new Error(`Unable to connect to server.  Please try sending again. (Missing upload_url or new_fax_key: ${json})`);
        }

        const uploadData = new FormData();
        uploadData.append("new_fax_key", json.new_fax_key);

        const docs = this ? this.state.acceptedFiles : cachedFaxFormState.acceptedFiles;
        for (const doc of docs) {
          if (!doc || !doc.file) {
            throw new Error("Fax document went missing, please upload a new document and try again.");
          }

          uploadData.append("file", doc.file);
        }

        return postData(json.upload_url, uploadData)
          .then((json) => {
            // clear the fax
            const update = { ...blankInternalFormData, ...blankFaxData };
            if (this) {
              this.setState(Object.assign(update, { shouldRedirectToHome: true }));
            } else if (cachedFaxFormState) {
              Object.assign(cachedFaxFormState, update);
            }

            ReactGA.event({
              category: 'Send',
              action: 'Fax record created',
              nonInteraction: true
            });
          })
          .catch((error) => {
            // TODO: set new_fax_key on state, to skip GETURL call on next submit?
            throw error;
          });
      })
      .catch((error) => {
        const update = { sendFaxError: error, isLoading: false };
        if (this) {
          this.setState(update);
        } else if (cachedFaxFormState) {
          Object.assign(cachedFaxFormState, update);
        }
        ReactGA.exception({ description: error.message });
      });
  };

  handleErrorAlert = (event) => {
    if (event) { event.preventDefault(); }
    this.setState({ sendFaxError: null });
  };

  redirectToStore = (event) => {
    event.preventDefault();
    this.setState({ isVisibleNotEnoughCreditsAlert: false, shouldRedirectToStore: true });
    ReactGA.event({
      category: 'Send',
      action: 'Redirect to store',
      label: 'User confirmed',
      //label: shouldRedirect ? 'User confirmed' : 'User canceled',
    });
  };

  handleAddCredits = (event) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'To Store',
      label: 'Add Credits button'
    });
    return true;
  };


  render() {
    const {
      isLoading, sendFaxError, numberError,
      recipientFax, recipientRegionCode,
      acceptedFiles, resendFiles, passwordFiles, rejectedFiles,
      creditsNeeded, creditsNeededError,
      ratePages, rateCredits, rateError,
      coverSheet, coverPage,
      shouldRedirectToStore, shouldRedirectToHome, isVisibleConfirmClearFaxAlert, isVisibleNotEnoughCreditsAlert, isVisibleConfirmSendAlert, isVisibleConfirmResendAlert, isVisibleNotEnoughPagesRemainingAlert,
      senderFaxNumber,
    } = this.state;

    const { credits, creditsError, faxNumbers, faxNumbersError } = this.props;

    const {
      hideAlert,
      handleConfirmClearFaxAlert, clearFax,
      handleChangeFaxNumber, phoneUtil, handleCoverPageChange,
      onDrop, handleRemoveFile,
      didSelectNumber, handleAddCredits, redirectToStore,
      handleSubmit, sendFax, resendFax,
      computePageCount,
    } = this;

    const pageCount = computePageCount(acceptedFiles) + computePageCount(resendFiles);
    const creditsText = pluralize(credits, "credit");
    const creditsNeededText = pluralize(creditsNeeded, "credit");
    const moreCreditsNeededText = pluralize(creditsNeeded - credits, "more credit");
    const pageCountText = pluralize(pageCount, "page");
    const { usage_period } = senderFaxNumber || {};
    const pagesRemainingText = usage_period ? pluralize(usage_period.pages_remaining, "page") : "0 pages";
    const confirmSendMessageDetailText = senderFaxNumber
    ? "This fax will use " + pageCountText + ". You currently have " + pagesRemainingText + " left this month."
    : "This fax will cost " + creditsNeededText + " to send. You currently have " + creditsText + " in your account.";

    if (shouldRedirectToStore) {
      return <Redirect to={PAGES.store} />;
    }

    if (shouldRedirectToHome) {
      return <Redirect to={PAGES.home} />;
    }

    return (
      <div className="NewFax">

        <ErrorDisplay errors={[faxNumbersError, creditsError, creditsNeededError, rateError]} type="bar" />

        {/* Page title */}
        <div className="section jnf-first">
          <div className="container">
            <div className="level is-mobile">
              <div className="level-left">
                <h1>New Fax</h1>
              </div>
              <div className="level-right">
                <a href="/" className="button" onClick={handleConfirmClearFaxAlert}>
                  <span>Clear</span>
                  <span className="icon"><i className="jnf-icon-delete"></i></span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <form name="fax" onSubmit={handleSubmit}>

          <section className="section jnf-fax-recipient">
            <div className="container">
              <NewFaxNumber {...{ recipientFax, recipientRegionCode, handleChangeFaxNumber, phoneUtil }} />
              {numberError && recipientFax && recipientFax.length && <p className="error help is-danger">{`Error: ${numberError.message}`}</p>}
            </div>
          </section>

          <section className="section jnf-fax-content">
            <div className="container">
              <h2>Fax Content</h2>
              { !this.state.resendFaxKey &&

                <Dropzone
                  accept="application/pdf"
                  {...{ onDrop }}
                  //onDrop={(accepted, rejected) => onDrop(accepted, rejected) }
                  //onDrop={(accepted, rejected) => { this.setState({ accepted, rejected }); }}
                >
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => (
                  <div {...getRootProps()}  className={"dropzone jnf-dropzone" + (isDragActive ? " jnf-dropzone-active" : "") + (isDragAccept ? " jnf-dropzone-accept" : "") + (isDragReject ? " jnf-dropzone-reject" : "") }>
                      <div className="jnf-dropzone-text">
                        <i className="jnf-icon-fax-content" />
                        <span>Drag and drop, or click to choose PDF file</span>
                      </div>

                    <input {...getInputProps()} />
                    {// <p>Try dropping some files here, or click to select files to upload.</p>
                     // <p>Only *.jpeg and *.png images will be accepted</p>
                    }
                  </div>
                )}
                </Dropzone>
              }
              <NewFaxFileList listType="accepted" files={acceptedFiles} removeFileCallback={handleRemoveFile("acceptedFiles")} />
              <NewFaxFileList listType="resend" files={resendFiles} removeFileCallback={handleRemoveFile("resendFiles")} />
              <NewFaxFileList listType="password" title="Password-protected files cannot be sent. Please remove password before adding:" files={passwordFiles} removeFileCallback={handleRemoveFile("passwordFiles")} />
              <NewFaxFileList listType="rejected" title="The following file is not allowed:" files={rejectedFiles} removeFileCallback={handleRemoveFile("rejectedFiles")} />
              <p className="jnf-page-count">Pages: {pageCount}</p>
            </div>
          </section>

          <section className="section jnf-fax-cover-sheet">
            <div className="container">
              <h2>Cover Page</h2>
              <div className="jnf-cover-page-checkbox subsection">
                <input type="checkbox" name="coverSheet" checked={coverSheet} onChange={this.handleCheckboxChange} />
                <label htmlFor="coverSheet">Add cover page (free)</label>
              </div>
              {coverSheet && <NewFaxCoverPageForm {...{ coverPage, recipientFax, pageCount, handleCoverPageChange }} />}
            </div>
          </section>

          <section className="section">
            <NewFaxPaymentOptions {...{
              faxNumbers, senderFaxNumber, didSelectNumber,
              credits, creditsNeeded, ratePages, rateCredits, moreCreditsNeededText, handleAddCredits,
            }} />
          </section>

          <section className="section has-text-centered">
            <button className="button is-primary jnf-button-send-fax" type="submit" name="submitButton">
              <span>Send Fax</span>
              <span className="icon">
                <i className="jnf-icon-send" />
              </span>
            </button>
          </section>

          <LoadingIndicator {...{ isLoading }} />
          <Alert
            alertType='NewFax_ConfirmClearFax'
            isVisible={isVisibleConfirmClearFaxAlert}
            handleConfirm={clearFax}
            handleCancel={hideAlert}
          />
          <Alert
            alertType='NewFax_NotEnoughCredits'
            isVisible={isVisibleNotEnoughCreditsAlert}
            handleConfirm={redirectToStore}
            handleCancel={hideAlert}
            messageDetail={"You need " + moreCreditsNeededText + " to send this fax. You currently have " + creditsText + ", and this fax costs " + creditsNeededText + " to send."}
          />
          <Alert
            alertType='NewFax_NotEnoughPagesRemaining'
            isVisible={isVisibleNotEnoughPagesRemainingAlert}
            handleConfirm={hideAlert}
            messageDetail={"You need " + pageCountText + " to send this fax. You currently have " + pagesRemainingText + " left this month. Please upgrade your subscription or add pages to the current month in the iOS app."}
          />
          <Alert
            alertType='NewFax_ConfirmSend'
            isVisible={isVisibleConfirmSendAlert}
            handleConfirm={sendFax}
            handleCancel={hideAlert}
            messageDetail={confirmSendMessageDetailText}
          />
          <Alert
            alertType='NewFax_ConfirmSend'
            isVisible={isVisibleConfirmResendAlert}
            handleConfirm={resendFax}
            handleCancel={hideAlert}
            messageDetail={confirmSendMessageDetailText}
          />
          <Alert
            alertType='NewFax_SendFaxError'
            isVisible={sendFaxError ? true : false}
            handleConfirm={hideAlert}
            messageDetail={sendFaxError ? sendFaxError.message : ''}
          />

        </form>
      </div>
    );
  }
}

function NewFaxPaymentOptions(
    {
      faxNumbers, senderFaxNumber, didSelectNumber,
      credits, creditsNeeded, handleAddCredits, rateCredits, ratePages,
    }) {

  const filteredFaxNumbers = getUnexpiredNumbers(faxNumbers);

  if(filteredFaxNumbers && filteredFaxNumbers.length > 0) {
    if(senderFaxNumber && getSubscriptionStatus(senderFaxNumber) === SUBSCRIPTION_STATUS.active) {
      return (
        <div className="container">
          <h2>Your Sender Fax Number</h2>
          <FaxNumberDisplay faxNumber={senderFaxNumber} isUnselectVisible={filteredFaxNumbers.length>1} {...{didSelectNumber}} />
        </div>
      );
    } else {
      return <FaxNumberList faxNumbers={filteredFaxNumbers} {...{didSelectNumber}} isSelectable={true} />
    }
  } else {
    const ratePagesText = pluralize(ratePages, "page");
    const rateCreditsText = pluralize(rateCredits, "credit");
    const moreCreditsNeededText = pluralize(creditsNeeded - credits, "more credit");

    return (
      <div className="container">
        <div className="level is-mobile">
          <div className="level-left">
            <h2>Credits</h2>
          </div>
          <div className="level-right jnf-rate-text">
            <span>Rate: {rateCreditsText} per {ratePagesText}</span>
          </div>
        </div>

        <div className="jnf-credits-text">
          <p>Credits needed: {creditsNeeded}</p>
          <p>Your credits: {credits}</p>
          {creditsNeeded > credits &&
            <div className="level is-mobile">
              <span className="level-left has-text-danger has-text-weight-semibold">You need {moreCreditsNeededText}</span>
              <span className="level-right"><Link to={PAGES.store} className="level-right button is-primary" onClick={handleAddCredits}>Add Credits</Link></span>
            </div>
          }
        </div>

      </div>
    );
  }
}

export default NewFax;
