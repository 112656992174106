import PDFJS from 'pdfjs-dist';
import uuidv1 from 'uuid/v1';
/**
  * document objects:
  * @property file:  a file-like object (name, size, blob, preview)
  * @property error:  (optional) an Error object
  * @property pages:  (optional) a number
  * @property password: (optional) a string
  */


function docRep(file, pages, error, password) {
  const hash = uuidv1();
  return {hash, file, pages, error, password};
}

function nameFromURL(url) {
  if(!url) {
    return null;
  }
  const parts = url.split('/');
  let name = null;
  if (parts) {
    name = parts.pop() || parts.pop();  // handle potential trailing slash
  }
  return name;
}

function processDocument(doc, acceptedFilesArr, rejectedFilesArr, passwordFilesArr, password) {

  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.onload = () => {
      const fileBuffer = reader.result;
      const initter = {
        data: fileBuffer,
      };
      if(password) {
        initter.password = password;
      }

      // Look into:
      // https://github.com/wojtekmaj/react-pdf
      // https://github.com/diegomura/react-pdf
      // https://github.com/MrRio/jsPDF
      PDFJS.getDocument(initter)
        .promise.then((pdf) => {
          acceptedFilesArr.push(docRep(doc.file, pdf.numPages, null, password));
          resolve(pdf.numPages);
        })
        .catch((error) => {
          console.log(error);
          switch (error.name) {
            case 'PasswordException':
              passwordFilesArr.push(docRep(doc.file, null, error, null));
              break;
            default:  // case 'MissingPDFException': case 'InvalidPDFException':
              rejectedFilesArr.push(docRep(doc.file, null, error, null));
          }
          resolve(0);
        });
      //FIXME: do we need to destroy PDF object / cleanup?
    };

    const errorEventHandler = (errorMsg) => (event) => {
      console.log(`FileReader error: file reading ${errorMsg}.`);
      console.log("event error:", event.target.error);
      console.log("file:", doc);
      rejectedFilesArr.push(doc(doc.file, null, event.target.error, null));
      resolve(0);
    };

    reader.onabort = errorEventHandler("aborted");
    reader.onerror = errorEventHandler("failed");
    reader.readAsArrayBuffer(doc.file);
  });
}

export { processDocument, docRep, nameFromURL };
