import React, { Component } from 'react';

import FaxNumberList from './FaxNumberList';
import InboxList from './InboxList';
import ErrorDisplay from './ErrorDisplay';

class Inbox extends Component {

  state = {
    fetchFaxesError: null,
  };


  componentDidMount() {
    this.props.fetchUser && this.props.fetchUser();
  }

  handleFetchFaxesError = (error) => {
    this.setState({ fetchFaxesError: error });
  };

  render() {
    const { faxNumbers, faxNumbersError } = this.props;
    const { fetchFaxesError } = this.state;
    const { handleFetchFaxesError } = this;

    return (
      <div className="Inbox">
        <ErrorDisplay errors={[faxNumbersError, fetchFaxesError]} type="bar" />

        <section className="section jnf-first">
          <div className="container">
            <h1>Inbox</h1>
          </div>
        </section>

        <FaxNumberList faxNumbers={faxNumbers} filterExpired={true} includeGracePeriod={true} shouldShowGetNumberButton={true}/>

        <InboxList errorCallback={handleFetchFaxesError} />
      </div>
    );
  }
}

export default Inbox;
