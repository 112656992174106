import Cookies from 'js-cookie';
import { APP_BASE_PATH, PAGES } from './constants.js';

const API_PREFIX = "/api";


const ENDPOINTS = Object.freeze({
  sentFax: API_PREFIX + "/sent-fax",
  receivedFax: API_PREFIX + "/received-fax",
  login: API_PREFIX + "/login",
  signup: API_PREFIX + "/signup",
  logout: API_PREFIX + "/logout",
  forgotPassword: API_PREFIX + "/forgotPassword",
  resetPassword: API_PREFIX + "/resetPassword",
  requestEmailChange: API_PREFIX + "/requestEmailChange",
  changeEmail: API_PREFIX + "/changeEmail",
  user: API_PREFIX + "/user",
  faxNumber: API_PREFIX + "/faxnumber",
  faxnumberSubscription: API_PREFIX + "/faxnumber-subscription",
  usagePeriod: API_PREFIX + "/faxnumber-subscription/usage-period",
  rate: API_PREFIX + "/rate",
  creditsNeeded: API_PREFIX + "/creditsNeeded",
  purchase: API_PREFIX + "/purchase",
  promotion: API_PREFIX + "/promotion",
  senderInfo: API_PREFIX + "/senderInfo",
  geturl: API_PREFIX + "/geturl",
  resend: API_PREFIX + "/resendFax",
  deleteSentFax: API_PREFIX + "/sent-fax/", // need to add <fax_secure_id>
  coverTemplate: API_PREFIX + "/coverTemplate",
  areaCodes: API_PREFIX + "/available-area-code",
  availableNumbers: API_PREFIX + "/available-fax-number",
  paymentMethods: API_PREFIX + "/billing-info",
});


const fakeSetAuthenticated = (newval) => {
  if(newval) {
    Cookies.set("auth", "fake-data", { expires: 1 });  // 1 day from now
  } else {
    Cookies.remove("auth");
  }
  console.log("new cookie value:", Cookies.get("auth"));
}


function isAuthenticated() {
  return Cookies.get("auth") !== undefined;
}

function redirectToLoginIfUnauthorized(response) {
  if(response.status === 401) {
    Cookies.remove("auth");
    window.location.assign(APP_BASE_PATH + PAGES.login);
  }
}

function getTextResponse(response) {
  if (!response) {
    throw new Error("response is missing");
  }

  console.log(response);
  console.log(response.body);

  if (!response.ok) {
    throw new Error(`Unable to connect to server: ${response.statusText} (${response.status})`);
  }

  return response.text();
}


function getJSONResponse(response) {
  if (!response) {
    throw new Error("response is missing");
  }

  console.log(response);
  console.log(response.body);

  if (!response.ok) {
    throw new Error(`Unable to connect to server: ${response.statusText} (${response.status})`);
  }

  return response.json().then((json) => {
    console.log("json response:", json);

    if(json.error_description) {
      throw new Error(json.error_description);
    }

    return json;
  });
}


function getURL(base_url, params, isTextResponse) {

  console.log(`GETting from ${base_url}`);
  let url = base_url;
  if(params !== undefined) {
    console.log("params:");
    console.log(params);
    let esc = encodeURIComponent;
    let querystring = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
    if(querystring.length > 0) {
      url = url + "?" + querystring;
    }
    console.log(`full url = ${url}`);
  }

  return fetch(url, {
      // body: body, // must match 'Content-Type' header
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, same-origin, *omit
      // headers: {
        // 'user-agent': 'Mozilla/4.0 MDN Example',
      //   'content-type': 'application/json'
      // },
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, cors, *same-origin
      // redirect: 'follow', // manual, *follow, error
      // referrer: 'no-referrer', // *client, no-referrer
    })
    .then(function(response) {
      redirectToLoginIfUnauthorized(response);
      if(isTextResponse) {
        console.log("Text response");
        return getTextResponse(response);
      } else {
        console.log("JSON response");
        return getJSONResponse(response);
      }
    });
}


function postData(url, body, method='POST') {

  console.log(`posting to ${url}`);
  if(body !== undefined) {
    console.log("body:");
    if(body instanceof FormData) {
      for(let pair of body.entries()) {
        console.log(pair);
      }
    } else {
      console.log(body);
    }
  }

  // Default options are marked with *
  return fetch(url, {
      body: body, // must match 'Content-Type' header
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, same-origin, *omit
      // headers: {
        // 'user-agent': 'Mozilla/4.0 MDN Example',
      //   'content-type': 'application/json'
      // },
      method: method, // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, cors, *same-origin
      // redirect: 'follow', // manual, *follow, error
      // referrer: 'no-referrer', // *client, no-referrer
    })
    .then(function(response) {
      redirectToLoginIfUnauthorized(response);
      return getJSONResponse(response);
    });
}


export { ENDPOINTS, fakeSetAuthenticated, isAuthenticated, getURL, postData };
