import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment-timezone';

import { pluralize } from '../utils/misc';
import { PAGES } from '../utils/constants';

const localTZ = moment.tz.guess();
const phoneUtil = PhoneNumberUtil.getInstance();


class FaxNumberList extends Component {

  handleNumberSelected = (event) => {
    event.preventDefault();
    const { didSelectNumber } = this.props;

    const faxNumberUrlsafe = event.currentTarget.getAttribute('data-urlsafe-key');
    console.log("selected number's urlsafe key:", faxNumberUrlsafe);
    if(!faxNumberUrlsafe) {
      didSelectNumber(null);
      return;
    }

    if(!this.props.faxNumbers) {
      console.log("selected number, but no fax numbers available!");
      return;
    }
    const faxNumber = this.props.faxNumbers.find((num) => {
      return num.urlsafe_key === faxNumberUrlsafe;
    });

    didSelectNumber(faxNumber);
  };

  render() {
    const {
      faxNumbers, filterExpired, includeGracePeriod, shouldShowGetNumberButton, isSelectable,
    } = this.props;
    const { handleNumberSelected } = this;
    const filteredNumbers = !filterExpired ? faxNumbers : getUnexpiredNumbers(faxNumbers, includeGracePeriod);
    const getFaxNumberDiv =
      <div className="level is-mobile">
        <span className="level-left">No fax numbers</span>
        { shouldShowGetNumberButton &&
          <div className="level-right">
            <Link to={PAGES.storeSubscriptions}>Get Number</Link>
          </div>
        }
      </div>;


    const listClassName = isSelectable ? "jnf-clickable-list" : "jnf-list";
    const listItemClassName = isSelectable ? "jnf-clickable-list-item" : "jnf-list-item";
    const title = isSelectable ? "Choose a Fax Number" : "Fax Numbers";

    const listItems = ((!filteredNumbers || filteredNumbers.length === 0)
      ? getFaxNumberDiv
      : filteredNumbers.map((faxNumber) => (
          <li key={faxNumber.urlsafe_key} className={listItemClassName}>
            { isSelectable
              ? <a href="/" data-urlsafe-key={faxNumber.urlsafe_key} onClick={handleNumberSelected}>
                  <FaxNumberDisplay faxNumber={faxNumber} />
                </a>
              : <FaxNumberDisplay faxNumber={faxNumber} isManageLinkVisible={true}/>
            }
          </li>
        ))
    );

    return (
      <section className="FaxNumberList section">
        <div className="container">
          <h2>{title}</h2>
          <ul className={listClassName}>
            {listItems}
          </ul>
        </div>
      </section>
    );
  }
}


function getUnexpiredNumbers(faxNumbers, includeGracePeriod=false) {
  if(!faxNumbers) {
    return null;
  }

  return faxNumbers.filter((currentValue) => {
    const subStatus = getSubscriptionStatus(currentValue);
    return subStatus === SUBSCRIPTION_STATUS.active || (includeGracePeriod && subStatus === SUBSCRIPTION_STATUS.expiredGracePeriod);
  });
}

const RESERVATION_STATUS = {
  requested: "requested",
  reserved: "reserved",
  expired: "expired",
  unavailable: "unavailable",
  error: "error",
};

function getReservationStatus(faxNumber) {
  if(!faxNumber || !faxNumber.reservation_status) {
    return RESERVATION_STATUS.error;
  }

  const status = Number(faxNumber.reservation_status);
  switch(status) {
    case 0: return RESERVATION_STATUS.requested;
    case 1: return RESERVATION_STATUS.reserved;
    case 2: return RESERVATION_STATUS.expired;
    case 3: return RESERVATION_STATUS.unavailable;
    default: return RESERVATION_STATUS.error;
  }
}

const SUBSCRIPTION_STATUS = {
  uninitialized: "uninitialized",
  active: "active",
  expiredGracePeriod: "expiredGracePeriod",
  expired: "expired",
};

const GRACE_PERIOD_DAYS = 28;

function getSubscriptionStatus(faxNumber) {
  if(!faxNumber || !faxNumber.expiration_date) {
    return SUBSCRIPTION_STATUS.uninitialized;
  }

  const expirationDate = moment.tz(faxNumber.expiration_date, 'UTC');
  if(!expirationDate) {
    return SUBSCRIPTION_STATUS.uninitialized;
  }

  const nowDate = moment();
  if(expirationDate && expirationDate > nowDate) {
    return SUBSCRIPTION_STATUS.active;
  }

  // mutates the date
  expirationDate.add(GRACE_PERIOD_DAYS, 'days');
  if(expirationDate > nowDate) {
    return SUBSCRIPTION_STATUS.expiredGracePeriod;
  } else {
    return SUBSCRIPTION_STATUS.expired;
  }
}



function FaxNumberDisplay({ faxNumber, isManageLinkVisible, isUnselectVisible, didSelectNumber }) {

  console.log("Fax Number List Item:", faxNumber);
  const { fax_number, usage_period } = faxNumber;

  const number = phoneUtil.parseAndKeepRawInput(fax_number, null);
  const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);

  const reservationStatus = getReservationStatus(faxNumber);
  let reservationStatusText;
  switch(reservationStatus) {
    case RESERVATION_STATUS.requested:
      reservationStatusText = "Reserving number ...";
      break;
    case RESERVATION_STATUS.reserved:
      reservationStatusText = "Number is ready to send and receive";
      break;
    case RESERVATION_STATUS.expired:
      reservationStatusText = "Number is expired" +
        (isManageLinkVisible ? "; please click 'Manage' to get a new number" : "");
      break;
    default:
      reservationStatusText = "Number is unavailable" +
        (isManageLinkVisible ? "; please click 'Manage' to get a new number" : "");
  }

  const subStatus = getSubscriptionStatus(faxNumber);
  let subscriptionText = "Subscription expired";
  if(subStatus === SUBSCRIPTION_STATUS.expiredGracePeriod) {
    subscriptionText = "Subscription expired. Please renew to keep your number.";
  } else if (subStatus === SUBSCRIPTION_STATUS.active && usage_period) {
    const { pages_remaining, period_end } = usage_period;
    const periodEndDate = moment.tz(period_end, 'UTC');
    const periodEndDateText = periodEndDate ? periodEndDate.tz(localTZ).format('lll') : period_end;
    const pagesText = pluralize(Number(pages_remaining), 'page');
    subscriptionText = pagesText + " left through " + periodEndDateText;
  }

  function NumberLink({ location }) {
    return (
      <Link to={{
        pathname: PAGES.faxNumber + "/" + faxNumber.urlsafe_key,
        state: { source: location }
      }}>
        Manage
      </Link>
    );
  }
  const NumberLinkWithSource = withRouter(NumberLink);

  const unselectNumber = (event) => {
    event.preventDefault();
    didSelectNumber(null);
  };

  return (
    <div className="columns is-vcentered FaxNumberDisplay">
      <div className="column">
        <div>{formattedNumber}</div>
        <div>{reservationStatusText}</div>
        <div>{subscriptionText}</div>
      </div>
      <div className="column">
        { isManageLinkVisible &&
          <div className="level-right">
            <NumberLinkWithSource />
          </div>
        }
        { isUnselectVisible && didSelectNumber &&
          <div className="level-right">
            <button className="button is-primary jnf-button" onClick={unselectNumber}>Change</button>
          </div>
        }
      </div>
    </div>
  );
}


export { getUnexpiredNumbers, FaxNumberDisplay, RESERVATION_STATUS, getReservationStatus, SUBSCRIPTION_STATUS, getSubscriptionStatus };

export default FaxNumberList;
