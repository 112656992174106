import React, { Component } from 'react';

import ErrorDisplay from './ErrorDisplay';

import LoadingIndicator from '../utils/LoadingIndicator';

class StorePaymentMethodList extends Component {

  handleDeleteClicked = (event) => {
    event.preventDefault();
    const pmID = event.currentTarget.getAttribute('data-id');
    console.debug("handleDeleteClicked: pmID:", pmID);
    this.props.deletePaymentMethod && this.props.deletePaymentMethod(pmID);
  };

  handlePaymentMethodClicked = (event) => {
    event.preventDefault();
    const selectedPaymentMethodID = event.currentTarget.getAttribute('data-id');
    this.props.paymentMethodSelected && this.props.paymentMethodSelected(selectedPaymentMethodID);
  };

  render() {
    const { selectedPaymentMethodID, paymentMethods, isLoading, error } = this.props;
    const { handleDeleteClicked, handlePaymentMethodClicked } = this;

    const selectedPaymentMethod = (!paymentMethods || !selectedPaymentMethodID) ? null : paymentMethods.find((pm) => {
      return pm.id === selectedPaymentMethodID;
    });

    const filteredPMs = (!paymentMethods) ? null : paymentMethods.filter((pm) => {
      return pm && pm.id && pm.card;
    });

    console.debug("filtered PMs:", filteredPMs);
    console.debug("selectedPaymentMethod:", selectedPaymentMethod);

    if(selectedPaymentMethod) {
      return (
        <div className="jnf-store-payment-method-list">
          <h3>Payment Method</h3>
          <StorePaymentMethod
            {...selectedPaymentMethod}
            isUnselectVisible={true} handleUnselectClicked={handlePaymentMethodClicked}
          />
        </div>
      );
    } else {
      return (
        <div className="jnf-store-payment-method-list">
          <ErrorDisplay errors={[error]} type="bar" />
          { filteredPMs && filteredPMs.length > 0 &&
            <div>
              <h3>Payment Methods</h3>
              <ul className="jnf-clickable-list">
                {filteredPMs.map((pm) => (
                  <li key={pm.id}>
                    <StorePaymentMethod
                      {...pm}
                      {...{handleDeleteClicked, handlePaymentMethodClicked}}
                    />
                  </li>
                ))}
              </ul>
            </div>
          }
          <LoadingIndicator isLoading={isLoading} />
        </div>
      );
    }
  }
}


function StorePaymentMethod(
    { id, billing_details, card,
      isUnselectVisible, handleUnselectClicked,
      handleDeleteClicked, handlePaymentMethodClicked
    }) {

  const { brand, exp_month, exp_year, last4 } = card || {};
  const { address, name } = billing_details || {};
  const { postal_code } = address || {};

  const brandUpper = brand ? brand.charAt(0).toUpperCase() + brand.slice(1) : '';

  const optionsDiv = isUnselectVisible
  ?
    <div>
      <button className="jnf-outline-button" type="button" onClick={handleUnselectClicked}>Change</button>
    </div>
  :
    <div>
      <button className="jnf-outline-button" type="button" data-id={id} onClick={handleDeleteClicked}>Remove</button>
    </div>;

  const innerDiv =
    <div>
      { (brandUpper && last4)
        ? <p>{brandUpper} ending with {last4}</p>
        : <p>Processing, please wait ...</p>
      }
      <p>Expires {exp_month}/{exp_year}</p>
      <p>Name on card: {name}</p>
      <p>Billing ZIP: {postal_code}</p>
    </div>;

  return (
    <div className="level is-mobile jnf-clickable-list-item jnf-payment-method StorePaymentMethod">
      <div className="level-left jnf-payment-method-description">
        <div>
          { isUnselectVisible
            ? innerDiv
            :
              <a className="jnf-payment-method-link" href="/" data-id={id} onClick={handlePaymentMethodClicked}>
                {innerDiv}
              </a>
          }
        </div>
      </div>
      <div className="level-right">
        {optionsDiv}
      </div>
    </div>
  );
}


export { StorePaymentMethod };

export default StorePaymentMethodList;
