import React from 'react';

function ErrorDisplay({ errors, type }) {
  const DEBUG_MODE = false;
  if ((errors.every(error => { return !error }) || !errors.length) && !DEBUG_MODE) {
    return null;
  }

  const simplifiedErrors = errors.filter((err) => (err !== null && err !== undefined))
    .map((err) => (
      err.message
      + (err.code ? ' (' + err.code + ')' : "")
    ));

  const errorSet = new Set(simplifiedErrors);

  const errorItems = [];
  errorSet.forEach((errStr) => (
    errorItems.push(
      <li className="error-list-item" key={errStr}>{errStr}</li>
    )
  ));

  return (
    <div className={'ErrorDisplay ' + type}>
      <ul className="error-list">
        {DEBUG_MODE && <li>This is a test error message.</li>}
        {errorItems}
      </ul>
    </div>
  );
}

export default ErrorDisplay;
