import React, { Component } from 'react';
import ReactGA from 'react-ga';

import ModalDocument from './ModalDocument';
import ErrorDisplay from './ErrorDisplay';
import { pluralize } from '../utils/misc';

class NewFaxFileListItem extends Component {

  state = {
    isVisibleModalDocument: false,
  };

  toggleModalDocument = (event) => {
    this.setState((prevState) => {
      return { isVisibleModalDocument: !prevState.isVisibleModalDocument };
    });
  };

  handleModalDocument = (event) => {
    event.preventDefault();
    this.setState(
      { isVisibleModalDocument: true },
      () => { ReactGA.modalview('/fax/document'); });
  };

  handleRemove = (event) => {
    this.props.removeFileCallback(this.props.index);
  };

  render() {
    const { doc, listType } = this.props;
    if (!doc) {
      return null;
    }

    const { handleModalDocument, handleRemove } = this;
    const { file, pages } = doc;
    const { name } = file;
    const { isVisibleModalDocument } = this.state;

    let preview;
    if ("preview" in file) {
      // Resend => file is not a file object; just json from the server
      preview = file.preview;
    } else {
      // Dropzone 7.0 does not add previews by default
      preview = URL.createObjectURL(file);
    }

    let { error } = doc;
    if (!preview) {
      error = new Error('File is missing.');
    }

    return (
      <li>

        <div className="columns is-mobile jnf-document">
          <span className="column jnf-document-name">
            {preview && !error
              ? <a href="/" onClick={handleModalDocument}> {name + ' ' + pluralize(pages, "page")} </a>
              : ''
            }
            {error && error.message === 'No password given' && name}
            {error && error.message !== 'No password given' &&
              <span>
                {name}
                <ErrorDisplay errors={[error]} />
              </span>
            }

          </span>
          <span className="column is-narrow jnf-document-actions">
            <div>
              {preview && !error &&
                <button className="button jnf-button-view" type="button" onClick={handleModalDocument}>View</button>
              }
              {preview && !error && listType !== "resend" &&
                <button className="button jnf-button-remove" type="button" onClick={handleRemove}>Remove</button>
              }
              {preview && error &&
                <button className="delete jnf-button-x" type="button" onClick={handleRemove}></button>
              }
            </div>
          </span>
        </div>

        <ModalDocument src={preview} name={name} isVisible={isVisibleModalDocument} handleCancel={this.toggleModalDocument} />
      </li>
    );
  }
}


function NewFaxFileList({ title, files, removeFileCallback, passwordCallback, listType }) {
  if (!files || files.length === 0) {
    return null;
  }

  const listItems = files.map((doc, index) =>
    <NewFaxFileListItem key={doc.hash} {...{ doc, index, listType, removeFileCallback, passwordCallback }} />
  );

  return (
    <div className={"NewFaxFileList " + listType}>
      {title && <p className="has-text-weight-semibold">{title}</p>}
      <ul>
        {listItems}
      </ul>
    </div>
  );
}


export default NewFaxFileList;
