import React, { Component } from 'react';
import ReactGA from 'react-ga';

import ErrorDisplay from './ErrorDisplay';

import { ENDPOINTS, getURL } from '../utils/fetching';
import { LOCAL_MODE, INPUT_MAX_LENGTH } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

class StorePromoForm extends Component {

  state = {
    promoCode : null,
    isPromoFormVisible: false,
    isPromoFormLoading: false,
    promoError: null,
  };

  togglePromoVisible = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isPromoFormVisible: !prevState.isPromoFormVisible,
    }));
  };

  handlePromoCodeChange = (event) => {
    const newCode = event.currentTarget.value;
    this.setState({ promoCode: newCode});
  };

  handlePromoSubmit = (event) => {
    event.preventDefault();

    this.setState({ isPromoFormLoading: true });

    const { promoCode } = this.state;
    if(!promoCode) {
      this.setState({
        isPromoFormLoading: false,
        promoError: new Error("Please enter a promo code and try again."),
      });
      return;
    }

    if(LOCAL_MODE) {
      setTimeout(() => {
        if(promoCode === "JNFAX10") {
          this.setState({
            promoError: null,
            isPromoFormLoading: false,
          });
          this.props.handleApplyPromoCompleted && this.props.handleApplyPromoCompleted({
            promoCode,
            promoText: "10% off (JNFAX10)",
            promoRate: 0.1,
            promoYears: 1,
            promoMonths: 3,
          });
        } else {
          this.setState({
            promoError: new Error("Promo code " + promoCode + " expired or invalid."),
            isPromoFormLoading: false,
          });
        }
      }, 1000);
      return;
    }

    getURL(ENDPOINTS.promotion, {"code": promoCode})
      .then((json) => {
        console.log(json);
        if (json && json.description && (json.amount || json.rate)) {
          this.setState({ isPromoFormLoading: false, promoError: null });
          if(this.props.requireSubscriptionPromo &&
             !(json.rate && json.discounted_months && json.discounted_years)) {
            throw new Error("Promo code not valid for subscriptions");
          }
          this.props.handleApplyPromoCompleted && this.props.handleApplyPromoCompleted({
            promoCode,
            promoText: json.description,
            promoRate: json.rate,
            promoAmount: json.amount,
            promoMonths: json.discounted_months,
            promoYears: json.discounted_years,
          });
        } else {
          throw new Error("There was a problem with this promo code. Please check the code and try again.");
        }
      })
      .catch((error) => {
        this.setState({ isPromoFormLoading: false, promoError: error });
        ReactGA.exception({ description: error.message });
      });
  };

  render() {

    const { promoCode, isPromoFormVisible, isPromoFormLoading, promoError } = this.state;
    const { togglePromoVisible, handlePromoCodeChange, handlePromoSubmit } = this;

    return (
      <div className="StorePromoForm">
        <div className="jnf-promo-form-toggle-link label">
          <a href="/" onClick={togglePromoVisible}>
            Add promo code &#x25BE;
          </a>
        </div>

        {isPromoFormVisible
          ? <form onSubmit={handlePromoSubmit}>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input className="input" type="text" name="promo-code"
                    defaultValue={promoCode} placeholder="Enter Code"
                    onChange={handlePromoCodeChange} maxLength={INPUT_MAX_LENGTH} required />
                </div>

                <div className="control">
                  <button className="button is-primary is-outlined jnf-button-promo-submit" type="button" onClick={handlePromoSubmit}>Apply</button>
                </div>
              </div>

              <div className="help is-danger">
                <ErrorDisplay errors={[promoError]} />
              </div>
              <LoadingIndicator isLoading={isPromoFormLoading} />
            </form>
          : null
        }
      </div>
    );
  }
};

export default StorePromoForm;
