import React from 'react';

function Modal({ title, children, footer, handleCancel }) {

  const printModalStyle = (
    <style type="text/css" media="print">
      {`
      .App { height: 0; max-height: 0; overflow: hidden; }
      .modal-card-head, .modal-card-foot {
        display: none;
      }
      .modal-card {
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
      @page { margin: 0; }
    `}
    </style>
  );

  return (
    <div className="Modal modal is-active">
      <div className="modal-background" onClick={handleCancel}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          {title && <p className="modal-card-title">{title}</p>}
          {handleCancel && <button className="delete" aria-label="close" onClick={handleCancel}></button>}
        </header>
        <section className="modal-card-body">
          {children}
        </section>
        <footer className="modal-card-foot">
          {footer}
        </footer>
      </div>
      {printModalStyle}
    </div>
  );
}

export default Modal;
