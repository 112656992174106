import React, { Component } from 'react';

class AccountVerified extends Component {

  render() {
    return (
      <section className="AccountVerified section jnf-first">
        <div className="container jnf-narrow-center-wrapper">
          <h1>Account Setup Finished</h1>
          <p>Thank you for signing up with JotNot Fax!</p>
        </div>
      </section>
    );
  }
}

export default AccountVerified;
