import React, { Component } from 'react';

import { getURL, ENDPOINTS } from '../utils/fetching.js';
import LoadingIndicator from '../utils/LoadingIndicator';
import { LOCAL_MODE } from '../utils/constants';

import ErrorDisplay from './ErrorDisplay';


let _cachedAreaCodes = [];

// Properties in an Area Code object:
// area_code = ndb.StringProperty()
// region_code = ndb.StringProperty()
// state_or_province = ndb.StringProperty()
// description = ndb.StringProperty()
// related_area_codes = ndb.StringProperty()
class StoreAreaCodePicker extends Component {

  state = {
    searchText: "",
    areaCodes: [],
    isPending: false,
    areaCodeError: null,
  };

  componentDidMount() {
    if(_cachedAreaCodes && _cachedAreaCodes.length > 0) {
      this.setState({ areaCodes: _cachedAreaCodes });
    } else {
      this.fetchAreaCodes();
    }
  }

  componentWillUnmount() {
    _cachedAreaCodes = this.state.areaCodes;
  }

  fetchAreaCodes = () => {
    if(LOCAL_MODE) {
      this.setState({ areaCodeError: null, isPending: false, areaCodes: [
        {
          area_code: "111",
          region_code: "US",
          state_or_province: "CA",
          description: "California",
          related_area_codes: "222, 333"
        },
        {
          area_code: "555",
          region_code: "CA",
          state_or_province: "ON",
          description: "Ontario",
          related_area_codes: "666, 777"
        },
      ]});

      return;
    }

    this.setState({ isPending: true });
    getURL(ENDPOINTS.areaCodes)
      .then((json) => {
        console.log("result from fetch area codes:", json);
        this.setState({ areaCodes: json.results, isPending: false, areaCodeError: null});
      })
      .catch((error) => {
        this.setState({ isPending: false, areaCodeError: error })
      });
  };

  handleSearchTextChanged = (event) => {
    event.preventDefault();
    const searchText = event.currentTarget.value;
    this.setState({ searchText });
  };

  render() {
    const { areaCode, handleAreaCodeClicked } = this.props;
    const { searchText, areaCodes, isPending, areaCodeError } = this.state;
    const { handleSearchTextChanged } = this;
    console.log("rendering area code picker with area codes", areaCodes);

    const searchTerms = searchText ? searchText.trim().split(" ") : [];
    const searchPlaceholder = "area code, state, province, or major city name";

    const filteredAreaCodes = (!areaCodes) ? [] : ((!searchTerms || searchTerms.length === 0) ? areaCodes : areaCodes.filter((ac) => {
      const acString = Object.keys(ac).reduce((total, cur) => {
        return total + " " + ac[cur];
      }, "");
      return searchTerms.every((st) => {
        return acString.search(new RegExp(st, "i")) >= 0;
      });
    }));

    console.log("rendering area code picker with filtered codes", filteredAreaCodes);

    if(areaCode) {
      return (
        <div className="StoreAreaCodePicker">
          <h3>Selected Area Code</h3>
          <StoreAreaCode {...areaCode} isEditVisible={true} handleEditClicked={handleAreaCodeClicked}/>
        </div>
      );
    } else {
      return (
        <div className="StoreAreaCodePicker">
          <h3>Available Area Codes</h3>
          <ErrorDisplay errors={[areaCodeError]} type="bar" />
          <LoadingIndicator isLoading={isPending} />
          <div className="jnf-area-code-search-bar">
            <label htmlFor="jnf-area-code-search-bar-input"><strong>Search:</strong>
              <input
                id="jnf-area-code-search-bar-input" type="text"
                defaultValue={searchText} placeholder={searchPlaceholder}
                onChange={handleSearchTextChanged}
              />
            </label>
          </div>
          { (filteredAreaCodes && filteredAreaCodes.length > 0)
            ?
              <ul className="jnf-clickable-list">
                {filteredAreaCodes.map((areaCode) => (
                  <li key={areaCode.area_code}>
                    <a href="/" data-area-code={JSON.stringify(areaCode)} onClick={handleAreaCodeClicked}>
                      <StoreAreaCode {...areaCode} />
                    </a>
                  </li>
                ))}
              </ul>
            :
              <p>No available area codes</p>
          }
        </div>
      );
    }
  }
}


function StoreAreaCode({ area_code, state_or_province, description, isEditVisible, handleEditClicked }) {
  return (
    <div className="columns is-mobile jnf-clickable-list-item StoreAreaCode">
      <span className="column is-2 has-text-left">{area_code}</span>
      <span className="column is-2 has-text-left">{state_or_province}</span>
      <span className="column">{description}</span>
      { isEditVisible &&
        <span className="column is-3 has-text-right jnf-subscription-edit">
          <button className="jnf-outline-button" type="button" onClick={handleEditClicked}>Change</button>
        </span>
      }
    </div>
  );
}


export { StoreAreaCode };

export default StoreAreaCodePicker;
