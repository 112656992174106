import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import ErrorDisplay from './ErrorDisplay';

import { ENDPOINTS, postData, fakeSetAuthenticated } from '../utils/fetching';
import { PAGES, LOCAL_MODE } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

class Signup extends Component {

  state = {
    isPosting: false,
    error: null,
    redirectToHome: false,
  };

  submitSignupForm = (event) => {
    event.preventDefault();

    if (LOCAL_MODE) {
      fakeSetAuthenticated(true);
      this.setState({error: null, isPosting: false, redirectToHome: true});
      return;
    }

    this.setState({isPosting: true});
    const formData = new FormData(event.target);
    postData(ENDPOINTS.signup, formData)
      .then( (json) => {
        console.log(json);
        this.setState({error: null, isPosting: false, redirectToHome: true});
      })
      .catch( (error) => {
        console.error(error);
        this.setState({error, isPosting: false});
      });
  }

  render() {
    const { redirectToHome, isPosting, error } = this.state;

    if (redirectToHome) {
      return <Redirect to={PAGES.landing} push/>;
    }

    return (
      <section className="Signup section jnf-first">
        <div className="container jnf-narrow-center-wrapper">
          <h1>Sign up</h1>
          <form name="signupform" onSubmit={this.submitSignupForm}>

            <div className="field">
              <label className="label" htmlFor="email">Email</label>
              <div className="control">
                <input className="input" type="email" name="email" autoComplete="email" required />
              </div>
              <div className="help"></div>
            </div>

            <div className="field">
              <label className="label" htmlFor="password">Password</label>
              <div className="control">
                <input className="input" type="password" name="password" autoComplete="current-password" required />
              </div>
              <div className="help"></div>
            </div>

            <div className="field">
              <label className="label" htmlFor="confirm-password">Confirm password</label>
              <div className="control">
                <input className="input" type="password" name="confirm-password" autoComplete="new-password" required />
              </div>
              <div className="help is-danger"><ErrorDisplay errors={[error]} /></div>
            </div>

            <button type="submit" name="submitbutton" className="button is-primary">Sign up</button>
          </form>
          <LoadingIndicator isLoading={isPosting} />
        </div>
      </section>
    );
  }
}

export default Signup;
