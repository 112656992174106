import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import ErrorDisplay from './ErrorDisplay';
import Alert from './Alert';
import {
  FaxNumberDisplay, RESERVATION_STATUS, getReservationStatus,
  SUBSCRIPTION_STATUS, getSubscriptionStatus
} from './FaxNumberList';

import StoreNumberPicker from './StoreNumberPicker';
import StoreAreaCodePicker from './StoreAreaCodePicker';
import StoreSubscriptionPicker from './StoreSubscriptionPicker';
import StoreCheckoutForm from './StoreCheckoutForm';

import LoadingIndicator from '../utils/LoadingIndicator';
import { PAGES, LOCAL_MODE } from '../utils/constants.js';
import { postData, ENDPOINTS } from '../utils/fetching.js';
import SUBSCRIPTIONS from '../utils/subscriptions';


const PAGES_PER_CREDIT = 5;


class FaxNumberPage extends Component {

  getFaxNumber = () => {
    const { faxNumberID } = this.props.match.params || { faxNumberID: null };
    const { faxNumbers } = this.props;
    if(faxNumbers === null) {
      // fetch user and show spinner
      this.props.fetchUser && this.props.fetchUser();
      return null;
    }
    if(!faxNumbers || ! faxNumberID) { return undefined; }
    return faxNumbers.find((num) => (
      num && num.urlsafe_key === faxNumberID
    ));
  };

  render() {
    const { source } = this.props.location.state || { source: { pathname: PAGES.home } };
    const faxNumber = this.getFaxNumber();

    console.log("rendering FaxNumberPage", faxNumber, source);

    const subStatus = getSubscriptionStatus(faxNumber);
    const resStatus = getReservationStatus(faxNumber);

    if(faxNumber === undefined) {
      return (
        <Redirect to={source} />
      );
    }

    return (
      <div className="FaxNumberPage">
        <div className="container">
          <section>
            <h1>Manage Your Fax Number</h1>
            <Link to={source}>&#8592; Back</Link>
          </section>

          <section>
            <h2>Your Number</h2>
            { faxNumber
              ? <FaxNumberDisplay faxNumber={faxNumber} />
              : <LoadingIndicator isLoading={true} />
            }
          </section>

          { faxNumber &&
            <section>
              { subStatus === SUBSCRIPTION_STATUS.active &&
                (resStatus === RESERVATION_STATUS.expired || resStatus === RESERVATION_STATUS.unavailable || resStatus === RESERVATION_STATUS.error) &&
                <SubscriptionChangeNumberForm {...{faxNumber}} {...this.props} />
              }
              { subStatus === SUBSCRIPTION_STATUS.active &&
                (resStatus === RESERVATION_STATUS.requested || resStatus === RESERVATION_STATUS.reserved) &&
                <ul className="jnf-list jnf-active-subscription-options">
                  <li className="jnf-list-item">
                    <SubscriptionAddPagesForm {...{faxNumber}} {...this.props} />
                  </li>
                  <li className="jnf-list-item">
                    <SubscriptionCancelOnOffForm {...{faxNumber}} {...this.props} />
                  </li>
                </ul>
              }

              { (subStatus === SUBSCRIPTION_STATUS.expired || subStatus === SUBSCRIPTION_STATUS.expiredGracePeriod) &&
                <div className="jnf-expired-subscription-options">
                  <SubscriptionRenewForm {...{faxNumber}} {...this.props} />
                </div>
              }

              { subStatus === SUBSCRIPTION_STATUS.uninitialized &&
                <div className="jnf-uninitialized-subscription-options">
                  <ErrorDisplay errors={[Error("Subscription transaction failed; please contact support to resolve")]} />
                </div>
              }
            </section>
          }
        </div>
      </div>
    );
  }
}


class SubscriptionChangeNumberForm extends Component {

  state = {
    areaCode: null,
    number: null,

    isLoading: false,
    changeNumberError: null,
  };

  handleAreaCodeClicked = (event) => {
    event.preventDefault();
    if(!event.currentTarget.hasAttribute('data-area-code')) {
      this.setState({ areaCode: null });
      return;
    }
    let areaCode = null;
    try {
      areaCode = JSON.parse(event.currentTarget.getAttribute('data-area-code'));
    } catch(err) {
      console.error(err);
    }
    if(areaCode) {
      this.setState({ areaCode, isAreaCodePickerVisible: false, isNumberPickerVisible: true, isSubmitVisible: false, changeNumberError: null });
    }
  };

  handleNumberClicked = (event) => {
    event.preventDefault();
    const number = event.currentTarget.getAttribute('data-fax-number');
    this.setState({ number, isAreaCodePickerVisible: false, isNumberPickerVisible: false, isSubmitVisible: true, changeNumberError: null });
  };

  handleChangeNumber = (event) => {
    event.preventDefault();

    if(this.state.isLoading) {
      this.setState({ changeNumberError: Error("Another operation is in progress. Please try again later") });
      return;
    }

    this.setState({ isLoading: true, changeNumberError: null });

    const { faxNumber } = this.props;
    const subStatus = getSubscriptionStatus(faxNumber);
    const resStatus = getReservationStatus(faxNumber);

    const { areaCode, number } = this.state;

    if(resStatus === RESERVATION_STATUS.reserved) {
      this.setState({ isLoading: false, changeNumberError: Error("Please contact support to change the number for an active subscription") });
      return;
    }

    if(resStatus === RESERVATION_STATUS.requested) {
      this.setState({ isLoading: false, changeNumberError: Error("Reserving number with phone number, please wait. If this persists, please contact support") });
      return;
    }

    if(subStatus !== SUBSCRIPTION_STATUS.active) {
      this.setState({ isLoading: false, changeNumberError: Error("No active subscription. Please purchase or renew to get a number") });
      return;
    }

    if(!areaCode || !number) {
      this.setState({ isLoading: false, changeNumberError: Error("Please select a number to reserve, and then try again") });
      return;
    }

    const formData = new FormData();
    formData.append("fax_number", number);

    const endpoint = ENDPOINTS.faxNumber + "/" + faxNumber.urlsafe_key;
    postData(endpoint, formData, "PUT")
      .then((json) => {
        if(json && json.urlsafe_key) {
          this.props.fetchUser && this.props.fetchUser();
          // update fax number data, throw up success alert?
          this.setState({ isLoading: false });
        } else {
          throw new Error("There was a problem with reserving this number. Please try again later.");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, changeNumberError: error });
      });
  };

  render() {

    const { areaCode, number: cartNumber, isLoading, changeNumberError } = this.state;
    const { handleAreaCodeClicked, handleNumberClicked, handleChangeNumber } = this;

    return (
      <div className="SubscriptionChangeNumberForm">
        <p>Please pick a new number</p>
        <form onSubmit={handleChangeNumber}>
          <ErrorDisplay errors={[changeNumberError]} />
          <StoreAreaCodePicker {...{areaCode, handleAreaCodeClicked}} />
          <StoreNumberPicker {...{areaCode, cartNumber, handleNumberClicked}} />
          <button className="button jnf-primary-button jnf-full-width-button" type="button" onClick={handleChangeNumber}>Get This Number</button>
          <LoadingIndicator {...{isLoading}} />
        </form>
      </div>
    );
  }
}


class SubscriptionAddPagesForm extends Component {

  state = {
    isAddPagesVisible: false,
    isLoading: false,
    convertCredits: 0,
    convertPages: 0,
    validateCreditsError: null,
    convertCreditsError: null,
  };

  handleToggleAddPages = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isAddPagesVisible: !prevState.isAddPagesVisible
    }));
  };

  handleCreditsTextChanged = (event) => {
    event.preventDefault();
    const { credits } = this.props;
    let convertCredits = Number(event.currentTarget.value);
    let convertPages = 0;
    let validateCreditsError = null;
    if(convertCredits) {
      if(convertCredits >= 0) {
        if(convertCredits > credits) {
          convertCredits = credits;
          validateCreditsError = Error("Not enough credits. If you need more pages, please purchase additional credits in the Store, then return here.")
        }
        convertPages = PAGES_PER_CREDIT * convertCredits;
      } else {
        convertCredits = 0;
        convertPages = 0;
        validateCreditsError = Error("Please enter a number greater than zero");
      }
    } else {
      convertCredits = 0;
    }
    this.setState({ convertCredits, convertPages, validateCreditsError });
  };

  handleAddPages = (event) => {
    event.preventDefault();
    const { convertCredits, convertPages, isLoading } = this.state;
    const { credits } = this.props;

    if(!convertCredits || convertCredits < 0) {
      this.setState({ validateCreditsError: Error("Invalid number of credits") });
      return;
    }

    if(!credits || credits < convertCredits) {
      this.setState({ validateCreditsError: Error("Insufficient credit balance. Please purchase additonal credits in the Store, then return here.") });
      return;
    }

    this.setState({ validateCreditsError: null });

    if(!convertPages || convertPages !== PAGES_PER_CREDIT * convertCredits) {
      this.setState({ convertCreditsError: Error("Unexpected number of pages to add") });
      return;
    }

    if(isLoading) {
      this.setState({ convertCreditsError: Error("Another operation in progress; please try again later.") });
      return;
    }

    const { faxNumber } = this.props;
    if(!faxNumber || !faxNumber.usage_period || !faxNumber.usage_period.urlsafe_key) {
      this.setState({ convertCreditsError: Error("No active subscription. Please purchase or renew first.") })
      return;
    }

    this.setState( { convertCreditsError: null, isLoading: true });

    const formData = new FormData();
    formData.append("pages", convertPages);
    formData.append("credits", convertCredits);

    const endpoint = ENDPOINTS.usagePeriod + "/" + faxNumber.usage_period.urlsafe_key + "/pages-purchase";
    console.log("purchasing pages at endpoint:", endpoint);
    console.log("form data:", formData);
    postData(endpoint, formData)
      .then((json) => {
        if(json && json.pages && json.credits && json.urlsafe_key) {
          this.props.fetchUser && this.props.fetchUser();
          // update fax number data, throw up success alert?
          this.setState({ isLoading: false, convertCredits: 0, convertPages: 0 });
        } else {
          throw new Error("There was a problem with updating your account. Please try again later.");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, convertCreditsError: error });
      });
  };

  render() {

    const { credits } = this.props;
    const {
      isAddPagesVisible,
      isLoading, convertCredits, convertPages,
      validateCreditsError, convertCreditsError,
    } = this.state;

    const { handleToggleAddPages, handleCreditsTextChanged, handleAddPages } = this;

    return (
      <form onSubmit={handleAddPages}>
        <a href="/" onClick={handleToggleAddPages}>
          <h4 className="title is-6">Add Pages to Current Usage Period &#x25BE;</h4>
        </a>
        { isAddPagesVisible &&
          <div>
            <ErrorDisplay errors={[convertCreditsError]} type="bar"/>
            <p>
              If you are running low on pages, you can add more to the current usage period.
              JotNot converts credits to pages at a rate of 5 pages per credit.
              Buy the number of credits that you need in the Store page, then return here to convert them to pages.
            </p>
            <p className="title is-6">Your credits: {credits}</p>
            <div>
              <label>
                How many credits do you wish to convert to pages?
                <input className="input jnf-credits-input" type="text" placeholder="Number of credits" defaultValue={convertCredits > 0 ? convertCredits : ""} onChange={handleCreditsTextChanged} />
                <ErrorDisplay errors={[validateCreditsError]} />
              </label>
            </div>
            <div>
              <label>
                Pages to be added:
                <input className="input jnf-pages-input" type="text" placeholder="" defaultValue={convertPages > 0 ? convertPages : ""} readOnly="readonly" />
              </label>
            </div>
            <button className="button jnf-primary-button jnf-full-width-button" type="button" onClick={handleAddPages}>Convert {convertCredits} Credits to {convertPages} Pages</button>
          </div>
        }
        <LoadingIndicator {...{ isLoading }} />
      </form>
    );
  }
}

class SubscriptionCancelOnOffForm extends Component {

  state = {
    isFormVisible: false,
    isVisibleConfirmCancelAlert: false,
    isLoading: false,
    error: null,
  };

  isSubscriptionSetToExpire = (faxNumber) => {
    return faxNumber && (faxNumber.cancel_at_period_end === true || faxNumber.cancel_at_period_end === "True");
  };

  handleToggleFormVisible = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      isFormVisible: !prevState.isFormVisible
    }));
  };

  handleCancelOrRenew = (event) => {
    event.preventDefault();

    const { faxNumber } = this.props;
    const isCancelOn = this.isSubscriptionSetToExpire(faxNumber);

    if(!isCancelOn) {
      // throw up alert to ask user for confirmation that they want to cancel
      this.setState({ isVisibleConfirmCancelAlert: true });
      return;
    }

    this.updateSubscriptionCancellationStatus(false);
  };

  updateSubscriptionCancellationStatus = (onOrOff) => {
    if(this.state.isLoading) {
      return;
    }

    this.setState({ error: null, isLoading: true });

    const { faxNumber } = this.props;
    const faxNumberID = faxNumber.urlsafe_key;

    const formData = new FormData();
    formData.append("cancel_at_period_end", onOrOff);

    console.log("form data:", formData);
    postData(ENDPOINTS.faxNumber + "/" + faxNumberID, formData, "PUT")
      .then((json) => {
        this.setState({ isLoading: false });
        this.props.fetchUser && this.props.fetchUser();
      })
      .catch((error) => {
        this.setState({ isLoading: false, error });
      });
  };

  handleHideAlert = (event) => {
    event.preventDefault();
    this.setState({ isVisibleConfirmCancelAlert: false });
  };

  handleCancelConfirmed = (event) => {
    event.preventDefault();
    this.setState({ isVisibleConfirmCancelAlert: false });
    this.updateSubscriptionCancellationStatus(true);
  };

  render() {

    const { faxNumber } = this.props;
    const subStatus = getSubscriptionStatus(faxNumber);
    const { subscription_source: subscriptionSource } = faxNumber || {};
    const isAppleSub = subscriptionSource === "1" || subscriptionSource === null || subscriptionSource === undefined;

    const { isFormVisible, error, isLoading, isVisibleConfirmCancelAlert } = this.state;
    const { handleToggleFormVisible, handleCancelOrRenew, handleHideAlert, handleCancelConfirmed } = this;

    const isCancelOn = this.isSubscriptionSetToExpire(faxNumber);

    const titleText = isAppleSub ? "View, Change, or Cancel Your Subscription in the Apple App Store" :
      (isCancelOn ? "Turn On Auto-renewal" : "Turn Off Auto-renewal");

    const formText = isAppleSub
      ?
        <p>
          Your subscription was purchased through Apple's App Store. We cannot manage the subscription for you, but
          here is Apple's documentation on how you can manage it yourself in the App Store or iTunes app:<br/>
          <a href="https://support.apple.com/en-us/HT202039">https://support.apple.com/en-us/HT202039</a>
        </p>
      :
        (isCancelOn ?
          <p>
            Turn auto-renewal back on to ensure that you keep your fax number and there is no interruption to your fax service.
            Your card on file will be charged at the end of the current billing period to buy the next billing period, and it will
            continue to be charged at the beginning of each billing period after that, until you cancel.
          </p>
        :
          <p>
            Cancelling will stop any further automatic charges on your card.
            You will continue to be able to use your number until the current subscription period expires.
          </p>);

    const buttonTitleText = isCancelOn ? "Turn On Auto-renewal" : "Cancel Subscription";

    return (
      <div className="SubscriptionCancelForm">
        <a href="/" onClick={handleToggleFormVisible}>
          <h4 className="title is-6">{titleText} &#x25BE;</h4>
        </a>
        { isFormVisible &&
          <div>
            <ErrorDisplay errors={[error]} type="bar"/>
            {formText}
            {!isAppleSub &&
              <button
                className="button jnf-primary-button jnf-full-width-button"
                type="button"
                disabled={subStatus!==SUBSCRIPTION_STATUS.active}
                onClick={handleCancelOrRenew}>{buttonTitleText}
              </button>
            }
            <LoadingIndicator {...{ isLoading }} />
          </div>
        }
        <Alert
          alertType='FaxNumberPage_ConfirmCancel'
          isVisible={isVisibleConfirmCancelAlert}
          handleConfirm={handleCancelConfirmed}
          handleCancel={handleHideAlert}
        />
      </div>
    );
  }
}


class SubscriptionRenewForm extends Component {

  state = {
    cartSubscription: null,

    subPeriodToPay: null,
    selectedPaymentMethodID: null,
    paymentIntentSecret: null,

    // UI state
    isPurchaseClicked: false,
    isVisibleConfirmPurchaseAlert: false,
    isPending: false,
    purchaseError: null,
    isVisiblePurchaseSuccessAlert: false,
  };

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({
      isVisibleConfirmPurchaseAlert: false,
      isPending: false,
      purchaseError: null,
      isVisiblePurchaseSuccessAlert: false,
    });
  };

  handleSubscriptionClicked = (event) => {
    event.preventDefault();
    if(!event.currentTarget.hasAttribute('data-subid')) {
      this.setState({ cartSubscription: null });
      return;
    }

    const cartSubscription = event.currentTarget.getAttribute('data-subid');
    this.setState({ cartSubscription });
  };

  paymentMethodSelected = (selectedPaymentMethodID) => {
    console.debug("payment method bubbled up:", selectedPaymentMethodID);
    this.setState({ selectedPaymentMethodID });
    this.props.updateUser &&
      this.props.updateUser({ defaultPaymentMethodID: selectedPaymentMethodID });
  };

  handlePurchaseClicked = (event) => {
    const { cartSubscription, selectedPaymentMethodID } = this.state;
    // proceed with purchase if cart and payment method are valid
    const isVisibleConfirmPurchaseAlert = cartSubscription && selectedPaymentMethodID;
    const isPending = isVisibleConfirmPurchaseAlert;
    this.setState({ isVisibleConfirmPurchaseAlert, isPending, isPurchaseClicked: true });
  };

  handleConfirmPurchase = (event) => {
    event.preventDefault();

    this.setState({ isPending: true, purchaseError: null, isVisibleConfirmPurchaseAlert: false });

    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {step: 4});
    ReactGA.event({
      category: 'Purchase',
      action: 'Clicked confirm purchase',
    });

    const { cartSubscription, selectedPaymentMethodID, subPeriodToPay } = this.state;

    if (!selectedPaymentMethodID) {
      const message = "Unable to validate credit card. Please try again.";
      this.setState({ isPending: false, purchaseError: new Error(message) });
      ReactGA.exception({ description: message });
      return;
    }

    if (LOCAL_MODE) {
      this.confirmPurchase();
      return;
    }

    const { faxNumber } = this.props;

    const endpoint = subPeriodToPay
      ? (ENDPOINTS.faxnumberSubscription + "/" + subPeriodToPay + "/pay-stripe-invoice")
      : ENDPOINTS.faxNumber + "/" + faxNumber.urlsafe_key;

    const formData = new FormData();
    formData.append("payment_method_id", selectedPaymentMethodID);
    if(!subPeriodToPay) {
      formData.append("product", cartSubscription);
    }

    postData(endpoint, formData, "PUT")
      .then((json) => {
        console.log(json);
        if (json && json.payment_status && json.payment_status === "succeeded") {
          this.confirmPurchase();
        } else if (json && json.payment_status && json.payment_status === "requires_payment_method") {
          this.setState({
            isPending: false,
            purchaseError: new Error(json.message),
            subPeriodToPay: json.subscription_period.urlsafe_key
          });
        } else if(json && json.payment_status && json.payment_status === "requires_action" && json.client_secret) {
          this.setState({ paymentIntentSecret: json.client_secret });
        } else if (json.message) {
          throw new Error(json.message);
        } else {
          throw new Error("There was a problem with this purchase. Please check your card information and try again.");
        }
      })
      .catch((error) => {
        this.setState({ isPending: false, purchaseError: error });
        ReactGA.exception({ description: error.message });
      });
  };

  handleAuthorizationResult = (result) => {
    if(result.error) {
      this.setState({
        isPending: false, paymentIntentSecret: null,
        purchaseError: result.error
      });
    } else {
      this.confirmPurchase();
    }
  };

  confirmPurchase = () => {
    this.props.fetchUser && this.props.fetchUser();
    this.setState({
      cartSubscription: null,
      paymentIntentSecret: null,
      isPending: false, isPurchaseClicked: false,
      isVisiblePurchaseSuccessAlert: true,
    });
    // TODO: log to GA
  };

  render() {

    const {
      cartSubscription, selectedPaymentMethodID,
      isVisibleConfirmPurchaseAlert, isVisiblePurchaseSuccessAlert,
      purchaseError, isPending, paymentIntentSecret, isPurchaseClicked,
    } = this.state;

    const {
      hideAlert, handleSubscriptionClicked, paymentMethodSelected,
      handlePurchaseClicked, handleAuthorizationResult, handleConfirmPurchase
    } = this;

    const cartError = (isPurchaseClicked && !cartSubscription) ? Error("Please choose a plan to purchase") : null;

    const confirmPurchaseAlertMessage = cartSubscription
      ? "I agree to purchase a " + SUBSCRIPTIONS[cartSubscription].name + ". JotNot Fax will charge my card $" + SUBSCRIPTIONS[cartSubscription].price.toFixed(2) + " now, and will automatically charge it once per month. Automatic charges will continue until I cancel the subscription. Cancelling will prevent any future automatic charges, but will not result in any refunds or prorated amounts."
      : null;

    const purchaseSuccessAlertMessage = 'Your subscription is active and ready to use.';

    return (
      <div className="SubscriptionCancelForm">
        <div>
          <p>
            Renew your subscription to send and receive faxes with your number.
          </p>
          <StoreSubscriptionPicker {...{cartSubscription, handleSubscriptionClicked}} />
          <StoreCheckoutForm {...{
            cartError, isPending, selectedPaymentMethodID, paymentIntentSecret,
            paymentMethodSelected, handlePurchaseClicked, handleAuthorizationResult
          }} />
        </div>
        <Alert
          alertType='StoreCheckoutForm_ConfirmPurchase'
          isVisible={isVisibleConfirmPurchaseAlert}
          handleConfirm={handleConfirmPurchase}
          handleCancel={hideAlert}
          messageDetail={confirmPurchaseAlertMessage}
        />
        <Alert
          alertType='StoreCheckoutForm_PurchaseError'
          isVisible={purchaseError ? true : false}
          handleConfirm={hideAlert}
          messageDetail={purchaseError ? purchaseError.message : ''}
        />
        <Alert
          alertType='StoreCheckoutForm_PurchaseSuccess'
          isVisible={isVisiblePurchaseSuccessAlert}
          handleConfirm={hideAlert}
          messageDetail={purchaseSuccessAlertMessage}
        />
      </div>
    );
  }
}


export default FaxNumberPage;
