import React, { Component } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import { gaEvent } from '../utils/GoogleAnalytics';

import Alert from './Alert';
import ErrorDisplay from './ErrorDisplay';
import FaxNumberList, { RESERVATION_STATUS } from './FaxNumberList';
import StoreCheckoutForm from './StoreCheckoutForm';
import StoreShoppingCart, { StoreShoppingCartCreditPack, StoreShoppingCartSubscription } from './StoreShoppingCart';
import StoreSubscriptionPicker from './StoreSubscriptionPicker';
import StoreNumberPicker from './StoreNumberPicker';
import StoreAreaCodePicker from './StoreAreaCodePicker';

import { PAGES, LOCAL_MODE } from '../utils/constants';
import { postData, ENDPOINTS } from '../utils/fetching.js';
import { pluralize } from '../utils/misc';
import { CREDIT_PACKS, getTotalPrice, getTotalCredits } from '../utils/creditPacks';
import SUBSCRIPTIONS from '../utils/subscriptions';


class Store extends Component {

  state = {
    isVisibleFaxPricingInfoAlert: false,
  };

  hideAlerts = (event) => {
    event.preventDefault();
    this.setState({ isVisibleFaxPricingInfoAlert: false });
  };

  handleAlertShowFaxPricingInfo = (event) => {
    event.preventDefault();
    this.setState({ isVisibleFaxPricingInfoAlert: true });
  };

  componentDidMount() {
    this.props.fetchUser && this.props.fetchUser();
  }

  render() {
    const { creditsError, faxNumbersError } = this.props;
    const { isVisibleFaxPricingInfoAlert } = this.state;
    const { hideAlerts, handleAlertShowFaxPricingInfo } = this;

    return (
      <div className="Store">
        <div>

          <ErrorDisplay errors={[creditsError, faxNumbersError]} type="bar" />

          <section className="section jnf-first">
            <div className="container">

              <h1>Store</h1>

              <Switch>
                <Route exact path={PAGES.storeSubscriptions} render={(props) => (
                  <div>
                    <StoreMenu page={PAGES.storeSubscriptions} />
                    <StoreSubscriptionPage {...this.props} {...{handleAlertShowFaxPricingInfo}} />
                    <Alert
                      alertType='Store_ShowSubscriptionInfo'
                      isVisible={isVisibleFaxPricingInfoAlert}
                      handleConfirm={hideAlerts}
                    />
                  </div>
                )} />
                <Route render={(props) => (
                  <div>
                    <StoreMenu page={PAGES.storeCredits} />
                    <StoreCreditPage {...this.props} {...{handleAlertShowFaxPricingInfo}} />
                    <Alert
                      alertType='Store_ShowFaxPricingInfo'
                      isVisible={isVisibleFaxPricingInfoAlert}
                      handleConfirm={hideAlerts}
                    />
                  </div>
                )} /> {/*Everything else renders the credits page*/}
              </Switch>

            </div>
          </section>
        </div>
      </div>
    );
  }
}


function StoreMenu({ page }) {
  const creditsLiClassName = page===PAGES.storeCredits ? "is-active" : "";
  const subsLiClassName = page===PAGES.storeSubscriptions ? "is-active" : "";

  return (
    <div className="tabs is-centered is-toggle is-medium jnf-store-menu">
      <ul className="jnf-store-menu-list">
        <li className={creditsLiClassName}><NavLink to={PAGES.storeCredits}>Credits</NavLink></li>
        <li className={subsLiClassName}><NavLink to={PAGES.storeSubscriptions}>Subscription Plans</NavLink></li>
      </ul>
    </div>
  );
}


const _INITIAL_CREDIT_PAGE_STATE = Object.freeze({
  cartPacks: {},
  promoCode: null,
  promoAmount: null, // in dollars
  promoRate: null,
  promoText: null,
  promoMonths: null,
  promoYears: null,
  selectedPaymentMethodID: null,
  paymentIntentSecret: null,
  isVisibleConfirmPurchaseAlert: false,
  isVisiblePurchaseSuccessAlert: false,
  isPending: false,
  purchaseError: null,
  isPurchaseClicked: false,
});

class StoreCreditPage extends Component {

  state = _INITIAL_CREDIT_PAGE_STATE;

  componentDidMount() {
    this.setState({ selectedPaymentMethodID: this.props.defaultPaymentMethodID });
    ReactGA.plugin.require('ec');
    ReactGA.plugin.execute('ec', 'addImpression', {
      id: '/store/credits',
      name: '/store/credits',
      category: 'Fax Credit Pack',
    });
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: '/store/credits',
      name: '/store/credits',
      category: 'Fax Credit Pack',
    });
    ReactGA.plugin.execute('ec', 'setAction', 'detail');
    ReactGA.event({ category: 'Page Load', action: 'Store credits page loaded' });
  }

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({
      isVisibleConfirmPurchaseAlert: false,
      isVisiblePurchaseSuccessAlert: false,
      isPending: false,
      purchaseError: null,
    });
  };

  handleIncrementPack = (event) => {
    event.preventDefault();
    const packid = event.currentTarget.getAttribute('data-packid');
    this.setState((prevState) => {
      const { cartPacks } = prevState;
      const newCart = Object.assign({}, cartPacks);
      if (packid in newCart) {
        newCart[packid] = newCart[packid] + 1;
      } else {
        newCart[packid] = 1;
      }
      return { cartPacks: newCart, cartSubscription: null, cartNumber: null };
    }, () => {
      console.log("packid", packid);
      console.log("new cart", this.state.cartPacks);
      console.log("EC ADD PRODUCT TO CART:");
      ReactGA.plugin.require('ec');
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: packid,
        name: CREDIT_PACKS[packid].name,
        category: 'Fax Credit Pack',
        price: CREDIT_PACKS[packid].price,
        quantity: 1
      });
      ReactGA.plugin.execute('ec', 'setAction', 'add');
      ReactGA.event({ category: 'Cart', action: 'Add to cart', label: CREDIT_PACKS[packid].name });
    });
  };

  handleDecrementPack = (event) => {
    event.preventDefault();
    const packid = event.currentTarget.getAttribute('data-packid');
    this.setState((prevState) => {
      const { cartPacks } = prevState;
      const newCart = Object.assign({}, cartPacks);
      if (packid in newCart) {
        newCart[packid] -= 1;
        if (newCart[packid] <= 0) {
          delete newCart[packid];
        }
      }
      return { cartPacks: newCart };
    }, () => {
      console.log("EC REMOVE PRODUCT FROM CART:");
      ReactGA.plugin.require('ec');
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: packid,
        name: CREDIT_PACKS[packid].name,
        category: 'Fax Credit Pack',
        price: CREDIT_PACKS[packid].price,
        quantity: 1
      });
      ReactGA.plugin.execute('ec', 'setAction', 'remove');
      ReactGA.event({ category: 'Cart', action: 'Remove from cart', label: CREDIT_PACKS[packid].name });
    });
  };

  handleApplyPromoCompleted = (promoInfo) => {
    this.setState(promoInfo);
  };

  paymentMethodSelected = (selectedPaymentMethodID) => {
    this.setState({ selectedPaymentMethodID });
    this.props.updateUser &&
      this.props.updateUser({ defaultPaymentMethodID: selectedPaymentMethodID });
  };

  handlePurchaseClicked = (event) => {
    const { cartPacks, selectedPaymentMethodID } = this.state;
    // proceed with purchase if cart and payment method are valid
    const creditsInCart = getTotalCredits(cartPacks);
    const isVisibleConfirmPurchaseAlert = creditsInCart > 0 && selectedPaymentMethodID;
    const isPending = isVisibleConfirmPurchaseAlert;
    this.setState({ isVisibleConfirmPurchaseAlert, isPending, isPurchaseClicked: true });
  };

  handleConfirmPurchase = (event) => {
    event.preventDefault();

    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {step: 4});
    ReactGA.event({
      category: 'Purchase',
      action: 'Clicked confirm purchase', // const action = shouldPurchase ? 'Clicked confirm purchase' : 'Clicked cancel purchase';
    });

    this.setState({ isPending: true, purchaseError: null, isVisibleConfirmPurchaseAlert: false });

    const {
      cartPacks, promoCode,
      selectedPaymentMethodID, webPurchaseToPay
    } = this.state;

    if (!selectedPaymentMethodID) {
      const message = "Unable to validate credit card. Please try again.";
      this.setState({ isPending: false, purchaseError: new Error(message) });
      ReactGA.exception({ description: message });
      return;
    }

    if (LOCAL_MODE) {
      this.confirmPurchase(this.props.credits + getTotalCredits(cartPacks));
      return;
    }

    const endpoint = ENDPOINTS.purchase + (webPurchaseToPay ? "/" + webPurchaseToPay : "");
    const method = webPurchaseToPay ? "PUT" : "POST";

    const formData = new FormData();
    formData.append("payment_method_id", selectedPaymentMethodID);
    formData.append("cart", JSON.stringify(cartPacks));
    formData.append("quotedPrice", this.getQuotedPrice(true));
    if (promoCode) {
      formData.append("promoCode", promoCode);
    }
    postData(endpoint, formData, method)
      .then((json) => {
        console.log(json);
        if (json && json.purchase && json.purchase.valid) {
          const { credits=(this.props.credits + getTotalCredits(cartPacks)) } = json;
          this.confirmPurchase(credits);
        } else if (json && json.purchase && (
            json.purchase.payment_intent_status === "requires_payment_method" ||
            json.purchase.payment_intent_status === "requires_confirmation")) {
          this.setState({
            isPending: false,
            purchaseError: new Error(json.message),
            webPurchaseToPay: json.purchase.urlsafe_key,
          });
        } else if(json && json.purchase && json.purchase.payment_intent_status === "requires_action" && json.client_secret) {
          this.setState({ paymentIntentSecret: json.client_secret });
        } else if(json && json.purchase && json.purchase.payment_intent_status === "canceled") {
          this.setState({ webPurchaseToPay: null });
          throw new Error("Payment was canceled. Please try again.");
        } else if(json && json.purchase && json.purchase.payment_intent_status === "processing") {
          this.setState({ purchaseError: null });
        } else if (json && json.error_description) {
          throw new Error(json.error_description);
        } else {
          throw new Error("There was a problem with this purchase. Please check your card information and try again.");
        }
      })
      .catch((error) => {
        this.setState({ isPending: false, purchaseError: error });
        ReactGA.exception({ description: error.message });
      });
  };

  handleAuthorizationResult = (result) => {
    if(result.error) {
      this.setState({
        isPending: false, paymentIntentSecret: null,
        purchaseError: result.error
      });
    } else {
      this.confirmPurchase();
    }
  };

  confirmPurchase = (localCredits) => {
    const { cartPacks, promoCode, promoAmount, promoRate } = this.state;
    this.setState({
      cartPacks: {}, webPurchaseToPay: null, paymentIntentSecret: null,
      promoCode: null, promoAmount: null, promoRate: null, promoText: null,
      isPending: false, isVisiblePurchaseSuccessAlert: true, isPurchaseClicked: false,
    });
    this.props.fetchUser && this.props.fetchUser(localCredits);
    gaEvent.purchase(cartPacks, promoCode, promoCode?promoAmount:0, promoCode?promoRate:0);
  };

  getPromoPrice = () => {
    const { cartPacks, promoAmount, promoRate } = this.state;
    let promoPrice = promoAmount ? Math.max(0, promoAmount) : 0;
    if(promoRate && promoPrice <= 0) {
      const cartPrice = getTotalPrice(cartPacks);
      promoPrice = Math.max(0, promoRate * cartPrice);
    }
    return promoPrice;
  };

  getQuotedPrice = (doPriceInCents) => {
    const { cartPacks } = this.state;
    const cartPrice = getTotalPrice(cartPacks);
    const promoPrice = this.getPromoPrice();
    const quotedPrice = Math.max(0, cartPrice-promoPrice);
    if(doPriceInCents) {
      return Math.floor(quotedPrice * 100 + 0.5);
    }
    return quotedPrice;
  };

  render() {

    const { credits, handleAlertShowFaxPricingInfo } = this.props;

    const {
      cartPacks, promoCode, promoText,
      isVisibleConfirmPurchaseAlert, isVisiblePurchaseSuccessAlert,
      purchaseError, isPending, isPurchaseClicked,
      selectedPaymentMethodID, paymentIntentSecret,
    } = this.state;

    const {
      handleIncrementPack, handleDecrementPack,
      handleApplyPromoCompleted,
      paymentMethodSelected, handlePurchaseClicked,
      handleConfirmPurchase, handleAuthorizationResult,
      getPromoPrice, getQuotedPrice, hideAlert,
    } = this;

    const cartPriceText = "$" + getTotalPrice(cartPacks).toFixed(2);
    const promoPriceText = "$" + getPromoPrice().toFixed(2);
    const quotedPriceText = "$" + getQuotedPrice().toFixed(2);

    const totalText = "Total";
    const totalPriceText = quotedPriceText;

    const cartCredits = getTotalCredits(cartPacks);
    const confirmPurchaseAlertMessage = 'You are purchasing ' + cartCredits + ' credits. Your credit card will be charged $' + quotedPriceText + '.';

    const cartError = (isPurchaseClicked && cartCredits <= 0) ? Error("Please choose a credit pack (or packs) to purchase.") : null;

    const cartList = Object.entries(cartPacks).map(([packid, count]) =>
      <StoreShoppingCartCreditPack key={packid} {...{packid, count, handleIncrementPack, handleDecrementPack}} />
    );

    const cartSummaryText = (cartCredits > 0) ? pluralize(cartCredits, "Credit") : "";

    const creditsText = pluralize(credits, "credit");
    const creditsAfterPurchase = credits + cartCredits;
    const creditsAfterPurchaseText = pluralize(creditsAfterPurchase, "credit");
    const footerPar = cartCredits
      ? <p className="">You currently have {creditsText}. After this purchase, you will have {creditsAfterPurchaseText}.</p>
      : null;

    return (
      <div className="jnf-store-credit-page">
        <section className="section">
          <div className="container">
            <h2>Your Credits: {credits}</h2>
          </div>
        </section>

        <section className="section jnf-credit-packs">
          <div className="container">
            <h2>Credit Packs</h2>
            <h4 className="subtitle is-6">
              <p>For sending only, pay as you go
                <a href="/" onClick={handleAlertShowFaxPricingInfo}>
                  <i className="jnf-icon-info is-primary"></i>
                </a>
              </p>
              <p className="jnf-credits-subtitle-about-receiving">Please note: JotNot Fax requires a subscription for receiving. See "Subscription Plans" above.</p>
            </h4>
            <ul className="jnf-clickable-list">
              {Object.entries(CREDIT_PACKS).map(([packid, pack]) =>
                <StoreCreditPack key={packid} {...{packid, pack}} handleClick={handleIncrementPack}/>
              )}
            </ul>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns is-variable is-1-tablet is-3-desktop">

              <div className="column is-6">
                <StoreShoppingCart showQuantity={true} {...{
                  cartList, cartSummaryText, footerPar,
                  promoCode, promoText,
                  cartPriceText, promoPriceText, totalText, totalPriceText,
                  handleApplyPromoCompleted,
                }} />
              </div>

              <div className="column">
                <StoreCheckoutForm {...{
                  selectedPaymentMethodID, isPending, cartError, paymentIntentSecret,
                  handlePurchaseClicked, paymentMethodSelected, handleAuthorizationResult
                }} />
              </div>

            </div>
          </div>
        </section>

        <Alert
          alertType='StoreCheckoutForm_ConfirmPurchase'
          isVisible={isVisibleConfirmPurchaseAlert}
          handleConfirm={handleConfirmPurchase}
          handleCancel={hideAlert}
          messageDetail={confirmPurchaseAlertMessage}
        />
        <Alert
          alertType='StoreCheckoutForm_PurchaseError'
          isVisible={purchaseError ? true : false}
          handleConfirm={hideAlert}
          messageDetail={purchaseError ? purchaseError.message : ''}
        />
        <Alert
          alertType='StoreCheckoutForm_PurchaseSuccess'
          isVisible={isVisiblePurchaseSuccessAlert}
          handleConfirm={hideAlert}
        />

      </div>
    );
  }
}


function StoreCreditPack({ packid, pack, handleClick }) {
  return (
    <li className="jnf-clickable-list-item jnf-pack">
      <a href="/" data-packid={packid} onClick={handleClick}>
        <div className="level is-mobile jnf-pack-container">
          <div className="level-left jnf-pack-description">
            <div>
              <p className="jnf-pack-credits">{pack.credits} Fax Credits</p>
              <p className="jnf-pack-per-credit">${pack.perCredit.toFixed(2)} per credit</p>
              {pack.message && <p className="jnf-pack-message"><i className="icon jnf-icon-discount"></i>{pack.message}</p>}
            </div>
          </div>
          <div className="level-right">
            <span className="button jnf-pack-price">${pack.price.toFixed(2)}</span>
          </div>
        </div>
      </a>
    </li>
  );
}


class StoreSubscriptionPage extends Component {

  state = {
    // purchase data
    cartSubscription: null,
    cartNumber: null,
    areaCode: null,

    // promo code data
    promoCode: null,
    promoAmount: null, // in dollars
    promoRate: null,
    promoText: null,
    promoMonths: null,
    promoYears: null,

    subPeriodToPay: null,
    selectedPaymentMethodID: null,
    paymentIntentSecret: null,

    // UI state
    isPurchaseClicked: false,
    isVisibleConfirmPurchaseAlert: false,
    isPending: false,
    purchaseError: null,
    isVisiblePurchaseSuccessAlert: false,
  };

  componentDidMount() {
    this.setState({ selectedPaymentMethodID: this.props.defaultPaymentMethodID });
    ReactGA.plugin.require('ec');
    ReactGA.plugin.execute('ec', 'addImpression', {
      id: '/store/subscriptions',
      name: '/store/subscriptions',
      category: 'Fax Subscription',
    });
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: '/store/subscriptions',
      name: '/store/subscriptions',
      category: 'Fax Subscription',
    });
    ReactGA.plugin.execute('ec', 'setAction', 'detail');
    ReactGA.event({ category: 'Page Load', action: 'Store subscriptions page loaded' });
  }

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({
      isVisibleConfirmPurchaseAlert: false,
      isPending: false,
      purchaseError: null,
      isVisiblePurchaseSuccessAlert: false,
    });
  };

  handleSubscriptionClicked = (event) => {
    event.preventDefault();
    if(!event.currentTarget.hasAttribute('data-subid')) {
      this.setState({ cartSubscription: null }, () => {
        console.log("EC REMOVE PRODUCT FROM CART:");
        ReactGA.plugin.require('ec');
        ReactGA.plugin.execute('ec', 'addProduct', {
          id: cartSubscription,
          name: name,
          category: 'Fax Subscription',
          price: price,
          quantity: 1
        });
        ReactGA.plugin.execute('ec', 'setAction', 'remove');
        ReactGA.event({ category: 'Cart', action: 'Remove from cart', label: name + ' ' + duration });
      });
      return;
    }

    const cartSubscription = event.currentTarget.getAttribute('data-subid');
    const { name, price, duration } = SUBSCRIPTIONS[cartSubscription];
    this.setState({ cartSubscription }, () => {
      console.log("EC ADD PRODUCT TO CART:");
      ReactGA.plugin.require('ec');
      ReactGA.plugin.execute('ec', 'addProduct', {
        id: cartSubscription,
        name: name,
        category: 'Fax Subscription',
        price: price,
        quantity: 1
      });
      ReactGA.plugin.execute('ec', 'setAction', 'add');
      ReactGA.event({ category: 'Cart', action: 'Add to cart', label: name + ' ' + duration });
    });
  };

  handleAreaCodeClicked = (event) => {
    event.preventDefault();
    let areaCode = null;
    if(!event.currentTarget.hasAttribute('data-area-code')) {
      this.setState({ areaCode: null });
      return;
    }

    try {
      areaCode = JSON.parse(event.currentTarget.getAttribute('data-area-code'));
    } catch(err) {
      console.error(err);
    }
    if(areaCode) {
      this.setState({ areaCode });
    }
  };

  handleNumberClicked = (event) => {
    event.preventDefault();
    const cartNumber = event.currentTarget.getAttribute('data-fax-number');
    this.setState({ cartNumber });
  };

  paymentMethodSelected = (selectedPaymentMethodID) => {
    this.setState({ selectedPaymentMethodID });
    this.props.updateUser &&
      this.props.updateUser({ defaultPaymentMethodID: selectedPaymentMethodID });
  };

  getCartPrice = () => {
    const { cartSubscription } = this.state;
    const { price } = SUBSCRIPTIONS[cartSubscription] || { price: 0};
    return price;
  };

  getPromoPrice = () => {
    const { promoRate } = this.state;
    const price = this.getCartPrice();
    return price * promoRate;
  };

  getQuotedPrice = (doPriceInCents) => {
    const cartPrice = this.getCartPrice();
    const promoPrice = this.getPromoPrice();
    const quotedPrice = Math.max(0, cartPrice-promoPrice);
    if(doPriceInCents) {
      return Math.floor(quotedPrice * 100 + 0.5);
    }
    return quotedPrice;
  };

  handleApplyPromoCompleted = (promoInfo) => {
    this.setState(promoInfo);
  };

  handlePurchaseClicked = (event) => {
    const { cartSubscription, cartNumber, selectedPaymentMethodID } = this.state;
    // proceed with purchase if cart and payment method are valid
    const isVisibleConfirmPurchaseAlert = cartSubscription && cartNumber && selectedPaymentMethodID;
    const isPending = isVisibleConfirmPurchaseAlert;
    this.setState({ isVisibleConfirmPurchaseAlert, isPending, isPurchaseClicked: true });
  };

  handleConfirmPurchase = (event) => {
    event.preventDefault();

    this.setState({ isPending: true, purchaseError: null, isVisibleConfirmPurchaseAlert: false });

    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {step: 4});
    ReactGA.event({
      category: 'Purchase',
      action: 'Clicked confirm purchase',
    });

    const { cartSubscription, cartNumber, promoCode, selectedPaymentMethodID, subPeriodToPay } = this.state;

    if (!selectedPaymentMethodID) {
      const message = "Unable to validate credit card. Please try again.";
      this.setState({ isPending: false, purchaseError: new Error(message) });
      ReactGA.exception({ description: message });
      return;
    }

    if (LOCAL_MODE) {
      this.confirmPurchase();
      return;
    }

    const endpoint = subPeriodToPay
      ? (ENDPOINTS.faxnumberSubscription + "/" + subPeriodToPay + "/pay-stripe-invoice")
      : ENDPOINTS.faxNumber;

    const formData = new FormData();
    formData.append("payment_method_id", selectedPaymentMethodID);
    if(!subPeriodToPay) {
      formData.append("product", cartSubscription);
      formData.append("number_str", cartNumber);
      if (promoCode) {
        formData.append("promo_code", promoCode);
      }
    }

    const method = subPeriodToPay ? "PUT" : "POST";

    postData(endpoint, formData, method)
      .then((json) => {
        console.log(json);
        if (json && json.payment_status && json.payment_status === "succeeded") {
          this.confirmPurchase();
        } else if (json && json.payment_status && json.payment_status === "requires_payment_method") {
          this.setState({
            isPending: false,
            purchaseError: new Error(json.message),
            subPeriodToPay: json.subscription_period.urlsafe_key
          });
        } else if(json && json.payment_status && json.payment_status === "requires_action" && json.client_secret) {
          this.setState({ paymentIntentSecret: json.client_secret });
        } else if (json.message) {
          throw new Error(json.message);
        } else {
          throw new Error("There was a problem with this purchase. Please check your card information and try again.");
        }
      })
      .catch((error) => {
        this.setState({ isPending: false, purchaseError: error });
        ReactGA.exception({ description: error.message });
      });
  };

  handleAuthorizationResult = (result) => {
    if(result.error) {
      this.setState({
        isPending: false, paymentIntentSecret: null,
        purchaseError: result.error
      });
    } else {
      this.confirmPurchase();
    }
  };

  confirmPurchase = () => {
    // TODO: for requires_action, update server with payment intent info (?)

    const { cartSubscription, cartNumber, promoCode, promoAmount, promoRate } = this.state;
    const { pages, duration } = SUBSCRIPTIONS[cartSubscription];
    const now = Date.now();
    const period_end = now + 30 * 24 * 60 * 60 * 1000;
    const expiration_date = (duration === "month") ? period_end : now + 365 * 24 * 60 * 60 * 1000;

    const newFaxNumber = {
      reservation_status: RESERVATION_STATUS.requested,
      expiration_date: expiration_date,
      fax_number: cartNumber,
      usage_period: {
        product: cartSubscription,
        pages: pages,
        period_end: period_end,
      },
    };

    gaEvent.subscribe(cartSubscription, promoCode, promoCode?promoAmount:0, promoCode?promoRate:0);

    this.setState({
      cartSubscription: null, cartNumber: null, areaCode: null,
      promoCode: null, promoAmount: null, promoRate: null, promoText: null, promoMonths: null, promoYears: null,
      paymentIntentSecret: null,
      isPending: false, isPurchaseClicked: false,
      newFaxNumber: newFaxNumber,
      isVisiblePurchaseSuccessAlert: true,
    });

    this.numberPollingTimer = setInterval(this.handleNumberPoll, 1000);
  };

  numberPollingTimer = null;

  setNewFaxNumberAndClearTimerIfNecessary = (newFaxNumber, shouldClear) => {
    this.setState({ newFaxNumber });

    if(shouldClear && this.numberPollingTimer) {
      clearInterval(this.numberPollingTimer);
      this.numberPollingTimer = null;
    }
  };

  handleNumberPoll = () => {
    const { newFaxNumber } = this.state;
    const { setNewFaxNumberAndClearTimerIfNecessary } = this;
    if(!newFaxNumber) {
      setNewFaxNumberAndClearTimerIfNecessary(null, true);
      return;
    }

    const { fetchUser } = this.props;
    fetchUser && fetchUser()
      .then((user) => {
        if(!newFaxNumber) {
          setNewFaxNumberAndClearTimerIfNecessary(null, true);
        }
        const { faxNumbers } = user;
        if(!faxNumbers) {
          return;
        }
        const foundFaxNumber = faxNumbers.find((num) => {
          return num.fax_number === newFaxNumber.fax_number;
        });
        if(!foundFaxNumber) {
          return;
        }
        const stopPolling = foundFaxNumber.reservation_status !== RESERVATION_STATUS.requested;
        setNewFaxNumberAndClearTimerIfNecessary(foundFaxNumber, stopPolling);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  confirmPurchaseAlertMessage = () => {
    const { cartSubscription } = this.state;
    if(!cartSubscription) { return null; }
    const { name, duration } = SUBSCRIPTIONS[cartSubscription] || {name: "", duration: ""};
    return "I agree to purchase a " + name + ". JotNot Fax will charge my card $" + this.getQuotedPrice().toFixed(2) + " now, and will automatically charge it once per " + duration + " until I cancel the subscription. Cancelling will prevent any future automatic charges, but will not result in any refunds or prorated amounts.";
  };

  render() {

    const { faxNumbers, handleAlertShowFaxPricingInfo } = this.props;
    const {
      cartSubscription, cartNumber, areaCode, selectedPaymentMethodID, promoCode, promoText, promoYears, promoMonths,
      paymentIntentSecret, isPending, isPurchaseClicked, purchaseError,
      isVisibleConfirmPurchaseAlert, isVisiblePurchaseSuccessAlert,
      newFaxNumber,
    } = this.state;

    const {
      handleSubscriptionClicked, handleAreaCodeClicked, handleNumberClicked, paymentMethodSelected,
      handleApplyPromoCompleted, handlePurchaseClicked, handleConfirmPurchase, handleAuthorizationResult, hideAlert,
      getPromoPrice, getQuotedPrice, getCartPrice,
    } = this;

    console.log("rendering subscription page", faxNumbers, cartSubscription, cartNumber, areaCode);

    const { duration } = SUBSCRIPTIONS[cartSubscription] || { duration: "" };
    const confirmPurchaseAlertMessage = this.confirmPurchaseAlertMessage();
    const purchaseSuccessAlertMessage = 'Your subscription is active. We are reserving your fax number with the phone company now.';

    const cartError = (isPurchaseClicked && (!cartSubscription || !cartNumber)) ? Error("Please choose a plan and fax number to purchase") : null;

    let newFaxNumberToShow = newFaxNumber;
    if(newFaxNumber && faxNumbers && faxNumbers.find((num) => {
      return num.fax_number === newFaxNumber.fax_number;
    })) {
      newFaxNumberToShow = null;
    }

    const durationText = cartSubscription ? (duration === "year" ? " / yr" : " / mo") : "";
    const cartPriceText = "$" + getCartPrice().toFixed(2) + durationText;
    const promoPriceText = "$" + getPromoPrice().toFixed(2) + durationText;
    const quotedPriceText = "$" + getQuotedPrice().toFixed(2) + durationText;

    const promoDuration = duration === "year" ? "year" : "month";
    const promoPeriods = promoDuration === "year" ? promoYears : promoMonths;
    const promoPeriodText = " for first " + pluralize(promoPeriods, promoDuration);
    const promoTextExtended = promoText
      ? <p>{promoText}<br/>{promoPeriodText}</p>
      : null;

    const totalText = <p>Total<br/>&nbsp;&nbsp;{promoPeriodText}:<br/>&nbsp;&nbsp; thereafter:</p>;
    const totalPriceText = <p><br/>{quotedPriceText}<br/>{cartPriceText}</p>;

    const cartList = cartSubscription
    ? (
      <StoreShoppingCartSubscription subid={cartSubscription} faxNumber={cartNumber} handleRemove={handleSubscriptionClicked} />
    )
    : null;
    const cartSummaryText = "";
    const footerPar = null;

    return (
      <div className="jnf-store-subscription-page">
        <FaxNumberList {...{faxNumbers}}/>
        { newFaxNumberToShow &&
          <FaxNumberList faxNumbers={[newFaxNumberToShow]} />
        }
        <section className="section jnf-subscriptions">
          <div className="container">
            <h2>Get a New Fax Number</h2>
            <h4 className="subtitle is-6">
              Send and receive faxes from your own fax number.
              <a href="/" onClick={handleAlertShowFaxPricingInfo}>
                <i className="jnf-icon-info is-primary"></i>
              </a>
            </h4>

            <StoreSubscriptionPicker {...{cartSubscription, handleSubscriptionClicked}} />

            <StoreAreaCodePicker {...{areaCode, handleAreaCodeClicked}} />

            <StoreNumberPicker {...{areaCode, cartNumber, handleNumberClicked}} />

            <div className="columns">
              <div className="column is-6">
                <StoreShoppingCart
                  requireSubscriptionPromo={true} promoText={promoTextExtended} {...{
                  cartList, cartSummaryText, footerPar,
                  promoCode,
                  cartPriceText, promoPriceText, totalText, totalPriceText,
                  handleApplyPromoCompleted,
                }} />
              </div>

              <div className="column">
                <StoreCheckoutForm {...{
                  selectedPaymentMethodID, isPending, cartError, paymentIntentSecret,
                  handlePurchaseClicked, paymentMethodSelected, handleAuthorizationResult
                }} />
              </div>
            </div>
          </div>
        </section>

        <Alert
          alertType='StoreCheckoutForm_ConfirmPurchase'
          isVisible={isVisibleConfirmPurchaseAlert}
          handleConfirm={handleConfirmPurchase}
          handleCancel={hideAlert}
          messageDetail={confirmPurchaseAlertMessage}
        />
        <Alert
          alertType='StoreCheckoutForm_PurchaseError'
          isVisible={purchaseError ? true : false}
          handleConfirm={hideAlert}
          messageDetail={purchaseError ? purchaseError.message : ''}
        />
        <Alert
          alertType='StoreCheckoutForm_PurchaseSuccess'
          isVisible={isVisiblePurchaseSuccessAlert}
          handleConfirm={hideAlert}
          messageDetail={purchaseSuccessAlertMessage}
        />

      </div>
    );
  }
}


export default Store;
