import React, { Component } from 'react';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment-timezone';
import { Link, } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isBrowser, isMobile, isAndroid } from 'react-device-detect';

import ModalCoverPage from './ModalCoverPage';
import ModalDocument from './ModalDocument';
import ModalFaxReceipt from './ModalFaxReceipt';
import Alert from './Alert';

import { ENDPOINTS, postData } from '../utils/fetching';
import { iconForRegion } from '../utils/regionCodes';
import { PAGES, LOCAL_MODE } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';
import { pluralize } from '../utils/misc';

const localTZ = moment.tz.guess();
const phoneUtil = PhoneNumberUtil.getInstance();


class HomeStatusItem extends Component {
  state = {
    visibleModalDocInfo: null,
    isVisibleModalCoverPage: false,
    isVisibleModalReceipt: false,
    isVisibleAlertConfirmDeleteFax: false,
    isVisibleAlertFaxErrorInfo: false,
    deleteError: null,
    isDeleting: false,
  };

  hideModal = (event) => {
    event.preventDefault();
    this.setState({
      visibleModalDocInfo: null,
      isVisibleModalCoverPage: false,
      isVisibleModalReceipt: false,
      isVisibleAlertConfirmDeleteFax: false,
      isVisibleAlertFaxErrorInfo: false,
      deleteError: null,
    });
  };

  handleModalCoverPage = (event) => {
    event.preventDefault();
    ReactGA.modalview('/home/cover');
    this.setState({ isVisibleModalCoverPage: true });
  };

  handleModalDocument = (event) => {
    event.preventDefault();
    ReactGA.modalview('/home/document');
    const visibleModalDocInfo =
      event.target.dataset && event.target.dataset.docindex
      ? this.props.fax.file_info[Number(event.target.dataset.docindex)].file
      : null;
    this.setState({ visibleModalDocInfo });
  };

  handleModalReceipt = (event) => {
    event.preventDefault();
    ReactGA.modalview('/home/receipt');
    this.setState({ isVisibleModalReceipt: true });
  };

  handleAlertConfirmDeleteFax = (event) => {
    event.preventDefault();
    this.setState({ isVisibleAlertConfirmDeleteFax: true });
  };

  handleAlertFaxErrorInfo = (event) => {
    event.preventDefault();
    this.setState({ isVisibleAlertFaxErrorInfo: true });
  };

  deleteFax = (event) => {
    event.preventDefault();
    this.setState({ isVisibleAlertConfirmDeleteFax: false });
    const { fax, deleteFaxCallback } = this.props;

    if (LOCAL_MODE) {
      deleteFaxCallback(fax.fax_key);
      return;
    }

    const formData = new FormData();
    // formData.append("fax_key", fax.fax_key);
    this.setState({ isDeleting: true });
    postData(ENDPOINTS.deleteSentFax+fax.fax_key, formData, 'DELETE')
      .then((json) => {
        this.setState({ isDeleting: false });
        deleteFaxCallback(fax.fax_key);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isDeleting: false });
        this.setState({ deleteError: error });
      });
  };

  resendFaxData = () => {
    const { fax } = this.props;

    const faxData = {
      recipientFax: fax.recipient_fax,
      recipientRegionCode: fax.recipient_region_code,
      recipientCountryCode: fax.recipient_country_code,
      resendFaxKey: fax.fax_key,
      resendFiles: fax.file_info, //[{ file: { name: fax.filename, preview: fax.file_url }, pages: Number(fax.pages) }], // Server has pages as string; client uses numbers
      pageCount: Number(fax.pages), // Server has pages as string; client uses numbers
      coverSheet: typeof (fax.cover_sheet) === 'string' ? fax.cover_sheet === 'True' : fax.cover_sheet, // Server has cover_sheet as string 'True' or 'False'; client uses booleans
      coverPage: {
        recipientName: fax.recipient_name || '',
        subject: fax.subject || '',
        message: fax.message || '',
        senderName: fax.sender_name || '',
        senderOrganization: fax.sender_organization || '',
        senderAddress: fax.sender_address || '',
        senderPhone: fax.sender_phone || '',
        senderFax: fax.sender_fax || '',
        senderEmail: fax.sender_email || '',
        senderWebsite: fax.sender_url || '',
      }
    };
    // console.log("faxData", faxData);
    return faxData;
  };

  render() {
    const {
      visibleModalDocInfo,
      isVisibleModalCoverPage,
      isVisibleModalReceipt,
      isVisibleAlertConfirmDeleteFax,
      isVisibleAlertFaxErrorInfo,
      deleteError,
      isDeleting,
    } = this.state;

    let fax = Object.assign({}, this.props.fax);
    fax.pages = Number(fax.pages); // Server has pages as string; client uses numbers
    fax.xmitpages = Number(fax.xmitpages); // Server has xmitpages as string; client uses numbers
    fax.status = Number(fax.status); // Server has status as string; client uses numbers
    fax.cover_sheet = (fax.cover_sheet === 'True'); // Server has cover_sheet as string 'True' or 'False'; client uses booleans
    //fax.cover_sheet = typeof (fax.cover_sheet) === 'string' ? fax.cover_sheet === 'True' : fax.cover_sheet;

    const { hideModal, handleModalCoverPage, handleModalDocument, handleModalReceipt, handleAlertConfirmDeleteFax, handleAlertFaxErrorInfo, deleteFax, resendFaxData } = this;

    const number = phoneUtil.parseAndKeepRawInput(fax.recipient_fax, fax.recipient_region_code);

    let iconClassName, faxTime, numPages;
    if (fax.status === 4) {
      iconClassName = 'success';
      faxTime = moment.tz(fax.completetime, 'America/New_York');
      numPages = fax.xmitpages;
    } else if (fax.status < 4) {
      iconClassName = 'pending';
      faxTime = moment.tz(fax.posted, 'UTC');
      numPages = fax.pages + (fax.cover_sheet ? 1 : 0);
    } else if (fax.status > 4) {
      iconClassName = 'error';
      faxTime = moment.tz(fax.posted, 'UTC');
      numPages = fax.pages + (fax.cover_sheet ? 1 : 0);
    }

    const pagesText = pluralize(numPages, 'page');

    var attributes = {};
    if (isBrowser) {
      attributes = { onClick: handleModalDocument }
    } else if (isAndroid) {
      // isMobile && isAndroid
      attributes = { target: "_blank" }
    }

        // <span key={info.md5_hash}><a  onClick={handleModalDocument} title={info.file.name} {...attributes}>{info.file.name}</a>{idx<numFiles?", ":""}</span>
        // <a href="/" onClick={handleModalDocument}>{info.file.name}</a>

    var fileSpans;
    if (fax.file_info) {
      const numFiles = fax.file_info.length - 1;
      fileSpans = fax.file_info.map((info, idx) => {
        if(isBrowser) {
          attributes.href = "/";
        } else if(isAndroid) {
          attributes.href = info.file.preview;
        }
        return (
          <span key={info.md5_hash}><a title={info.file.name} data-docIndex={idx} {...attributes}>{info.file.name}</a>{idx<numFiles?", ":""}</span>
        );
      });
    }

    return (
      <li className="HomeStatusItem media">
        <div className="media-left columns jnf-fax-status-icon">
          <i className={`column jnf-icon-${iconClassName}`} />
        </div>
        <div className="media-content columns is-multiline jnf-fax-status-content">
          <span className="column is-narrow jnf-fax-number">
            <img className="jnf-country-flag-icon" src={iconForRegion(fax.recipient_region_code)} alt={fax.recipient_region_code + ' flag'} />
            {phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL)}
          </span>
          <span className="column jnf-recipient-and-pages">
            {fax.recipient_name && fax.recipient_name + ', '}
            {pagesText}
          </span>
          <span className="column is-narrow jnf-fax-time">{faxTime && faxTime.tz(localTZ).format('lll')}</span>
          <span className="column is-12 jnf-documents-list jnf-documents">
            {fileSpans}
          </span>
          <span className="column is-12 jnf-documents-and-actions">
            <span className="jnf-documents">

              {fax.cover_sheet && isBrowser &&
                <a href="/" title="Cover Page" onClick={handleModalCoverPage}>Cover<span className="is-hidden-mobile"> page</span></a>
              }
              {fax.cover_sheet && isMobile &&
                <a href={fax.cp_url} title="Cover Page">Cover<span className="is-hidden-mobile"> page</span></a>
              }

              {fax.status === 4 && isBrowser &&
                <a href="/" title="Receipt" onClick={handleModalReceipt}>Receipt</a>
              }
              {fax.status === 4 && isMobile &&
                <Link to={{ pathname: PAGES.receipt, state: { ...{ fax } } }}>Receipt</Link>
              }

              {fax.status > 4 &&
                <a href="/" title="Error Information" onClick={handleAlertFaxErrorInfo}>Error Info</a>
              }

            </span>
            <span className="jnf-actions">
              {fax.status >= 4 &&
                <Link className="jnf-resend-fax" to={{ pathname: PAGES.newFax, state: resendFaxData() }}>
                  <span>Resend</span>
                  <i className="jnf-icon-resend is-hidden-mobile" />
                </Link>
              }
              {fax.status >= 4 &&
                <a href="/" className="jnf-delete-fax" onClick={handleAlertConfirmDeleteFax}>
                  <span className="is-hidden-mobile">Delete</span>
                  <i className="jnf-icon-delete" />
                </a>
              }
              <LoadingIndicator isLoading={isDeleting} />
            </span>
          </span>
        </div>

        <ModalCoverPage isVisible={isVisibleModalCoverPage} hasModal={true} handleCancel={hideModal} coverPageURL={fax.cp_url} />
        <ModalDocument isVisible={visibleModalDocInfo} handleCancel={hideModal} src={visibleModalDocInfo && visibleModalDocInfo.preview} name={visibleModalDocInfo && visibleModalDocInfo.name} />
        <ModalFaxReceipt isVisible={isVisibleModalReceipt} handleCancel={hideModal} fax={fax} />

        <Alert
          alertType='HomeStatusItem_FaxErrorInfo'
          isVisible={isVisibleAlertFaxErrorInfo}
          handleConfirm={hideModal}
          messageDetail={fax.fax_error ? fax.fax_error + '(Fax ID: ' + fax.fax_key + ')' : ''}
        />
        <Alert
          alertType='HomeStatusItem_ConfirmDeleteFax'
          isVisible={isVisibleAlertConfirmDeleteFax}
          handleConfirm={deleteFax}
          handleCancel={hideModal}
        />
        <Alert
          alertType='HomeStatusItem_DeleteError'
          isVisible={deleteError ? true : false}
          handleConfirm={hideModal}
          messageDetail={deleteError ? deleteError.message : ''}
        />
      </li>
    );
  }
}

export default HomeStatusItem;
