const sampleFaxData = [
  {
    client: "com.mobitech3000.JotNotFaxWeb",
    completetime: "2018-02-13 00:22:29",
    cover_sheet: "False",

    credits: "4",
    detailed_status: "Pending",

    fax_key: "ykslIB2SLB7rxmPOePhkGkUI7hlSQxM6",
    fax_service: 0,
    file_url: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    filename: "Blue-Shield-small-business-guide-17pp.pdf",
    file_urls: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    filenames: "Blue-Shield-small-business-guide-17pp.pdf",
    pages: "19",
    file_info: [
        {
            file: {
                name: "Blue-Shield-small-business-guide-17pp.pdf",
                preview: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
            },
            md5_hash: "abc4d43e65f8a863e4cf09586a898145",
            pages: 17,
        },
        {
            file: {
                name: "Lorem ipsum (2 pp, legal, landscape).pdf",
                preview: "/sample-fax-files/Lorem ipsum (2 pp, legal, landscape).pdf",
            },
            md5_hash: "d4c7b7816574740df569d213a883efbb",
            pages: 2,
        }
    ],
    xmitpages: "0",
    posted: "2018-02-12 22:46:05",
    recipient_country_code: "1",
    recipient_fax: "6023696544",
    recipient_region_code: "US",
    refunded: "False",
    status: "3",
  },
  {
    client: "com.mobitech3000.JotNotFaxWeb",
    completetime: "2018-05-26 06:11:40",
    cover_sheet: "True",
    cp_url: "/sample-fax-files/cp2.html",
    credits: "1",
    detailed_status: "Success",

    fax_key: "JWA4zNnslkxv3MfXUDkcWneVpxPbFh08",
    fax_service: 0,
    file_url: "/sample-fax-files/Lorem ipsum (2 pp, legal, landscape).docx",
    filename: "Lorem ipsum (2 pp, legal, landscape).docx",
    pages: "21",
    file_info: [
        {
            file: {
                name: "Lorem ipsum (2 pp, legal, landscape).docx",
                preview: "/sample-fax-files/Lorem ipsum (2 pp, legal, landscape).docx",
            },
            md5_hash: "abc4d43e65f8a863e4cf09586a898145",
            pages: 2,
        },
        {
            file: {
                name: "Blue-Shield-small-business-guide-17pp.pdf",
                preview: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
            },
            md5_hash: "94a3153ee0f057b2c42ce6e05125b45f",
            pages: 17,
        },
        {
            file: {
                name: "Lorem ipsum (2 pp, A4, portrait, no margins).docx",
                preview: "/sample-fax-files/Lorem ipsum (2 pp, A4, portrait, no margins).docx",
            },
            md5_hash: "d4c7b7816574740df569d213a883efbb",
            pages: 2,
        },
    ],
    xmitpages: "3",
    xmittime: "00:34:14",
    posted: "2018-05-26 05:37:26",
    recipient_country_code: "1",
    recipient_fax: "2025719769",
    recipient_region_code: "US",
    refunded: "False",
    status: "4",



    sender_fax: "+66 (190) 637-8434",


    sender_url: "http://imageshack.us/consequat/in/consequat.jsp",

    message: "Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis. Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci.",
  },
  {
    client: "com.mobitech3000.JotNotFaxWeb",
    completetime: "2017-08-23 03:31:30",
    cover_sheet: "True",
    cp_url: "/sample-fax-files/cp3.html",
    credits: "1",
    detailed_status: "Error",
    fax_error: "This is a toll-free number. Some toll-free numbers are restricted to local calls only. Please try faxing to the recipient's non-toll-free-fax number instead.",
    fax_key: "fGyOtvmSpU2zZ3_bIPqKFAarJ7ujC5YC",
    fax_service: 0,
    file_url: "/sample-fax-files/Lorem ipsum (2 pp, A4, portrait, no margins).docx",
    filename: "Lorem ipsum (2 pp, A4, portrait, no margins).docx",
    pages: "2",
    file_info: [
        {
            file: {
                name: "Lorem ipsum (2 pp, legal, landscape).docx",
                preview: "/sample-fax-files/Lorem ipsum (2 pp, legal, landscape).docx",
            },
            md5_hash: "abc4d43e65f8a863e4cf09586a898145",
            pages: 2,
        },
    ],
    xmitpages: "0",

    posted: "2017-08-23 02:11:42",
    recipient_country_code: "66",
    recipient_fax: "2859469392",
    recipient_region_code: "TH",
    refunded: "True",
    status: "5",
    recipient_name: "Lorraine Leaming",

    sender_email: "mduffitti5@sbwire.com",
    sender_fax: "+48 (474) 426-8311",

    sender_phone: "+380 (356) 780-9026",


    message: "Etiam faucibus cursus urna. Ut tellus. Nulla ut erat id mauris vulputate elementum.",
  },
  {
    client: "com.mobitech3000.JotNotFaxWeb",
    completetime: "2018-05-26 07:16:20",
    cover_sheet: "True",
    cp_url: "/sample-fax-files/cp4.html",
    credits: "1",
    detailed_status: "Success",

    fax_key: "LVMR1p98PVKwGubKGhiDI8GvnJsM_O4A",
    fax_service: 0,
    file_url: "/sample-fax-files/1 Page Tabloid Landscape.pdf",
    filename: "1 Page Tabloid Landscape.pdf",
    pages: "2",
    file_info: [
        {
            file: {
                name: "1 Page Tabloid Landscape.pdf",
                preview: "/sample-fax-files/1 Page Tabloid Landscape.pdf",
            },
            md5_hash: "abc4d43e65f8a863e4cf09586a898145",
            pages: 2,
        },
    ],
    xmitpages: "2",
    xmittime: "00:17:24",
    posted: "2018-05-26 06:58:56",
    recipient_country_code: "1",
    recipient_fax: "4682684395",
    recipient_region_code: "CA",
    refunded: "False",
    status: "4",


    sender_email: "pguenthere0@dedecms.com",
    sender_fax: "+504 (346) 544-8734",
    sender_name: "Pattie Guenther",
    sender_phone: "+62 (680) 627-1810",
    sender_url: "http://blogspot.com/lacinia/aenean/sit.jpg",


  },
  {
    client: "com.mobitech3000.JotNotFaxWeb",
    completetime: "2018-05-07 14:17:33",
    cover_sheet: "True",
    cp_url: "/sample-fax-files/cp5.html",
    credits: "1",
    detailed_status: "Success",

    fax_key: "abgQyU0mJTPFo6I1E68Be7CUYrjl28If",
    fax_service: 0,
    file_url: "/sample-fax-files/Lorem ipsum (2 pp, legal, landscape).pdf",
    filename: "Lorem ipsum (2 pp, legal, landscape).pdf",
    pages: "2",
    file_info: [
        {
            file: {
                name: "Lorem ipsum (2 pp, legal, landscape).pdf",
                preview: "/sample-fax-files/Lorem ipsum (2 pp, legal, landscape).pdf",
            },
            md5_hash: "abc4d43e65f8a863e4cf09586a898145",
            pages: 2,
        },
    ],
    xmitpages: "3",
    xmittime: "00:31:52",
    posted: "2018-05-07 13:45:41",
    recipient_country_code: "55",
    recipient_fax: "2937495583",
    recipient_region_code: "BR",
    refunded: "False",
    status: "4",



    sender_fax: "+242 (773) 590-6193",

    sender_phone: "+963 (509) 581-8571",

    subject: "Nulla facilisi.",

  },

];

export default sampleFaxData;