import React from 'react';
import ReactDOM from 'react-dom';
//import './assets/index.css';
//import 'bulma/css/bulma.css';
import './styles.css';
import App from './components/App';
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();  // FIXME: turn back on?
