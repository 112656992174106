import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import { PAGES } from '../utils/constants';

function Balance({ credits, children, showAddCreditsButton }) {
  
  const handleClick = (event) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'To Store',
      label: 'Add Credits button'
    });
    return true;
  };

  return (
    <section className="Balance section">
      <div className="container">
        <h2>Credits</h2>
        
        {children}

        <div className="level is-mobile">
          <span className="level-left">Your credits: {credits}</span>
          {showAddCreditsButton && <Link to={PAGES.store} className="level-right button is-primary is-outlined" onClick={handleClick}>Add Credits</Link>}
        </div>

      </div>
    </section>
  );
}

export default Balance;

