import countryCodes from "../assets/flags/countryCodes.json";

// NB: We're using "region code" to refer to two-letter ISO country codes, eg "US", "CA", "DE"
// We're using "country code" to refer to numeric dialing codes, eg 1 for US/CA, 49 for DE

function isAllowedRC(regionCode) {
  return regionCode && (typeof regionCode) === "string" && regionCode.length === 2 && regionCode in countryCodes;
}

function allowedRC(regionCode) {
  if(isAllowedRC(regionCode)) {
    return regionCode;
  }

  return "US";
}

function importAssets(context) {
  let assets = {};
  context.keys().forEach((item) => { assets[item.replace('./', '')] = context(item); });
  return assets;
}

const flagIcons = importAssets(require.context('../assets/flags', false, /\.(png|jpe?g|svg)$/));
console.log("FLAG ICONS", flagIcons);

function iconForRegion(regionCode) {
  const key = "icon-flag-" + allowedRC(regionCode) + ".svg";
  if (key && key in flagIcons) {
    return flagIcons[key].default;
  }
  return null;
}

export { iconForRegion, isAllowedRC, allowedRC };
