import React from 'react';

import { LOCAL_MODE, INPUT_MAX_LENGTH } from '../utils/constants.js';
import { ENDPOINTS, getURL, postData } from '../utils/fetching.js';


const senderInfoKeys = ["ident", "senderName", "senderOrganization", "senderAddress", "senderPhone", "senderFax", "senderEmail", "senderWebsite"];

function SenderInfoForm({ senderName="", senderOrganization="", senderAddress="", senderPhone="", senderFax="", senderEmail="", senderWebsite="", handleChange }) {
  return (
    <div className="SenderInfoForm subsection">

      <CoverPageField
        jnfClassName="jnf-sender-name"
        label="Name"
        name="senderName"
        type="text"
        value={senderName}
        handleChange={handleChange}
        autoComplete="section-sender name"
      />

      <CoverPageField
        jnfClassName="jnf-sender-organization"
        label="Company"
        name="senderOrganization"
        type="text"
        value={senderOrganization}
        handleChange={handleChange}
        autoComplete="section-sender organization"
      />

      <CoverPageField
        jnfClassName="jnf-sender-address"
        label="Address"
        name="senderAddress"
        type="textarea"
        value={senderAddress}
        handleChange={handleChange}
        autoComplete="section-sender street-address"
      />

      <CoverPageField
        jnfClassName="jnf-sender-phone"
        label="Phone"
        name="senderPhone"
        type="text"
        value={senderPhone}
        handleChange={handleChange}
        autoComplete="section-sender tel"
      />

      <CoverPageField
        jnfClassName="jnf-sender-fax"
        label="Fax"
        name="senderFax"
        type="text"
        value={senderFax}
        handleChange={handleChange}
        autoComplete="section-sender fax tel"
      />

      <CoverPageField
        jnfClassName="jnf-sender-email"
        label="Email"
        name="senderEmail"
        type="email"
        value={senderEmail}
        handleChange={handleChange}
        autoComplete="section-sender email"
      />

      <CoverPageField
        jnfClassName="jnf-sender-website"
        label="Website"
        name="senderWebsite"
        type="text"
        value={senderWebsite}
        handleChange={handleChange}
        autoComplete="section-sender url"
      />

    </div>
  );
}

function CoverPageField({ jnfClassName, label, name, type, value, handleChange, autoComplete }) {

  const className = "field is-horizontal " + jnfClassName;
  const inputProps = {
    name, value, autoComplete,
    onChange: handleChange,
    placeholder: "(Optional)",
    maxLength: INPUT_MAX_LENGTH,
  };
  const input = (type === "textarea")
    ? <textarea className="textarea" rows="3" {...inputProps} />
    : <input className="input" type={type} {...inputProps} />;
  return (
    <div className={className}>
      <div className="field-label is-normal">
        <label className="label" htmlFor={name}>{label}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            {input}
          </div>
        </div>
      </div>
    </div>
  );
}

function formatSenderInfoClientToServer(clientObj) {
  const ret = {
    // skip ident; server returns it but doesn't expect it in body of POSTs
    sender_name: clientObj.senderName || '',
    sender_organization: clientObj.senderOrganization || '',
    sender_address: clientObj.senderAddress || '',
    sender_phone: clientObj.senderPhone || '',
    sender_fax: clientObj.senderFax || '',
    sender_email: clientObj.senderEmail || '',
    sender_url: clientObj.senderWebsite || '',
  };
  return ret;
}

function formatSenderInfoServerToClient(serverObj) {
  const ret = {
    ident: serverObj.ident,
    senderName: serverObj.sender_name,
    senderOrganization: serverObj.sender_organization,
    senderAddress: serverObj.sender_address,
    senderPhone: serverObj.sender_phone,
    senderFax: serverObj.sender_fax,
    senderEmail: serverObj.sender_email,
    senderWebsite: serverObj.sender_url,
  };
  return ret;
}

function getSenderInfo() {
  if(LOCAL_MODE) {
    return Promise.resolve({
      ident: 1,
      title: "work",
      senderName: "A. Name",
      senderAddress: "555 Street Rd.\nTown, ST 12345",
      senderOrganization: "Large Corp.",
      senderPhone: "+1 222-345-6788",
      senderFax: "+1 222-345-6789",
      senderEmail: "a@example.com",
      senderWebsite: "https://www.example.com/",
    });
  }

  return getURL(ENDPOINTS.senderInfo)
    .then((json) => {
      // If user has no sender infos saved yet, don't display anything
      if(json && json.results && json.results.length > 0) {
        return formatSenderInfoServerToClient(json.results[0]);
      }
    });
}

function postSenderInfo(senderInfo) {
  if(LOCAL_MODE) {
    return Promise.resolve(Object.assign({}, senderInfo, { "ident": 1 }));
  }

  const method = (senderInfo.ident && senderInfo.ident > 0) ? "PUT" : "POST";

  const formData = new FormData();
  Object.entries(formatSenderInfoClientToServer(senderInfo)).forEach(([key, value]) => {
    formData.append(key, value);
  });

  let endpoint = ENDPOINTS.senderInfo;
  if (method === "PUT") {
    endpoint = endpoint + "/" + senderInfo.ident;
  }

  return postData(endpoint, formData, method)
    .then((json) => {
      return formatSenderInfoServerToClient(json);
    });
}

export { senderInfoKeys, CoverPageField, getSenderInfo, postSenderInfo };

export default SenderInfoForm;
