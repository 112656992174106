import React from 'react';
import { Link } from 'react-router-dom';
import { PAGES } from '../utils/constants.js';

function Page({ page }) {
  let pageContents;
  switch (page) {
    case 'termsOfUse': pageContents = termsOfUseContents; break;
    case 'privacyPolicy': pageContents = privacyPolicyContents; break;
    default: pageContents = null; break;
  }
  return PageWrapper(page, pageContents);
}

function PageWrapper(page, pageContents) {
  return (
    <section className={'section jnf-first ' + page}>
      <div className="container">
        <div className="content">
          {pageContents}
        </div>
      </div>
    </section>
  );
}

const termsOfUseContents = (
  <div>

<h1 id="terms-of-use">Terms of Use</h1>
<p>JotNot is the creator of mobile apps for iOS and Android that enhance productivity for people and businesses. Our products include:</p>
<ul>
<li><strong>JotNot Invoice:</strong> mobile app to create, send, and track invoices.</li>
<li><strong>JotNot Scanner App and JotNot Scanner App Pro:</strong> mobile apps to scan documents.</li>
<li><strong>JotNot Fax:</strong> mobile apps to send faxes.</li>
<li><strong>JotNot Signature:</strong> mobile app to sign digital documents.</li>
</ul>
<p>These products and other mobile apps, websites, web apps, software and services created and provided by JotNot are collectively the “Products” (“Apps” or “Services”). </p>
<p>These Terms of Use (“terms”, “Terms of Service”), are a legal contract that bind you (“the user”) to conditions specified by MobiTech 3000 LLC, maker of JotNot Products (“MobiTech”, “JotNot”, “we”, “us”, “our”). Any use of JotNot Products, Apps or Services by the user marks the user’s consent to be bound by these terms.  Any disagreement with these terms by the user, in part or in entirety, disqualifies the user from using the service. If you are accepting these Terms and using the Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so.</p>
<p>Please read these Terms carefully. They cover important information about JotNot Products and information about acceptable use, purchases, changes to these Terms, limitations of liability, privacy information, class action waiver, and resolution of disputes by arbitration instead of in court.</p>
<h3 id="1-privacy-policy">1. Privacy Policy</h3>
<p>JotNot’s Privacy Policy describes how we handle the information you provide to us when you use our Products. The Privacy Policy is incorporated into this agreement. By accepting the Terms of Use the user also accepts our Privacy Policy, which can be modified at any time with or without notice to the user. Our privacy policy can be found <Link to={PAGES.privacyPolicy}>here</Link>.</p>
<h3 id="2-acceptable-use">2. Acceptable Use</h3>
<h4 id="compliance-with-laws-and-regulations">Compliance with Laws and Regulations</h4>
<p>Your use of JotNot Products must not violate any applicable laws, including copyright or trademark laws, export control laws, or other laws in your jurisdiction. You are responsible for making sure that your use of JotNot Products is in compliance with laws and any applicable regulations.</p>
<h4 id="conduct-restrictions">Conduct Restrictions</h4>
<p>You may not interrupt, introduce malicious code, or attempt to otherwise impede the operation and service of JotNot Products, and you may not help anyone else to do so.</p>
<p>You may not attempt to disrupt or tamper with JotNot&#39;s servers in ways that could harm our Products, to place undue burden on JotNot&#39;s servers through automated means, or to access JotNot Products in ways that exceed your authorization.</p>
<p>You may not probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures.</p>
<p>You may not engage in any activity that would prevent others from using JotNot Products, or harm their ability to do so.</p>
<p>You may not submit content or export content that is blatantly offensive.</p>
<p>You may not use JotNot Invoice to make or send fraudulent or deceptive invoices or estimates.</p>
<p>You may not use JotNot Products to harass, abuse, intimidate, threaten, or incite violence towards any individual or group, including JotNot employees, officers, and agents, or other JotNot users.</p>
<p>You may not use our Products for any form of excessive automated bulk activity (for example, spamming), or relay any other form of unsolicited advertising or solicitation through our servers, such as get-rich-quick schemes or scams.</p>
<p>You may not impersonate any person or entity, including any of our employees or representatives, including through false association with JotNot, or by fraudulently misrepresenting your identity or purpose.</p>
<p>You may not violate the privacy of any third party, such as by posting another person&#39;s personal information without consent.</p>
<p>You many not attempt to send faxes through JotNot Fax without using credits in your account, or attempt to add credits to your account without a valid purchase.</p>
<p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of, use of, or access to any JotNot Product without our express written permission.</p>
<h4 id="use-of-mobile-apps">Use of Mobile Apps</h4>
<p>The user agrees to access JotNot Apps only through software downloaded from the Apple App Store or the Google Play Store. The user may not modify or attempt to modify the software, or use a modified version of the apps.</p>
<p>The user may not use JotNot Apps from a rooted device.</p>
<p>JotNot releases updates through the App Store and the Play Store.  It is the user&#39;s responsibility to update the app from the App Store or the Play Store periodically and as necessary to maintain the service.</p>
<p>Subject to these terms, JotNot grants to the user a non-exclusive, non-transferable, and revocable right to use and access JotNot Products.</p>
<h4 id="services-usage-limits">Services Usage Limits</h4>
<p>If we determine your usage to be significantly excessive in relation to other JotNot customers, we reserve the right to suspend your account or throttle your service usage until you can reduce your bandwidth, server or resource consumption.</p>
<h4 id="monitoring">Monitoring</h4>
<p>JotNot reserves the right to monitor the use of JotNot Products, and you agree to allow our monitoring.  However, we make no promise that we will monitor the service.</p>
<h3 id="3-accounts">3. Accounts</h3>
<p>JotNot Products may require or provide the option to create an account (“Your Account”). A valid email address and password are required to sign up for Your Account. You must be a human to create an account. Accounts registered by &quot;bots&quot; or other automated methods are not permitted. Additional information about your business may be requested during signup. The types of data we collect and our use of the data is governed by our privacy policy, which is available <Link to={PAGES.privacyPolicy}>here</Link>.</p>
<p>JotNot Fax requires an account (“Your Account”) to use the service. The app automatically creates an account whenever the app is first started on a device. Your Account is for an individual device, but it can be merged with the account for another devices by contacting support from within the app.</p>
<p>You are solely responsible for Your Account, the data and content in Your Account, and anything that you do with Your Account.</p>
<p>You are responsible for maintaining the security of your account and password. JotNot cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</p>
<p>You may not sell, license, lease, rent, loan, gift, assign, convey, or otherwise transfer Your Account to anyone else.  You may not share access to Your Account or permit others to use Your Account to access JotNot Products.</p>
<h3 id="4-user-content">4. User Content</h3>
<h4 id="content-restrictions-and-rights">Content Restrictions and Rights</h4>
<p>You may not submit any content or information to JotNot Products that violates the copyright, trademark, trade secret, or any other personal or proprietary rights of anyone else.  </p>
<p>By submitting content to JotNot Products, you maintain that the content is yours, or that you have the right to submit the content. Also by submitting content to JotNot Products, you agree that you are solely liable for any intellectual property right infringement that may result from JotNot Products retaining that content.</p>
<p>JotNot takes no responsibility for any of your content.  We do not endorse, implicitly or explicitly, your content, and we assume no liability for your content.</p>
<p>You accept all risk of unauthorized access to your Content and any information that you provide to JotNot.</p>
<p>By submitting content or information to JotNot Products, you grant us permission to process such content and information, including but not limited to storage, transmission and processing, for the functioning of our products, providing user services or to providing customer support.</p>
<p>JotNot maintains the right to disclose your content to comply with any legal and governmental requirement.</p>
<h4 id="content-export-sending-and-transmission">Content Export, Sending and Transmission</h4>
<p>JotNot Products allow you to send content (such as documents, files, invoices, estimates, faxes, data and other information) in various ways, including but not limited to emailing, printing, faxing or messaging.</p>
<p>JotNot Products are a passive conduit for your Content. You are solely responsible for all content that you send or export. We do not endorse, implicitly or explicitly, your Content, and we assume no liability for your Content.</p>
<p>We are not responsible for emails, faxes, invoices, estimates, documents, messages, or data failing to send, or any other content for failing to reach the intended recipient.</p>
<h3 id="5-payment-and-collections">5. Payment and Collections</h3>
<h4 id="purchases">Purchases</h4>
<p>Purchases of JotNot Products are charged to your iTunes account on iOS devices, or to your Google Play account on Android devices. JotNot is not obligated to provide a refund for purchases, except as required by law.  Refunds will be at the sole discretion of JotNot. Please contact support from within the app with any questions about your purchases.</p>
<h4 id="fax-credits">Fax Credits</h4>
<p>Sending faxes through JotNot Products requires the purchase of credits. See the apps for available packs of credits, their cost, and the price of a fax in credits. Purchases are charged to your iTunes account on iOS devices, or to your Google Play account on Android devices.</p>
<p>Credits are added to your account when you purchase them, and are available from any device with access to that account. Credits expire 5 years after the date of purchase.</p>
<h4 id="subscriptions">Subscriptions</h4>
<p>JotNot Invoice is billed on a subscription basis. The effective date of the billing cycle will begin on the date of your purchase. You will be billed on a recurring monthly or annual basis, depending on the subscription that you purchased. Subscriptions are charged to your iTunes Account at confirmation of purchase. Your subscription will automatically renew, and will automatically be charged without confirmation to your iTunes Account, if you do not cancel your subscription more than 24 hours before the renewal date.  </p>
<p>Subscriptions may be managed, and auto-renewal may be turned off, by going to your iTunes Account Settings after purchase. Any unused portion of a free trial period, if offered, will be forfeited when you purchase a subscription.</p>
<p>If you agree to a subscription price, that will remain your price for the duration of the payment term; however, prices are subject to change at the end of a payment term.</p>
<p>It is your responsibility to manage your subscription. Refunds will not be provided in full or in part for early cancellation of a subscription; however, the service will remain active for the length of the paid billing period. We reserve the right to decline any refund requests by the user.</p>
<h4 id="collections">Collections</h4>
<p>JotNot Invoice is a tool to assist you with invoicing and estimates, but we are not a debt collection agency. It is solely your responsibility to collect money owed to you. JotNot assumes no responsibility for missing or late payments from your customers, bounced checks, fraudulent credit or debit payments, or any other liabilities incurred by your customers.</p>
<p>If you turn on credit card payments via Stripe in JotNot Invoice, you will need to connect JotNot Invoice to an existing Stripe account, or create a new Stripe account and connect JotNot Invoice to that. Your Stripe account is separate from your JotNot Invoice account. When JotNot Invoice is connected to your Stripe account, Stripe will inform JotNot Invoice when a new payment arrives.  However, it is your responsibility to monitor and manage your Stripe account, as described in Stripe’s terms of service here: <a href="https://stripe.com/us/legal" target="_blank" rel="noopener noreferrer">https://stripe.com/us/legal</a>.</p>
<p>If you turn on PayPal payments in JotNot Invoice, you will need to provide JotNot Invoice a PayPal.ME link to your PayPal account. JotNot will not receive notification of any payments made through PayPal.  It is your responsibility to manage and track your PayPal account, as governed by the PayPal user agreement.  If you would like JotNot Invoice to reflect payments to your PayPal account, you will need to manually record those payments within the app.</p>
<h4 id="terms-for-additional-services">Terms for Additional Services</h4>
<p>JotNot Invoice provides the option to collect credit card payments using Stripe. By using Stripe, the user is bound to their terms of service, which can be found here: <a href="https://stripe.com/us/legal" target="_blank" rel="noopener noreferrer">https://stripe.com/us/legal</a>.</p>
<p>JotNot Invoice provides the option to collect payments using PayPal. By using PayPal, the user is bound to their terms of service, which can be found here: <a href="https://www.paypal.com/ga/webapps/mpp/ua/useragreement-full" target="_blank" rel="noopener noreferrer">https://www.paypal.com/ga/webapps/mpp/ua/useragreement-full</a>.</p>
<h3 id="6-cancellation-and-termination">6. Cancellation and Termination</h3>
<p>JotNot has the right to suspend or terminate your access to all or any part of the Products, Apps, Services or Websites at any time, with or without cause, with or without notice, effective immediately for reasons including but not limited to for violation of these terms or suspected fraud by the user. JotNot reserves the right to refuse service to anyone for any reason at any time.</p>
<p>Upon termination, JotNot reserves the right to delete all data and other information that is associated with your account. We reserve the right to retain and use your data and other information as necessary to comply with legal obligations, resolve disputes, and enforce our agreements.</p>
<p>Upon termination, the user must immediately cease using JotNot Products. The user is responsible for cancelling any active subscriptions at least 24 hours before the next billing cycle.</p>
<p>Accounts may be terminated and deleted after a certain period of inactivity. Currently, that period is 2 years. Account deletion may include deletion of all user information, data and content.</p>
<p>All provisions of this Agreement which by their nature should survive termination will survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
<h3 id="7-disclaimers-and-limitations-of-liability">7. Disclaimers and Limitations of Liability</h3>
<h4 id="disclaimer-of-warranties">Disclaimer of Warranties</h4>
<p>JotNot provides the Products, Apps, Services and Website “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.</p>
<p>JotNot does not warrant that the Products and Services will meet your requirements; that the Products and Services will be uninterrupted, timely, secure, or error-free; that the information provided through the Products and Services is accurate, reliable or correct; that any defects or errors will be corrected; that the Products and Services will be available at any particular time or location; or that the Products and Services are free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Products and Services.</p>
<h4 id="limitation-of-liability">LIMITATION OF LIABILITY</h4>
<p>WE ARE NOT RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY USER-GENERATED CONTENT. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, WE, ON BEHALF OF OUR EMPLOYEES, OWNERS, EXECUTIVES, AGENTS AND PARTNERS, EXCLUDE AND DISCLAIM LIABILITY FOR ANY LOSSES AND EXPENSES OF WHATEVER NATURE AND HOWSOEVER ARISING, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT, GENERAL, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES; LOSS OF USE; LOSS OF DATA; LOSS CAUSED BY A VIRUS; LOSS OF INCOME OR PROFIT; LOSS OF OR DAMAGE TO PROPERTY; UNAUTHORIZED ACCESS TO OR ALTERATIONS OF YOUR TRANSMISSIONS OR DATA; CLAIMS OF THIRD PARTIES; OR OTHER LOSSES OF ANY KIND OR CHARACTER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE PRODUCTS, APPS, SERVICES, SITE, OR ANY WEBSITE IN WHICH THEY ARE LINKED. THE USER ASSUMES TOTAL RESPONSIBILITY FOR ESTABLISHING SUCH PROCEDURES FOR DATA BACKUP AND VIRUS CHECKING AS THE USER CONSIDERS NECESSARY. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER BASIS.</p>
<h4 id="indemnification">Indemnification</h4>
<p>You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, suits, demands, disputes, actions, allegations, or investigations brought by any third party or governmental authority, and all liabilities, damages, losses, costs, and expenses, including but not limited to attorneys’ fees and other expenses, arising out of or in any way connected with (i) your use of or access to JotNot Products, (ii) your data or content created in, stored it, used in, or sent by JotNot Products, (iii) your breach or alleged breach of any warranties made by you hereunder or your violation of any other provision of these Terms of Use, or (iv) your violation of any law or the rights of a third-party.</p>
<h4 id="force-majeure">Force Majeure</h4>
<p>JotNot Invoice will not be held liable for a delay or failure in service caused by reason of any unforeseen event including, but not limited to, acts of God, natural disasters, power failures, server failures, third party server failures or service interruptions.</p>
<h3 id="8-miscellaneous">8. Miscellaneous</h3>
<h4 id="governing-law">Governing Law</h4>
<p>Except to the extent applicable law provides otherwise, this Agreement between you and JotNot and any access to or use of the Products and Services are governed by the federal laws of the United States of America and the laws of the State of Massachusetts, without regard to conflict of law provisions. You and JotNot agree to submit to the exclusive jurisdiction and venue of the courts located in the County of Suffolk, Massachusetts.</p>
<h4 id="dispute-resolution-and-arbitration">Dispute Resolution and Arbitration</h4>
<p>We want to address your concerns without needing a formal legal case. Before filing a claim against JotNot, you agree to try to resolve the dispute informally by contacting <a href="mailto:support@jotnot.com">support@jotnot.com</a>. We&#39;ll try to resolve the dispute by contacting you via email.</p>
<p>If the parties do not reach settlement within a period of 60 days, any unresolved controversy or claim arising out of or relating to this Agreement shall proceed to binding arbitration under the Rules of Arbitration of the International Chamber of Commerce. The parties shall seek to mutually appoint an arbitrator; arbitration will take place in Cambridge, Massachusetts. All negotiations and arbitration proceedings will be confidential.</p>
<h4 id="no-class-or-representative-proceedings-class-action-waiver">No Class Or Representative Proceedings: Class Action Waiver</h4>
<p>You agree that you may bring claims against JotNot only in your individual capacity and not as a plaintiff or class member in any class or representative action. You agree that no arbitrator or judge may consolidate more than one person’s claims or otherwise preside over any form of a representative or class proceeding. If a court decides that applicable law precludes enforcement of any of this paragraph’s limitations as to a particular claim, then that claim and only that claim must be severed from the arbitration and may be brought in court.</p>
<h4 id="non-assignability">Non-Assignability</h4>
<p>JotNot may assign or delegate these Terms of Use and/or the JotNot Privacy Statement, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Use or Privacy Statement without our prior written consent, and any unauthorized assignment and delegation by you is void.</p>
<h4 id="severability-no-waiver-and-survival">Severability, No Waiver, and Survival</h4>
<p>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of JotNot to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.</p>
<h4 id="changes-to-these-terms-complete-agreement">Changes to These Terms; Complete Agreement</h4>
<p>We reserve the right to modify, at any time, the Terms of Use, Privacy Policy, and pricing of JotNot Products. Any modifications will become effective immediately, and the user agrees to be bound by the modified terms with continued access to JotNot Products. We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, any or all of the Products (or any parts of them) with or without notice.</p>
<p>These Terms of Use, together with the JotNot Privacy Policy, represent the complete and exclusive statement of the agreement between you and us. This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between you and JotNot relating to the subject matter of these terms including any confidentiality or nondisclosure agreements.</p>
<h4 id="contact">Contact</h4>
<p>If you have any questions regarding these Terms of Use, please contact us by email at <a href="mailto:support@jotnot.com">support@jotnot.com</a>.</p>
<p><em>Updated: May 25, 2018</em></p>

  </div>
);

const privacyPolicyContents = (
  <div>

<h1 id="privacy-policy">Privacy Policy</h1>
<p>Your privacy is important to us at MobiTech 3000 LLC, maker of JotNot Products (“MobiTech”, “JotNot”, “we”, “us”, “our”). This policy covers how we collect, use, store, and disclose your information as we provide our products and services to you (the “Customer” or “User”). Please take a moment to review our policy.</p>
<p>JotNot is the creator of mobile apps for iOS and Android that enhance productivity for people and businesses. Our products include:</p>
<ul>
<li><strong>JotNot Invoice:</strong> mobile app to create, send, and track invoices.</li>
<li><strong>JotNot Scanner App and JotNot Scanner App Pro:</strong> mobile apps to scan documents.</li>
<li><strong>JotNot Fax:</strong> mobile apps to send faxes.</li>
<li><strong>JotNot Signature:</strong> mobile app to sign digital documents.</li>
</ul>
<p>These products and other mobile apps, websites, web apps, software and services created and provided by JotNot are collectively the “Products” (“Apps” or “Services”). </p>
<p>Some provisions in this Privacy Policy reference the Terms of Use which can be found <Link to={PAGES.termsOfUse}>here</Link>.</p>
<h3 id="information-we-collect">Information We Collect</h3>
<p>At times, we may collect the following information about our users:</p>
<ul>
<li><strong>Usage Data:</strong> We receive information when you use our apps, view content on our websites, or otherwise interact with our services, which we refer to as “Usage Data,” even if you have not created an account. This Usage Data includes information such as: your IP address, browser type, operating system, the referring web page, pages visited, location, device information (including device and application IDs), search terms, and cookie information. We also receive Usage Data when you click on, tap, swipe, view, or interact with our Apps and Services. We use Usage Data to monitor, operate and improve the performance, security and reliability of our Products.</li>
<li><strong>Cookies:</strong> We use cookies to make interactions with our Products easy and meaningful. We use cookies to keep you logged in, remember your preferences, and provide information for future development of Apps and Services. A cookie is a small piece of text that our web server stores on your computer or mobile device, which your browser sends to us when you return to our site. Cookies may store a unique identifier for each logged in user. Cookies are essential for the operation of the website, apps and services, or are used for performance or functionality. By using our website or apps, you agree that we can place these types of cookies on your computer or device. If you disable your browser or device’s ability to accept cookies, you may not be able to log in or use our services. We do not support the Do Not Track browser option.</li>
<li><strong>Customer support and email contact:</strong> If you contact us through our website or apps to ask questions or request customer support, we may collect information such as the name and email address of the user, and information about the user’s devices and applications. We may also request copies of user’s receipts from Apple, in order to verify certain purchases.</li>
<li><strong>Mailing lists information:</strong> You may opt into our mailing lists by providing your email address and explicitly consenting to receive emails from us. You may unsubscribe from mailing lists at any time by following the instructions contained within the email.</li>
<li><strong>Sending digital documents and information:</strong> For certain Services, (for example sending Faxes with JotNot Fax or sending invoices with JotNot Invoice) we may collect information about the sending device, information about the recipient (for example, the sender’s name, fax number, phone number, email address, physical address, website or other provided information), and information about the sender (for example, the sender’s name, fax number, phone number, email address, physical address, website or other provided information). We are passive conduits for the contents of customers’ documents and information (such as faxes and invoices). We do not monitor or edit the private data of customer documents except when requested by users in order to provide customer support.</li>
<li><strong>Account Information:</strong> Certain services require or provide the option of create a user account. If you create a user account, we may ask you to provide us with information such as your name, username, password, email address, phone number, details about your organization, or other information. We never share your information with anyone except certain third parties that help us provide services to you as explained in the section “When and Why We Share Your Information” below. We never sell your information to anyone. Please refer to our <Link to={PAGES.termsOfUse}>Terms of Use</Link> for further details on accounts.</li>
<li><strong>Payment Information:</strong> JotNot Invoice provides the user the option to collect payments using services including Stripe and Paypal. If the user uses these services, we may collect account identifiers, tokens, other information to perform services and track payments for the user.</li>
</ul>
<p>We will ask for your consent before using your information for a purpose other than described in this privacy policy. We do not knowingly collect personally-identifiable information of anyone under age 13. If we learn that we have personal information of anyone under age 13, we will delete it from our system.</p>
<h3 id="information-we-do-not-collect">Information We Do Not Collect</h3>
<p>We do not intentionally collect sensitive personal information, such as social security numbers, genetic data, race or ethnic origin, physical or mental health, sexual life or orientation, criminal offences, religious beliefs, trade union activities, or political opinions.</p>
<p>Although our Products do not request or intentionally collect these types of sensitive personal information, we realize that you might store this kind of information inside documents, faxes, invoices, scans, or other data you create and store in our apps and services (collectively, your “Content”). If you store any sensitive personal information in your Content, which may be stored in our Apps, Services and servers, you are consenting to storage of that information.</p>
<p>We do not intentionally collect information that is in your Content. Your Content belong to you, and you are responsible for it, as well as for making sure that your Content complies with our Terms of Use. We do not access your Content unless required to for security or maintenance, or for support reasons with your consent.</p>
<h3 id="how-we-use-your-information">How We Use Your Information</h3>
<p>Information collected from you is used only to provide our services and products, to bill customers, to provide customer support, to send you information about our product and services, to market to you, to send out our newsletter to our mailings lists, and to comply with any requirements of the law.</p>
<p>We may occasionally send you notification emails about updates to our product, legal documents, offer customer support or marketing emails. We may use analytics tools to capture data such as when you open our email or click on any links or banners within the email to help us to gauge the effectiveness of our communications, customer support and marketing campaigns. You may unsubscribe from receiving these messages by following the instructions contained within the emails. If you unsubscribe, you may still receive emails when required to perform Product services (for example, emails related to resetting your account password), or when required to do so by law (e.g. notifying you of a data breach).</p>
<p>We may share or disclose aggregated non-personal data, such as total number of customers or aggregated app usage statistics for marketing purposes.</p>
<h3 id="when-and-why-we-share-information">When and Why We Share Information</h3>
<p>We do not share your information with third parties except in the limited circumstances described here. We never sell your information to anyone.</p>
<p>We use a select number of trusted third parties to help us provide services to you. We use trusted third parties for services such as data storage and processing, fax delivery, and email delivery. These service providers are carefully selected and meet high data protection and security standards. We only share information with them that is required for the services offered.</p>
<p>To send faxes, our Products may use third-party intermediaries for fax transmission. These third-party intermediaries receive no personal information from us other than the content and destination of faxes. Currently, we use the following faxing services: Faxage, run by EC Data Systems, Inc. located in Denver, Colorado USA; SRFax Inc, located in Burnaby, British Columbia CA; and Phaxio, Inc. located in Northbrook, Illinois USA. Security is a top priority for these faxing services. You can find more information on their respective websites: <a href="http://www.faxage.com/secure-internet-fax.php" target="_blank" rel="noopener noreferrer">Faxage security</a>, <a href="https://www.srfax.com/security-privacy/security-overview" target="_blank" rel="noopener noreferrer">SRFax security</a>, and <a href="https://www.phaxio.com/docs/security/hipaa" target="_blank" rel="noopener noreferrer">Phaxio security</a>.</p>
<p>Certain Products use cloud-based services for data storage and processing. These services are subject to our strong information security mechanisms, as outlined in the “Information Security” section below. </p>
<p>We may disclose personal information if required to do so by law or in the good faith belief that such action is: (1) required by law; (2) necessary to protect and defend the rights or property of JotNot; or (3) necessary to act in urgent circumstances to protect the safety and security of customers, Products or Services, or the public.</p>
<h3 id="accessing-and-controlling-your-personal-information">Accessing and Controlling Your Personal Information</h3>
<p>Our Apps and Services provide you with tools and settings where you may access, modify, correct, delete, or export your personal information. To the extent you are unable to perform any of these functions inside the App or Service, you may contact us for assistance at <a href="mailto:support@jotnot.com">support@jotnot.com</a>.</p>
<p>Where you have provided consent, you may withdraw it at any time, without affecting the lawfulness of the processing that was carried out prior to withdrawing it. Whenever you withdraw consent, you acknowledge and accept that this may have a negative influence on the quality of the Apps and Services. You further agree that JotNot shall not be held liable with respect to any loss and/or damage to your data or Content if you choose to withdraw consent. You may contact us for assistance at <a href="mailto:support@jotnot.com">support@jotnot.com</a>.</p>
<h3 id="data-retention-and-deletion">Data Retention and Deletion</h3>
<p>We will retain your information for as long as your account is active or as needed to provide you services.</p>
<p>You may delete or deactivate your JotNot account at anytime by contacting us at <a href="mailto:support@jotnot.com">support@jotnot.com</a>. Following termination of your account, we may retain your information (in part or in whole) in order to meet any legal obligations, resolve disputes, or meet any regulatory and reporting requirements for the timeframes stipulated by law and in order to be able to address customer service issues.</p>
<p>Accounts may be terminated and deleted after a certain period of inactivity, in accordance with the Terms of Use. Currently, that period is 2 years. Account deletion may include deletion of all user information, data and content.</p>
<h3 id="information-security">Information Security</h3>
<p>JotNot takes security seriously, and we make commercially reasonable efforts to prevent unauthorized disclosure of all personally identifiable information. To that end:</p>
<ul>
<li>We use SSL (Secure Sockets Layer), the industry standard encryption protocol, to encrypt communications.</li>
<li>We automatically encrypt all data and associated metadata stored in our servers under the Advanced Encryption Standard (AES).</li>
<li>We restrict access to employees and subcontractors who need access in order to provide our services. All employees and subcontractors are subject to contractual confidentiality clauses.</li>
<li>Our application is hosted on Google Cloud Platform, where physical access is strictly controlled by professional security staff using 24/7 video surveillance, laser beam intrusion-detection systems, biometric and custom keycard access controls, and other means. You can find additional information about Google Cloud Platform Security here: <a href="https://cloud.google.com/security/compliance" target="_blank" rel="noopener noreferrer">https://cloud.google.com/security/compliance</a>.</li>
</ul>
<h3 id="revisions-to-this-privacy-policy">Revisions to this Privacy Policy</h3>
<p>By using our Products and Services, you agree to this Privacy Policy. This is our entire and exclusive Privacy Policy and it supersedes any earlier version. We may change this Privacy Policy by posting a new version of this Privacy Policy on our website or in our Apps and Services, which is your responsibility to review. By continuing to access or use our Products and Services after those changes become effective, you agree to be bound by the revised Privacy Policy.</p>
<h3 id="contacting-jotnot">Contacting JotNot</h3>
<p>If you have any questions or concerns regarding this Privacy Policy, please contact us by email at <a href="mailto:support@jotnot.com">support@jotnot.com</a>.</p>
<p><em>Updated: May 25, 2018</em></p>

  </div>
);

export default Page;