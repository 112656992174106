import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Base64 } from 'js-base64';

import ErrorDisplay from './ErrorDisplay';
import Alert from './Alert';

import { ENDPOINTS, postData } from '../utils/fetching';
import { LOCAL_MODE, PAGES } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

class ChangeEmail extends Component {

  state = {
    isPosting: false,
    error: null,
    redirectToLogin: false,
    showAlert: false,
  };

  submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    if (LOCAL_MODE) {
      this.setState({ error: null, showAlert: true });
      return;
    }

    this.setState({ isPosting: true });
    postData(ENDPOINTS.changeEmail, formData)
      .then((json) => {
        this.setState({ error: null, isPosting: false, showAlert: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error, isPosting: false });
      });
  };

  handleAlert = (event) => {
    if (event) { event.preventDefault(); }
    this.setState({ showAlert: false, redirectToLogin: true });
  };

  render() {
    const { redirectToLogin, showAlert, isPosting, error } = this.state;
    console.log("state:", this.state);

    if (redirectToLogin) {
      return <Redirect to={PAGES.login} push />;
    }

    let oldEmail = "", newEmail = "", token = "";

    const { match } = this.props;
    if (match && match.params) {
      const { tokenAndEmails } = match.params;
      try {
        const base64Data = decodeURIComponent(tokenAndEmails);
        const jsonData = Base64.decode(base64Data);
        ({ token, oldEmail, newEmail } = JSON.parse(jsonData));
      }
      catch(error) {
        console.error(error);
        this.setState({ error });
      }
    }

    if (!oldEmail || !newEmail || !token) {
      const linkError = new Error("Malformed change-email link. Please click the link in your email again, or request a new link.");
      return (
        <section className="ChangeEmail section jnf-first">
          <div className="container jnf-narrow-center-wrapper">
            <ErrorDisplay errors={[error, linkError]} />
          </div>
        </section>
      );
    }

    return (
      <section className="ChangeEmail section jnf-first">
        <div className="container jnf-narrow-center-wrapper">
          <h1>Update Your Email</h1>
          <p>Please enter your password to verify that it's you and complete the update.</p>
          <form name="change-email-form" onSubmit={this.submitForm}>
            <div className="field">
              <label className="label" htmlFor="old_email">Current Email</label>
              <div className="control">
                <input className="input" type="email" name="old_email" value={oldEmail} autoComplete="off" required readOnly />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="new_email">New Email</label>
              <div className="control">
                <input className="input" type="email" name="new_email" value={newEmail} autoComplete="off" required readOnly />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="password">Password</label>
              <div className="control">
                <input className="input" type="password" name="password" placeholder="Enter your password" autoComplete="current-password" required />
              </div>
              <div className="help is-danger"><ErrorDisplay errors={[error]}/></div>
            </div>

            <div className="field">
              <div className="control">
                <input className="input" type="hidden" name="token" value={token} autoComplete="off" required readOnly />
              </div>
            </div>

            <button className="button is-primary" name="submit">Submit</button>

          </form>

          <Alert
            alertType='ChangeEmail_EmailUpdated'
            isVisible={showAlert}
            handleConfirm={this.handleAlert}
          />
          <LoadingIndicator isLoading={isPosting} />
        </div>
      </section>
    );
  }
}

export default ChangeEmail;
