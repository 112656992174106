import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';

import InboxListItem from './InboxListItem';

import { ENDPOINTS, getURL } from '../utils/fetching';
import { LOCAL_MODE } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

import appIcon from '../assets/images/app-icon.svg';
import sampleFaxData from '../assets/sampleReceivedFaxData5';


const FETCH_FAXES_PAGE_SIZE = 50;
const PAGINATION_ITEMS_PER_PAGE = 10;

const blankReceivedFaxState = Object.freeze({
  faxes: Object.freeze([]),
  cursor: null,
  more: false,
  pagination: Object.freeze({
    faxesDisplayed: Object.freeze([]),
    pageCount: 0,
    pageSelected: 0,
  }),
});

let cachedReceivedFaxState = { ...blankReceivedFaxState };


class InboxList extends Component {

  constructor(props) {
    super(props);
    this.state = Object.assign({ isLoading: false }, cachedReceivedFaxState);
    console.log("home state in constructor:", this.state);
    cachedReceivedFaxState = null;
  }

  componentWillUnmount() {
    console.log("unmount began");
    // the destructuring use below doesn't count for the no-unused-vars warning, so i'm disabling it for this line
    let isLoading; // eslint-disable-line no-unused-vars
    ({ isLoading, ...cachedReceivedFaxState } = this.state);
    console.log("cached home state at unmount:", cachedReceivedFaxState);
    this.clearTimer();
    console.log("unmount ended");
  }

  componentDidMount() {
    this.fetchFaxList();
  }

  timer = null;

  clearTimer = () => {
    console.log("clear timer called");
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    console.log("timer cleared");
  };

  setFaxTimer = () => {
    this.clearTimer();
    console.log("set timer called");
    this.timer = setTimeout(() => {
      console.log("timer fired");
      this.timer = null;
      this.fetchFaxList();
    }, 30000);  // 30 seconds
    console.log("timer set");
  };

  mergeFaxLists = (oldList, newList) => {

    if(!oldList || oldList.length === 0) {
      return newList;
    }

    if(!newList || newList.length === 0) {
      return oldList;
    }

    const oldestNewFaxMoment = moment.tz(newList[newList.length-1].received, 'UTC');
    console.log("oldestNewFaxMoment:", oldestNewFaxMoment);

    for(let oldInd = 0; oldInd < oldList.length; oldInd++) {
      const oldFaxMoment = moment.tz(oldList[oldInd].received, 'UTC');
      if(oldestNewFaxMoment.isAfter(oldFaxMoment)) {
        console.log("found older existing fax:", oldFaxMoment, "at index", oldInd);
        return newList.concat(oldList.slice(oldInd));
      }
    }

    return newList;
  };

  paginationUpdate = (newPageSelected, faxes) => {
    console.log("pagination update for selection", newPageSelected, "and faxes", faxes);
    const pageCount = Math.ceil(faxes.length / PAGINATION_ITEMS_PER_PAGE);
    const pageSelected = Math.max(0, Math.min(newPageSelected, pageCount-1));
    const start = pageSelected * PAGINATION_ITEMS_PER_PAGE;
    const end = (pageSelected + 1) * PAGINATION_ITEMS_PER_PAGE;
    const faxesDisplayed = faxes.slice(start, end);
    const pagination = { pageSelected, faxesDisplayed, pageCount };
    console.log("new pagination:", pagination);
    return pagination;
  };

  fetchFaxList = (startCursor=null) => {

    console.log("fetching faxes, cursor =", startCursor);

    const { errorCallback } = this.props;

    if (LOCAL_MODE) {
      this.setState((prevState) => {
        return {
          faxes: sampleFaxData,
          pagination: this.paginationUpdate(prevState.pagination.pageSelected, sampleFaxData),
          isLoading: false
        }
      });
      return;
    }

    let params = { page_size: FETCH_FAXES_PAGE_SIZE };
    if(startCursor) {
      params.start_cursor = startCursor;
    }

    console.log("fetch params:", params);

    this.setState({ isLoading: true });
    getURL(ENDPOINTS.receivedFax, params)
      .then((json) => {
        console.log("result from fetch fax list:", json);
        errorCallback(null);
        const shouldAppend = startCursor !== null;
        this.setState((prevState) => {
          // Default case: new state matches results from fetch
          let { results: faxes, cursor, more } = json;
          if(shouldAppend && prevState.faxes) {
            // Append case: we fetched with a cursor, so append faxes to end of existing fax list
            faxes = prevState.faxes.concat(json.results);
          } else if(!shouldAppend && prevState.faxes && prevState.faxes.length > FETCH_FAXES_PAGE_SIZE && cursor && more) {
            // We're refreshing a list that is longer than the amount we refreshed, so splice
            // new refreshed faxes onto tail of old faxes that weren't refreshed
            faxes =  this.mergeFaxLists(prevState.faxes, faxes);
            cursor = prevState.cursor;
            more = prevState.more;
          }

          console.log("new state for faxes:", { faxes, cursor, more });
          return {
            faxes, cursor, more,
            pagination: this.paginationUpdate(prevState.pagination.pageSelected, faxes),
            isLoading: false,
          };
        }, () => {
          this.setFaxTimer();
        });
      })
      .catch((error) => {
        errorCallback(error);
        this.setState({ isLoading: false })
        this.setFaxTimer();
      });
  };

  fetchSingleFax = (faxUrlsafeKey) => {
    console.log("fetching single fax:", faxUrlsafeKey);

    if (LOCAL_MODE) {
      this.setState((prevState) => {
        return {
          faxes: sampleFaxData,
          pagination: this.paginationUpdate(prevState.pagination.pageSelected, sampleFaxData),
          isLoading: false
        }
      });
      return Promise.resolve(sampleFaxData.find((fax) => {
        return fax.urlsafe_key === faxUrlsafeKey;
      }));
    }

    return getURL(ENDPOINTS.receivedFax + "/" + faxUrlsafeKey)
      .then((json) => {
        console.log("result from fetch single fax:", json);
        this.setState((prevState) => {
          const faxes = { prevState };
          const faxInd = !faxes ? -1 : faxes.findIndex((fax) => {
            return fax.urlsafe_key === faxUrlsafeKey;
          });
          if (faxInd >= 0) {
            faxes[faxInd] = json;
            return { faxes };
          }
        });
        return json;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  handlePageClick = (p) => {
    this.setState((prevState) => {
      return { pagination: this.paginationUpdate(p.selected, prevState.faxes) };
    }, () => {
      const { pagination, cursor, more } = this.state;
      if ((pagination.pageSelected === pagination.pageCount-1) && cursor && more) {
        this.fetchFaxList(cursor);
      }
    });
  };

  deleteFaxCallback = (urlsafe_key) => {
    this.setState((prevState) => {
      const { faxes } = prevState;
      const newFaxes = faxes.filter(f => f.urlsafe_key !== urlsafe_key);
      return {
        faxes: newFaxes,
        pagination: this.paginationUpdate(prevState.pagination.pageSelected, newFaxes)
      };
    });
  };

  render() {
    const { isLoading, pagination } = this.state;
    const { deleteFaxCallback, fetchSingleFax, handlePageClick } = this;

    return (
      <div className="InboxList">
        <InboxListPage faxes={pagination.faxesDisplayed} {...{deleteFaxCallback, fetchSingleFax, isLoading}} />
        <LoadingIndicator {...{ isLoading }} />

        { pagination.pageCount > 1 &&
          <section className="section ReactPaginate">
            <div className="container">
              <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'pagination-ellipsis'}
                pageCount={pagination.pageCount}
                initialPage={pagination.pageSelected}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName={'pagination-list'}
                disabledClassName={'jnf-pagination-link-disabled'}
                pageClassName={'pagination-link'}
                activeClassName={'is-current'}
                previousClassName={'pagination-previous'}
                nextClassName={'pagination-next'}
              />
            </div>
          </section>
        }
      </div>
    );
  }
}


function InboxListPage({ faxes, deleteFaxCallback, fetchSingleFax, isLoading }) {
  console.log("FAXES", faxes);
  const listItems = (faxes.length === 0 && !isLoading
    ? <li className="has-text-centered">
        <img src={appIcon} alt="App Icon" className="image is-96x96 jnf-app-icon" />
        <p className="has-text-weight-semibold is-size-5">No received faxes</p>
      </li>
    : faxes.map(fax =>
        <InboxListItem key={fax.urlsafe_key} {...{fax, deleteFaxCallback, fetchSingleFax}} />
      )
  );

  return (
    <section className="InboxListPage section">
      <div className="container">
        <h2>Faxes</h2>
        <ul>
          {listItems}
        </ul>
      </div>
    </section>
  );
}

export default InboxList;
