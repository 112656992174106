import React from 'react';
import ReactGA from 'react-ga';

import ModalCoverPage from './ModalCoverPage';
import SenderInfoForm, { senderInfoKeys, CoverPageField, getSenderInfo, postSenderInfo } from './SenderInfoForm';

class NewFaxCoverPageForm extends React.Component {

  state = {
    isVisibleModalCoverPage: false,
    isPosting: false,
    isGetting: false,
    isSenderDirty: false,
    error: null,
  };

  componentDidMount() {
    this.setState({ isGetting: true });
    getSenderInfo()
      .then((serverSenderInfo) => {
        let shouldLoadSenderInfo = true;
        let matchesServer = true;
        const { coverPage } = this.props;
        senderInfoKeys.forEach((key) => {
          if(coverPage[key]) {
            shouldLoadSenderInfo = false;
          }
          if(coverPage[key] !== serverSenderInfo[key]) {
            matchesServer = false;
          }
        });
        if(shouldLoadSenderInfo) {
          this.props.handleCoverPageChange(serverSenderInfo);
        }
        this.setState({ isGetting: false, error: null, isSenderDirty: (!matchesServer && !shouldLoadSenderInfo) });
      })
      .catch((error) => {
        ReactGA.exception({ description: error.message });
        this.setState({ isGetting: false, error });
      });
  }

  handleToggleModalCoverPage = (event) => {
    if (!this.state.isVisibleModalCoverPage) {
      ReactGA.modalview('/fax/cover');
    }
    this.setState((prevState) => ({ isVisibleModalCoverPage: !prevState.isVisibleModalCoverPage }));
  };

  handleChange = (event) => {
    this.props.handleCoverPageChange({ [[event.currentTarget.name]]: event.currentTarget.value });
    if (senderInfoKeys.includes(event.currentTarget.name)) {
      this.setState({ isSenderDirty: true });
    }
  };

  handleSaveSenderInfo = (event) => {
    event.preventDefault();
    this.setState({ isPosting: true });
    postSenderInfo(this.props.coverPage)
      .then((senderInfo) => {
        this.props.handleCoverPageChange(senderInfo);
        this.setState({ isPosting: false, error: null, isSenderDirty: false });
      })
      .catch((error) => {
        ReactGA.exception({ description: error.message });
        this.setState({ isPosting: false, error });
      });
  };

  render() {

    const { isVisibleModalCoverPage, isSenderDirty } = this.state;

    const { coverPage, recipientFax, pageCount } = this.props;

    const {
      recipientName,
      subject, message,
    } = coverPage;

    const { handleToggleModalCoverPage, handleChange, handleSaveSenderInfo } = this;

    return (
      <div className="NewFaxCoverPageForm">

        <h3>Recipient</h3>

        <CoverPageField
          jnfClassName="jnf-recipient-name"
          label="Name"
          name="recipientName"
          type="text"
          value={recipientName}
          handleChange={handleChange}
          autoComplete="section-recipient name"
        />

        <h3>Message</h3>

        <CoverPageField
          jnfClassName="jnf-subject"
          label="Subject"
          name="subject"
          type="text"
          value={subject}
          handleChange={handleChange}
          autoComplete="off"
        />

        <CoverPageField
          jnfClassName="jnf-subject"
          label="Message"
          name="message"
          type="textarea"
          value={message}
          handleChange={handleChange}
          autoComplete="off"
        />

        <div className="columns is-mobile">
          <div className="column is-6">
            <h3>
              Sender
            </h3>
          </div>
          <div className="column is-6 jnf-new-fax-save-sender-link">
            <div className={isSenderDirty ? "" : "jnf-new-fax-link-disabled"}>
              <a href="/" onClick={handleSaveSenderInfo}>Set as Default</a>
            </div>
          </div>
        </div>

        <SenderInfoForm {...coverPage} handleChange={handleChange}/>

        <div className="help subsection">Note: the cover page is free - it does not require any credits.</div>
        <div className="subsection has-text-centered">
          <button className="button" type="button" onClick={handleToggleModalCoverPage}>Preview Cover Page</button>
        </div>

        <ModalCoverPage {...{ coverPage, recipientFax, pageCount }} isVisible={isVisibleModalCoverPage} hasModal={true} handleCancel={handleToggleModalCoverPage} />
      </div>
    );
  }
}

export default NewFaxCoverPageForm;
