import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Base64 } from 'js-base64';

import ErrorDisplay from './ErrorDisplay';
import Alert from './Alert';

import { ENDPOINTS, postData } from '../utils/fetching';
import { LOCAL_MODE, PAGES } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

class ResetPassword extends Component {

  state = {
    isPosting: false,
    error: null,
    redirectToLogin: false,
    isVisiblePasswordUpdatedAlert: false,
  };

  submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const newPassword = formData.get("password");
    const confirmPassword = formData.get("confirm-password");
    if (!newPassword || !confirmPassword) {
      this.setState({ error: new Error("Please enter and confirm your password.") });
      return;
    }
    if (newPassword !== confirmPassword) {
      this.setState({ error: new Error("Passwords do not match.") });
      return;
    }

    if (LOCAL_MODE) {
      this.setState({ error: null, isVisiblePasswordUpdatedAlert: true });
      return;
    }

    this.setState({ isPosting: true });
    postData(ENDPOINTS.resetPassword, formData)
      .then((json) => {
        this.setState({ error: null, isPosting: false, isVisiblePasswordUpdatedAlert: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error, isPosting: false });
      });
  };

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({ isVisiblePasswordUpdatedAlert: false, redirectToLogin: true });
  };

  render() {
    const { redirectToLogin, isVisiblePasswordUpdatedAlert, isPosting, error } = this.state;
    const { hideAlert } = this;

    if (redirectToLogin) {
      return <Redirect to={PAGES.login} push />;
    }

    let email = "", token = "";
    const { match } = this.props;
    if (match && match.params) {
      const { tokenAndEmail } = match.params;
      try {
        const base64Data = decodeURIComponent(tokenAndEmail);
        const jsonData = Base64.decode(base64Data);
        ({token, email} = JSON.parse(jsonData));
      }
      catch(error) {
        console.error(error);
        this.setState({ error });
      }
    }

    if (!email || !token) {
      const linkError = new Error("Malformed password-reset link. Please click the link in your email again, or send a new password-reset email.");
      return (
        <section className="ResetPassword section jnf-first">
          <div className="container jnf-narrow-center-wrapper">
            <ErrorDisplay errors={[error, linkError]} />
          </div>
        </section>
      );
    }

    return (
      <section className="ResetPassword section jnf-first">
        <div className="container jnf-narrow-center-wrapper">

          <h1>Update Your Password</h1>
          <form name="reset-password-form" onSubmit={this.submitForm}>
            <div className="field">
              <label className="label" htmlFor="email">Email</label>
              <div className="control">
                <input className="input" type="email" name="email" value={email} autoComplete="username email" required readOnly />
              </div>
            </div>

            <div className="field">
              <div className="control">
                <input className="input" type="hidden" name="token" value={token} autoComplete="off" required readOnly />
              </div>
            </div>

            <div className="field">
              <label className="label" htmlFor="password">New Password</label>
              <div className="control">
                <input className="input" type="password" name="password" placeholder="Enter a new password" autoComplete="new-password" required />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor="confirm-password">Confirm Password</label>
              <div className="control">
                <input className="input" type="password" name="confirm-password" placeholder="Please re-enter your new password" autoComplete="new-password" required />
              </div>
              <div className="help is-danger"><ErrorDisplay errors={[error]} /></div>
            </div>
            <button className="button is-primary" name="submit">Submit</button>

          </form>

          <Alert
            alertType='ResetPassword_PasswordUpdated'
            isVisible={isVisiblePasswordUpdatedAlert}
            handleConfirm={hideAlert}
          />
          <LoadingIndicator isLoading={isPosting} />
        </div>
      </section>
    );
  }
}

export default ResetPassword;
