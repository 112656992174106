import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  NavLink,
  Redirect,
} from 'react-router-dom';

import Home from './Home';
import Inbox from './Inbox';
import NewFax from './NewFax';
import Store from './Store';
import Settings from './Settings';
import FaxNumberPage from './FaxNumberPage';

import Landing from './Landing';
import Login from './Login';
import Signup from './Signup';
import Page from './Page';
import Receipt from './Receipt';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ChangeEmail from './ChangeEmail';
import AccountVerified from './AccountVerified';

import { isAuthenticated, getURL, postData, ENDPOINTS } from '../utils/fetching.js';
import { APP_BASE_PATH, PAGES, LOCAL_MODE } from '../utils/constants.js';
import RouteTracker from '../utils/GoogleAnalytics.js';
import ScrollToTop from '../utils/ScrollToTop.js';

import JotNotLogo from '../assets/jotnot-logo-white.svg';
import Icon from '../assets/icons/Icon';

// Globally specify src for pdfjs service worker.
// Taken from: https://www.npmjs.com/package/react-pdf#browserify-and-others
import PDFJS from 'pdfjs-dist';
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;


class App extends Component {

  state = {
    email: null,
    defaultPaymentMethodID: null,
    credits: 0,
    creditsError: null,
    faxNumbers: null,
    faxNumbersError: null,
    defaultFaxNumberID: null,
    faxToEmail:false,
  };

  fetchUser = (localCredits) => { // localCredits are passed in from the Store page after a purchase completes
    if (localCredits) {
      const update = { credits: localCredits };
      this.setState(update);
      return Promise.resolve(Object.assign({}, this.state, update));
    }
    if (LOCAL_MODE) {
      const localState = {
        credits: 3, creditsError: null, email: "test@example.com",
        defaultPaymentMethodID: null,
        defaultFaxNumberID: "blah",
        faxToEmail: true,
        faxNumbers: [
          {
            urlsafe_key: "blah",
            fax_number: "+15551234567",
            reservation_status: "1",
            subscription_source: "0",
            expiration_date: "2020-01-01 21:24:10.847989",
            usage_period: {
              period_end: "2020-01-01 21:24:10.847989",
              pages_remaining: 33,
            }
          },
          {
            urlsafe_key: "blah2",
            fax_number: "+14441234567",
            reservation_status: "1",
            subscription_source: "1",
            expiration_date: "2020-01-01 21:24:10.847989",
            usage_period: {
              period_end: "2020-01-01 21:24:10.847989",
              pages_remaining: 120,
            }
          },
          {
            urlsafe_key: "blah3",
            fax_number: "+13331234567",
            reservation_status: "1",
            subscription_source: null,
            expiration_date: "2020-01-01 21:24:10.847989",
            usage_period: {
              period_end: "2020-01-01 21:24:10.847989",
              pages_remaining: 56,
            }
          },
        ],
      };
      this.setState(localState);
      return Promise.resolve(localState);
    }
    const userPromise = getURL(ENDPOINTS.user)
      .then((json) => {
        const update = {
          creditsError: null, credits: json.balance,
          email: json.email, defaultPaymentMethodID: json.default_payment_id,
          defaultFaxNumberID: json.default_fax_number,
          faxToEmail: json.fax_to_email,
        };
        this.setState(update);
        return Object.assign({}, this.state, update);
      })
      .catch((error) => {
        const update = { creditsError: error };
        this.setState(update);
        return Object.assign({}, this.state, update);
      });

    const numberPromise = getURL(ENDPOINTS.faxNumber)
      .then((json) => {
        const update = { faxNumbersError: null, faxNumbers: json.results };
        this.setState(update);
        return Object.assign({}, this.state, update);
      })
      .catch((error) => {
        const update = { faxNumbersError: error };
        this.setState(update);
        return Object.assign({}, this.state, update);
      });

    return Promise.all([userPromise, numberPromise])
      .then((values) => {
        return Object.assign({}, values[0], values[1]);
      });
  };

  updateUser = ({ defaultFaxNumberID, defaultPaymentMethodID, faxToEmail }) => {
    console.log("default fax number:", defaultFaxNumberID);
    console.log("default payment method:", defaultPaymentMethodID);

    if (defaultFaxNumberID === undefined && defaultPaymentMethodID === undefined && faxToEmail === undefined) {
      console.log("no data to update, quitting");
      return Promise.resolve(Object.assign({}, this.state));
    }

    if (LOCAL_MODE) {
      const update = { defaultFaxNumberID, defaultPaymentMethodID };
      this.setState(update);
      return Promise.resolve(Object.assign({}, this.state, update));
    }

    // Convert nulls to empty strings for server post
    defaultFaxNumberID = defaultFaxNumberID === null ? "" : defaultFaxNumberID;
    defaultPaymentMethodID = defaultPaymentMethodID === null ? "" : defaultPaymentMethodID;

    const formData = new FormData();
    if (defaultFaxNumberID !== undefined) {
      formData.append("default_fax_number", defaultFaxNumberID);
    }
    if (defaultPaymentMethodID !== undefined) {
      formData.append("default_payment_id", defaultPaymentMethodID);
    }
    if (faxToEmail !== undefined) {
      formData.append("fax_to_email", faxToEmail);
    }
    return postData(ENDPOINTS.user, formData, "PUT")
      .then((json) => {
        const update = {
          faxNumbersError: null,
          defaultFaxNumberID: json.default_fax_number,
          defaultPaymentMethodID: json.default_payment_id,
          faxToEmail: json.fax_to_email,
        };
        this.setState(update);
        return Object.assign({}, this.state, update);
      })
      .catch((error) => {
        const update = { faxNumbersError: error };
        this.setState(update);
        return Object.assign({}, this.state, update);
      });
  };

  clearUser = () => {
    this.setState({ email: null, defaultPaymentMethodID: null, credits: 0, creditsError: null, faxNumbers: null, defaultFaxNumberID: null, faxNumbersError: null, faxToEmail: false });
  };

  // NOTE: the outer <Route> component is there
  // to force isAuthenticated() to be re-evaluated whenever the path changes
  render() {
    return (
      <BrowserRouter basename={APP_BASE_PATH}>
        <ScrollToTop>
          <div id="jnf-routes-wrapper">
            <RouteTracker />
            <Switch>
              <Route exact path={PAGES.receipt} component={Receipt} />
              <Route exact path={PAGES.receipt} render={(props) => (
                <Receipt {...props} />
              )} />
              <Route render={(props) => {
                return (
                  <div className="App">
                    <Header isAuthenticated={isAuthenticated()} />
                    <Main
                      isAuthenticated={isAuthenticated()}
                      props={props}
                      state={this.state}
                      fetchUser={this.fetchUser}
                      clearUser={this.clearUser}
                      updateUser={this.updateUser}
                    />
                    <Footer />
                  </div>
                );
              }} />
            </Switch>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

function Header({ isAuthenticated }) {
  return (
    <header className={'Header ' + (isAuthenticated ? 'logged-in' : 'logged-out')}>
      {isAuthenticated ?
        <nav className="navbar is-primary">
          <div className="jnf-container-wrapper">
            <div className="container">
              <div className="jnf-navbar-wrapper">
                <div className="navbar-brand">
                  <NavLink className="jnf-logo-link" to={PAGES.home}>
                    <img className="jnf-logo-image" src={JotNotLogo} alt="JotNot logo" />
                  </NavLink>

                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.home}>
                    <i className="jnf-icon-home" />
                    <span className="is-hidden-mobile">Sent</span>
                  </NavLink>
                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.inbox}>
                    <Icon name="inbox" fill="#FFF" height="1.2rem" />
                    <span className="is-hidden-mobile">Inbox</span>
                  </NavLink>
                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.newFax}>
                    <i className="jnf-icon-fax" />
                    <span className="is-hidden-mobile">New Fax</span>
                  </NavLink>
                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.storeCredits}>
                    <i className="jnf-icon-store" />
                    <span className="is-hidden-mobile">Store</span>
                  </NavLink>
                </div>
                <div className="navbar-brand">
                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.settingsAccount}>
                    <i className="jnf-icon-settings" />
                    {/*<span className="is-hidden-mobile">Settings</span>*/}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
        :
        <nav className="navbar is-primary">
          <div className="jnf-container-wrapper">
            <div className="container">
              <div className="jnf-navbar-wrapper">
                <div className="navbar-brand">
                  <NavLink className="jnf-logo-link" to={PAGES.landing}>
                    <img className="jnf-logo-image" src={JotNotLogo} alt="JotNot logo" />
                  </NavLink>
                </div>
                <div className="navbar-brand">
                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.login}>Log In</NavLink>
                  <NavLink className="navbar-item" activeClassName="is-active" to={PAGES.signup}><div className="button jnf-login-button">Sign Up</div></NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>

      }
    </header>
  );
}

function Main({ isAuthenticated, props, state, fetchUser, clearUser, updateUser }) {
  return (
    <main className="Main">
      {isAuthenticated ?
        <Switch>
          <Route exact path={PAGES.home} render={(props) => (
            <Home {...state} {...props} {...{ fetchUser, clearUser, updateUser }} />
          )} />
          <Route exact path={PAGES.inbox} render={(props) => (
            <Inbox {...state} {...props} {...{ fetchUser, clearUser, updateUser }} />
          )} />
          <Route exact path={PAGES.newFax} render={(props) => (
            <NewFax {...state} {...props} {...{ fetchUser, clearUser, updateUser }} />
          )} />
          <Route path={PAGES.store} render={(props) => (
            <Store {...state} {...props} {...{ fetchUser, clearUser, updateUser }} />
          )} />
          <Route path={PAGES.settings} render={(props) => (
            <Settings {...state} {...props} {...{ fetchUser, clearUser, updateUser }} />
          )} />
          <Route exact path={PAGES.faxNumber + "/:faxNumberID"} render={(props) => (
            <FaxNumberPage {...state} {...props} {...{ fetchUser, clearUser, updateUser }} />
          )} />
          <Route exact path={PAGES.forgotPassword} component={ForgotPassword} />
          <Route exact path={PAGES.resetPassword + "/:tokenAndEmail"} component={ResetPassword} />
          <Route exact path={PAGES.changeEmail + "/:tokenAndEmails"} component={ChangeEmail} />
          <Route exact path={PAGES.accountVerified} component={AccountVerified} />
          <Route exact path={PAGES.termsOfUse} render={(props) => (
            <Page {...state} {...props} page='termsOfUse' />
          )} />
          <Route exact path={PAGES.privacyPolicy} render={(props) => (
            <Page {...state} {...props} page='privacyPolicy' />
          )} />
          <Redirect to={PAGES.home} />
        </Switch>
        :
        <Switch>
          <Route exact path={PAGES.signup} component={Signup} />
          <Route exact path={PAGES.login} component={Login} />
          <Route exact path={PAGES.landing} component={Landing} />
          <Route exact path={PAGES.forgotPassword} component={ForgotPassword} />
          <Route exact path={PAGES.resetPassword + "/:tokenAndEmail"} component={ResetPassword} />
          <Route exact path={PAGES.changeEmail + "/:tokenAndEmails"} component={ChangeEmail} />
          <Route exact path={PAGES.accountVerified} component={AccountVerified} />
          <Route exact path={PAGES.termsOfUse} render={(props) => (
            <Page {...state} {...props} page='termsOfUse' />
          )} />
          <Route exact path={PAGES.privacyPolicy} render={(props) => (
            <Page {...state} {...props} page='privacyPolicy' />
          )} />
          <Redirect to={{
            pathname: PAGES.login,
            state: { source: props.location },
          }} />
        </Switch>
      }
    </main>
  );
}

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="Footer footer">
      <div className="container">
        <p>©2018-{year} MobiTech 3000 LLC</p>
        <p>
          <Link to={PAGES.termsOfUse}>Terms of Use</Link> / <Link to={PAGES.privacyPolicy}>Privacy Policy</Link>
        </p>
        {/* <p><a href="https://www.jotnot.com/terms-of-use-app.html" target="_blank" rel="noopener noreferrer">Terms of Use</a></p> */}
        {/* <p><a href="https://www.jotnot.com/privacy-app.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p> */}
      </div>
    </footer>
  );
}

export default App;
