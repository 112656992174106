import React from 'react';
import { PhoneNumberType, AsYouTypeFormatter } from 'google-libphonenumber';

import countryCodes from '../assets/flags/countryCodes.json';
import { isAllowedRC, allowedRC, iconForRegion } from '../utils/regionCodes.js';


function parseAndReturnNumberInfo(newRC, newNumber, callback, phoneUtil, ignoreError) {
  let numberError = null;
  let isValidNumber = false;

  if (!isAllowedRC(newRC)) {
    numberError = new Error(`JotNot does not currently support faxing to region ${newRC}`);
    const fallbackRC = allowedRC(newRC);
    callback(fallbackRC, countryCodes[fallbackRC].code, "", numberError, isValidNumber);
    return;
  }

  let nbNumber = null;
  try {
    nbNumber = phoneUtil.parseAndKeepRawInput(newNumber, newRC);
  } catch (error) {
    numberError = error;
  }
  if (!nbNumber) {
    if (!numberError) {
      numberError = new Error(`Unknown error with fax number ${newNumber} for region ${newRC}`);
    }
    if (ignoreError) {
      numberError = null;
    }
    callback(newRC, countryCodes[newRC].code, newNumber, numberError, isValidNumber);
    return;
  }

  let regionCode = phoneUtil.getRegionCodeForNumber(nbNumber);
  if (regionCode === null || regionCode === undefined) {
    numberError = ignoreError ? null : new Error(`Fax number ${newNumber} is not valid for region ${newRC}`);
    callback(newRC, countryCodes[newRC].code, newNumber, numberError, isValidNumber);
    return;
  }

  if (!isAllowedRC(regionCode)) {
    numberError = ignoreError ? null : new Error(`JotNot does not currently support faxing to region ${regionCode}`);
    const fallbackRC = allowedRC(regionCode);
    callback(fallbackRC, countryCodes[fallbackRC].code, "", numberError, isValidNumber);
    return;
  }

  const countryCode = "" + nbNumber.getCountryCode();  // sometimes returns a number, forcing str
  const recipientFax = "" + nbNumber.getNationalNumber();  // sometimes returns a number, forcing str
  if (!phoneUtil.isValidNumber(nbNumber)) {
    numberError = ignoreError ? null : new Error(`Fax number ${recipientFax} is not valid for region ${regionCode}`);
  } else if (phoneUtil.getNumberType(nbNumber) === PhoneNumberType.SHARED_COST) {
    numberError = ignoreError ? null : new Error(`${recipientFax} (${regionCode}) is a shared cost number; we cannot fax to shared cost numbers`);
  } else {
    isValidNumber = true;
  }

  callback(regionCode, countryCode, recipientFax, numberError, isValidNumber);
}


function NewFaxNumber({ recipientFax, recipientRegionCode, handleChangeFaxNumber, phoneUtil }) {

  function selectChanged(event) {
    parseAndReturnNumberInfo(event.target.value, recipientFax, handleChangeFaxNumber, phoneUtil);
  }

  function inputChanged(event) {
    parseAndReturnNumberInfo(recipientRegionCode, event.target.value, handleChangeFaxNumber, phoneUtil, true);
  }

  function inputBlurred(event) {
    parseAndReturnNumberInfo(recipientRegionCode, event.target.value, handleChangeFaxNumber, phoneUtil);
  }

  function formattedNumber() {
    let formatter = new AsYouTypeFormatter(recipientRegionCode);
    let result = "";
    for (let ind = 0; ind < recipientFax.length; ind++) {
      result = formatter.inputDigit(recipientFax[ind]);
    }
    return result;
  }

  return (
    <div className="NewFaxNumber">
      <h2>Recipient</h2>

      <div className="jnf-fax-recipient">

        <div className="columns">
          <div className="column is-narrow jnf-country-code">

            <div className="field">
              <label className="label is-hidden-tablet" forhtml="recipientCountryCode">Country Code:</label>
              <div className="field has-addons">
                <div className="control">
                  <img className="jnf-country-flag-icon" src={iconForRegion(recipientRegionCode)} alt="Flag for recipient country" />
                </div>
                <div className="control">
                  <div className="select">
                    <select name="recipientCountryCode" value={recipientRegionCode} onChange={selectChanged} autoComplete="section-fax country">
                      {Object.entries(countryCodes).map(([regionCode, country]) =>
                        <option key={regionCode} value={regionCode} >
                          {country.name} (+{country.code})
                    </option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="column">

            <div className="field jnf-fax-number">
              <label className="label is-hidden-tablet" forhtml="recipientFax">Fax Number:</label>
              <div className="field">
                <div className="control">
                  <input className="input" type="text" name="recipientFax" placeholder="Enter fax number" value={formattedNumber()} autoFocus required onChange={inputChanged} onBlur={inputBlurred} autoComplete="section-fax fax tel-national" />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export { parseAndReturnNumberInfo };

export default NewFaxNumber;
