import React, { Component } from 'react';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import { isAndroid } from 'react-device-detect';

import ModalDocument from './ModalDocument';
import Alert from './Alert';

import { ENDPOINTS, postData } from '../utils/fetching';
import { LOCAL_MODE } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';
import { pluralize } from '../utils/misc';

import Icon from '../assets/icons/Icon';

const localTZ = moment.tz.guess();
const phoneUtil = PhoneNumberUtil.getInstance();


class InboxListItem extends Component {
  state = {
    isVisibleModalDocument: false,
    isVisibleAlertConfirmDeleteFax: false,
    isVisibleAlertFaxProcessing: false,
    isVisibleAlertFaxUnpaidFor: false,
    deleteError: null,
    isDeleting: false,
    isLoading: false,
    updateUsagePeriodError: null,
  };

  hideModal = (event) => {
    event.preventDefault();
    this.setState({
      isVisibleModalDocument: false,
      isVisibleAlertConfirmDeleteFax: false,
      isVisibleAlertFaxProcessing: false,
      isVisibleAlertFaxUnpaidFor: false,
      deleteError: null,
      updateUsagePeriodError: null,
    });
  };

  handleDocumentView = (event) => {

    if(!this.isDownloaded() || !this.isUsageChecked()) {
      event.preventDefault();
      this.setState({ isVisibleAlertFaxProcessing: true });
      return;
    }

    if(!this.isPaidFor()) {
      event.preventDefault();
      this.setState({ isVisibleAlertFaxUnpaidFor: true });
      return;
    }

    // show document
    if(isAndroid) {
      return;  // open in new tab
    } else {
      event.preventDefault();
      ReactGA.modalview('/inbox/document');
      this.setState({ isVisibleModalDocument: true });
    }
  };

  handleAlertConfirmDeleteFax = (event) => {
    event.preventDefault();
    this.setState({ isVisibleAlertConfirmDeleteFax: true });
  };

  deleteFax = (event) => {
    event.preventDefault();
    this.setState({ isVisibleAlertConfirmDeleteFax: false });
    const { fax, deleteFaxCallback } = this.props;

    if (LOCAL_MODE) {
      deleteFaxCallback(fax.urlsafe_key);
      return;
    }

    const singleFaxEndpoint = ENDPOINTS.receivedFax + '/' + fax.urlsafe_key;
    this.setState({ isDeleting: true });
    postData(singleFaxEndpoint, null, 'DELETE')
      .then((json) => {
        this.setState({ isDeleting: false });
        deleteFaxCallback(fax.urlsafe_key);
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isDeleting: false });
        this.setState({ deleteError: error });
      });
  };

  handleSubscriptionLapse = (event) => {
    event.preventDefault();
    this.setState({ isVisibleAlertFaxUnpaidFor: false });
    const { fax } = this.props;

    if (LOCAL_MODE) {
      console.log("setting usage period on fax");
      return;
    }

    const singleFaxEndpoint = ENDPOINTS.receivedFax + '/' + fax.urlsafe_key;
    this.setState({ isLoading: true, updateUsagePeriodError: null });
    postData(singleFaxEndpoint, null, 'PUT')
      .then((json) => {
        const timer = setInterval(() => {
          this.checkUsageStatus();
        }, 2000);
        this.setState({ faxTimer: timer });
      })
      .catch((error) => {
        this.setState({ isLoading: false, updateUsagePeriodError: error });
      });
  };

  checkUsageStatus = () => {
    this.props.fetchSingleFax(this.props.fax.urlsafe_key)
      .then((fax) => {
        if(fax.is_usage_checked === "True") {
          this.clearTimer();
          let updateUsagePeriodError = null;
          if(!fax.usage_period) {
            updateUsagePeriodError = Error("Unable to add fax to usage period; please try again or contact support for help.");
          } else if(fax.is_paid_for !== "True") {
            updateUsagePeriodError = Error("Not enough pages left in this usage period for this fax. Please purchase more pages, or contact support for help.");
          }
          this.setState({ isLoading: false, updateUsagePeriodError });
        }
      })
      .catch((error) => {
        this.clearTimer();
        this.setState({ isLoading: false, updateUsagePeriodError: error});
      });
  };

  clearTimer = () => {
    const { timer } = this.state;
    if(timer) {
      clearTimeout(timer);
      this.setState({ timer: null });
    }
  };

  isDownloaded = () => { return this.props.fax.is_downloaded === "True"; };
  isPaidFor = () => { return this.props.fax.is_paid_for === "True"; };
  isUsageChecked = () => { return this.props.fax.is_usage_checked === "True"; };
  hasBeenViewed = () => { return this.props.fax.has_been_viewed === "True"; };

  render() {
    const {
      isVisibleModalDocument,
      isVisibleAlertConfirmDeleteFax, isVisibleAlertFaxProcessing, isVisibleAlertFaxUnpaidFor,
      deleteError, isDeleting,
      isLoading, updateUsagePeriodError,
    } = this.state;

    const { num_pages, received, fax_from, media_url, download_url } = this.props.fax;

    const { hideModal, handleDocumentView, handleAlertConfirmDeleteFax, handleSubscriptionLapse, deleteFax } = this;

    const number = phoneUtil.parseAndKeepRawInput(fax_from, null);
    const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);

    const pagesText = pluralize(Number(num_pages), 'page');

    const receivedDate = moment.tz(received, 'UTC');
    const receivedDateText = receivedDate ? receivedDate.tz(localTZ).format('lll') : received;


    let linkAttributes = {
      href: media_url ? media_url : "/",  // "/" to avoid warnings about empty hrefs
      title: formattedNumber,
      onClick: handleDocumentView,
    };
    if (isAndroid) {
      linkAttributes.target = "_blank";
      linkAttributes.rel = "noopener noreferrer";
    }

    const filename = formattedNumber + '.pdf';
    console.log(download_url);

    return (
      <li className="InboxListItem media">
        <div className="media-content columns is-multiline jnf-fax-status-content">
          <span className="column is-narrow jnf-fax-number">
            <span><a {...linkAttributes}>from {formattedNumber}</a></span>
          </span>
          <span className="column jnf-recipient-and-pages">{pagesText}</span>
          <span className="column is-narrow jnf-fax-time">{receivedDateText}</span>
          { this.isPaidFor() && this.isDownloaded() &&
            <span className="column is-12 jnf-documents-and-actions">
              <span className="jnf-documents" /> {/* Empty span to push actions to right side */}
              <span className="jnf-actions">
                <a href={download_url} className="jnf-download-fax" download={filename}>
                  <span className="is-hidden-mobile">Download</span>
                  <Icon name="download" height="20"/>
                </a>
                <a href="/" className="jnf-delete-fax" onClick={handleAlertConfirmDeleteFax}>
                  <span className="is-hidden-mobile">Delete</span>
                  <i className="jnf-icon-delete" />
                </a>
                <LoadingIndicator isLoading={isDeleting} />
              </span>
            </span>
          }
          <LoadingIndicator isLoading={isLoading} />
        </div>

        <ModalDocument isVisible={isVisibleModalDocument} handleCancel={hideModal} src={media_url} downloadURL={download_url} name={formattedNumber} />

        <Alert
          alertType='InboxListItem_ConfirmDeleteFax'
          isVisible={isVisibleAlertConfirmDeleteFax}
          handleConfirm={deleteFax}
          handleCancel={hideModal}
        />
        <Alert
          alertType='InboxListItem_DeleteError'
          isVisible={deleteError ? true : false}
          handleConfirm={hideModal}
          messageDetail={deleteError ? deleteError.message : ''}
        />
        <Alert
          alertType='InboxListItem_FaxProcessing'
          isVisible={isVisibleAlertFaxProcessing}
          handleConfirm={hideModal}
        />
        <Alert
          alertType='InboxListItem_FaxUnpaidFor'
          isVisible={isVisibleAlertFaxUnpaidFor}
          handleConfirm={handleSubscriptionLapse}
          handleCancel={hideModal}
        />
        <Alert
          alertType='InboxListItem_UpdateUsageError'
          isVisible={updateUsagePeriodError ? true : false}
          handleConfirm={hideModal}
          messageDetail={updateUsagePeriodError ? updateUsagePeriodError.message : ''}
        />
      </li>
    );
  }
}

export default InboxListItem;
