import React, { Component } from 'react';
import { OutboundLink } from 'react-ga';

import {
  Route,
  Switch,
  NavLink,
  Redirect,
} from 'react-router-dom';

import SenderInfoForm, { getSenderInfo, postSenderInfo } from './SenderInfoForm';
import Alert from './Alert';
import ErrorDisplay from './ErrorDisplay';

import { postData, ENDPOINTS, isAuthenticated, fakeSetAuthenticated } from '../utils/fetching';
import { PAGES, LOCAL_MODE, APP_STORE_URLS } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

import appleAppStoreBadge from '../assets/images/apple-app-store-badge.svg';
import googlePlayStoreBadge from '../assets/images/google-play-store-badge.svg';

class SettingsPage extends Component {

  componentDidMount() {
    this.props.fetchUser && this.props.fetchUser();
  }

  render() {
    return (
      <div className="Settings">
        <section className="section jnf-first">
          <div className="container">
            <h1>Settings</h1>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-narrow">
                <aside className="menu jnf-settings-menu">
                  <ul className="menu-list jnf-settings-menu-list">
                    <li><NavLink to={PAGES.settingsAccount} activeClassName="is-active">Account</NavLink></li>
                    <li><NavLink to={PAGES.settingsCoverPage} activeClassName="is-active">Cover Page</NavLink></li>
                    <li><NavLink to={PAGES.settingsSupport} activeClassName="is-active">Support</NavLink></li>
                    <li><NavLink to={PAGES.settingsApps} activeClassName="is-active">More Apps by JotNot</NavLink></li>
                  </ul>
                </aside>
              </div>
              <div className="column jnf-settings-content">
                <Switch>
                  <Route exact path={PAGES.settingsCoverPage} component={SettingsCoverPage} />
                  <Route exact path={PAGES.settingsSupport} component={Support} />
                  <Route exact path={PAGES.settingsApps} component={Apps} />
                  <Route render={(props) => (
                    <Account {...this.props} />
                  )} /> {/*Everything else renders the account page*/}
                </Switch>
              </div>
            </div>
          </div>
        </section>

      </div>
    );
  }
};

class Account extends Component {

  state = {
    isPosting: false,
    isUpdatingUser: false,
    error: null,
    isVisibleChangeEmailForm: false,
    redirectToHome: false,
    isVisiblePasswordAlert: false,
    isVisibleChangeEmailAlert: false,
  };

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({
      isVisiblePasswordAlert: false,
      isVisibleChangeEmailAlert: false,
    });
  }

  submitLogout = (event) => {
    event.preventDefault();

    if (LOCAL_MODE) {
      fakeSetAuthenticated(false);
      this.props.clearUser && this.props.clearUser();
      this.setState({ error: null, redirectToHome: true });
      return;
    }

    this.setState({ isPosting: true });
    postData(ENDPOINTS.logout)
      .then((json) => {
        this.props.clearUser && this.props.clearUser();
        this.setState({ isPosting: false, error: null, redirectToHome: true });
      })
      .catch((error) => {
        console.error(error)
        this.setState({ isPosting: false, error });
        if (!isAuthenticated()) {
          this.props.clearUser && this.props.clearUser();
          this.setState({ redirectToHome: true });
        }
      });
  };

  changePassword = (event) => {
    event.preventDefault();

    const { email } = this.props;
    if (!email) {
      this.setState({ error: new Error("Email for account is missing. Please log in again, then retry.") });
      return;
    }

    if (LOCAL_MODE) {
      this.setState({ error: null, isVisiblePasswordAlert: true });
      return;
    }

    this.setState({ isPosting: true });
    const formData = new FormData();
    formData.append("email", email);
    postData(ENDPOINTS.forgotPassword, formData)
      .then((json) => {
        this.setState({ error: null, isPosting: false, isVisiblePasswordAlert: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error, isPosting: false });
      });
  };

  toggleEmailForm = (event) => {
    if (event) { event.preventDefault(); }
    this.setState((prevState) => ({ isVisibleChangeEmailForm: !prevState.isVisibleChangeEmailForm }));
  };

  changeEmail = (event) => {
    event.preventDefault();

    if (LOCAL_MODE) {
      this.setState({ error: null, isVisibleChangeEmailAlert: true });
      return;
    }

    this.setState({ isPosting: true });
    const formData = new FormData(event.target);
    postData(ENDPOINTS.requestEmailChange, formData)
      .then((json) => {
        this.setState({ error: null, isPosting: false, isVisibleChangeEmailForm: false, isVisibleChangeEmailAlert: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error, isPosting: false });
      });
  };

  handleFaxToEmailChange = (event) => {
    console.log("fax to email change");
    this.setState({ isUpdatingUser: true });
    console.log("checked value = ", event.target.checked);
    this.props.updateUser({ faxToEmail: event.target.checked })
      .then((json) => {
        this.setState({ isUpdatingUser: false });
      })
      .catch((error) => {
        this.setState({ isUpdatingUser: false });
      });
  };


  render() {
    const { email, faxToEmail } = this.props;
    console.log("faxToEmail:", faxToEmail);
    console.log("type of faxToEmail:", typeof faxToEmail);

    const { redirectToHome, isVisiblePasswordAlert, isVisibleChangeEmailAlert, isVisibleChangeEmailForm, isPosting, isUpdatingUser, error } = this.state;
    const { submitLogout, changePassword, toggleEmailForm, changeEmail, hideAlert, handleFaxToEmailChange } = this;

    if (redirectToHome) {
      return <Redirect to={PAGES.landing} push />;
    }

    return (
      <div className="Account content">

        <div className="subsection">
          <h2>Account</h2>
          <p>{"You’re using " + email + " to log in — this is also where we send newsletters, notifications, and all other emails."}</p>
          <div className="level">
            <button type="button" className="button" onClick={toggleEmailForm}>Change Email</button>
            <button type="button" className="button" onClick={changePassword}>Change Password</button>
            <button type="button" className="button is-danger is-outlined" onClick={submitLogout}>Log Out</button>
          </div>
        </div>

        <RequestEmailChangeForm isVisible={isVisibleChangeEmailForm} onSubmit={changeEmail} onCancel={toggleEmailForm} />

        <LoadingIndicator isLoading={isPosting} />
        <ErrorDisplay errors={[error]} type="bar" />

        { faxToEmail !== undefined &&
          <div className="subsection">
            <h4>Email Settings</h4>
            <div className="field">
              <input id="emailSwitch" type="checkbox" name="emailSwitch" className="switch is-rounded" checked={faxToEmail} onChange={handleFaxToEmailChange} />
              <label htmlFor="emailSwitch">Email me copies of received faxes</label>
            </div>
            <LoadingIndicator isLoading={isUpdatingUser} />
          </div>
        }

        <Alert
          alertType='Settings_PasswordResetRequestSent'
          isVisible={isVisiblePasswordAlert}
          handleConfirm={hideAlert}
        />
        <Alert
          alertType='Settings_EmailChangeRequestSent'
          isVisible={isVisibleChangeEmailAlert}
          handleConfirm={hideAlert}
        />
      </div>
    );
  }
}

function RequestEmailChangeForm({ onSubmit, onCancel, isVisible }) {
  return (
    isVisible
      ?
      <div className="content subsection">
        <form className="RequestEmailChangeForm" name="request-email-change-form" onSubmit={onSubmit}>
          <div className="field">
            <label className="label" htmlFor="email">Enter new email address:</label>
            <div className="control">
              <input className="input" type="email" name="email" placeholder="New email" autoComplete="username email" required />
            </div>
            <div className="help"></div>
          </div>
          <div className="level">
            <div className="level-left">
              <button className="button" type="button" onClick={onCancel}>Cancel</button>
            </div>
            <div className="level-right">
              <button className="button is-primary" type="submit">Change Email</button>
            </div>
          </div>
        </form>
      </div>
      :
      null
  );
}

class SettingsCoverPage extends Component {

  state = {
    senderInfo: {},
    isPosting: false,
    isGetting: false,
    isDirty: false,
    error: null,
  };

  componentDidMount() {
    this.getSenderInfo();
  }

  getSenderInfo = () => {
    this.setState({isGetting: true});

    getSenderInfo()
      .then((senderInfo) => {
        this.setState({ isGetting: false, error: null, senderInfo, isDirty: false });
      })
      .catch((error) => {
        this.setState({ isGetting: false, error });
      });
  };

  postSenderInfo = () => {
    this.setState({isPosting: true});

    postSenderInfo(this.state.senderInfo)
      .then((json) => {
        this.setState({ isPosting: false, error: null, senderInfo: json, isDirty: false, });
      })
      .catch((error) => {
        this.setState({ isPosting: false, error });
      });
  };

  handleSave = (event) => {
    event.preventDefault();
    this.postSenderInfo();
  };

  handleChange = (event) => {
    const update = {
      [[event.currentTarget.name]]: event.currentTarget.value,
    };
    this.setState((prevState) => {
      return { senderInfo: Object.assign({}, prevState.senderInfo, update), isDirty: true };
    });
  };

  render() {
    const { handleChange, handleSave } = this;
    const { senderInfo, isPosting, isGetting, isDirty, error } = this.state;

    return (
      <div className="SettingsCoverPage content">
        <h2>Sender Information for Cover Page</h2>
        <form onSubmit={handleSave}>
          <SenderInfoForm {...senderInfo} handleChange={handleChange} />
          <section className="section has-text-centered">
            <button className="button is-primary" type="submit" onClick={handleSave} disabled={!isDirty}>Save</button>
          </section>
          <LoadingIndicator isLoading={isPosting || isGetting} />
          <ErrorDisplay errors={[error]} type="bar" />
        </form>
      </div>
    );
  }
}

function Support() {
  return (
    <div className="Support content">
      <h2>Support</h2>
      <script type="text/javascript" src="http://assets.freshdesk.com/widget/freshwidget.js"></script>
      <style type="text/css" media="screen, projection">@import url(http://assets.freshdesk.com/widget/freshwidget.css); </style>
      <iframe title="Feedback Form" className="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://mobitech3000.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No" scrolling="no" height="500px" width="100%" frameBorder="0" >
      </iframe>
    </div>
  );
}

function Apps() {
  return (
    <div className="Apps content">
      <h2>JotNot Fax Mobile Apps</h2>
      <h3>iOS and Android</h3>
      <OutboundLink to={APP_STORE_URLS.iosFax} target="_blank" eventLabel="Fax on App Store">
        <img className="jnf-store-badge" src={appleAppStoreBadge} alt="Apple App Store badge" />
      </OutboundLink>

      <OutboundLink to={APP_STORE_URLS.androidFax} target="_blank" eventLabel="Fax on Play Store">
        <img className="jnf-store-badge" src={googlePlayStoreBadge} alt="Google Play Store badge" />
      </OutboundLink>

      <hr />

      <h2>Other Apps by JotNot</h2>
      <h3>JotNot Scanner Pro</h3>
      <OutboundLink to={APP_STORE_URLS.iosScannerPro} target="_blank" className="app-store-badge" eventLabel="Scanner on App Store">
        <img className="jnf-store-badge" src={appleAppStoreBadge} alt="Apple App Store badge" />
      </OutboundLink>
      <OutboundLink to={APP_STORE_URLS.androidScannerPro} target="_blank" className="app-store-badge" eventLabel="Scanner on Play Store">
        <img className="jnf-store-badge" src={googlePlayStoreBadge} alt="Google Play Store badge" />
      </OutboundLink>

      <h3>JotNot Invoice</h3>
      <OutboundLink to={APP_STORE_URLS.iosInvoice} target="_blank" className="app-store-badge" eventLabel="Invoice on App Store">
        <img className="jnf-store-badge" src={appleAppStoreBadge} alt="Apple App Store badge" />
      </OutboundLink>

    </div>
  );
}

export default SettingsPage;
