import React, { Component } from 'react';
import { Base64 } from 'js-base64';

import Modal from './Modal';
import ModalFooter from './ModalFooter';

import { ENDPOINTS, getURL } from '../utils/fetching.js';
import { LOCAL_MODE } from '../utils/constants.js';
import { nameFromURL } from '../utils/documents.js';
import LoadingIndicator from '../utils/LoadingIndicator';
import ErrorDisplay from './ErrorDisplay';


class ModalDocument extends Component {

  state = {
    isFetchingDoc: false,
    localURL: null,
    fetchError: null,
  };

  componentDidMount() {
    if(this.props.isVisible) {
      this.fetchDocument();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.isVisible && !prevProps.isVisible) {
      this.fetchDocument();
    }
  }

  fetchDocument = () => {
    if (this.state.isFetchingDoc) {
      return;
    }

    const protocol = new URL(this.props.src).protocol;
    if(protocol === 'file' || protocol === 'blob') {
      this.setState({ localURL: this.props.src });
      return;
    }

    this.setState({ isFetchingDoc: true, localURL: null });

    return fetch(this.props.src, {
      credentials: 'same-origin',
      method: 'GET',
    }).then((response) => {
      return response.blob();
    }).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      console.log("blob url:", url);
      this.setState({ isFetchingDoc: false, localURL: url });
    }).catch((error) => {
      console.log(error);
      this.setState({ isFetchingDoc: false, fetchError: error });
    });
  };

  printContent = () => {
    document.getElementById("jnf-embedded-pdf").contentWindow.print();
  };

  render() {

    const { name, src, isVisible, handleCancel, downloadURL } = this.props;
    if (!isVisible) { return null; }

    const { localURL, fetchError } = this.state;
    const { printContent } = this;

    const printModalStyle = (
      <style type="text/css" media="print">
        {`
        .jnf-document-page {
          //padding-top: 129.412% !important;
          //margin: 0 !important;
        }
        #jnf-embedded-pdf {
          border: none !important;
        }
        .jnf-print-pdf-message {
          display: block !important;
          text-align: center !important;
          margin-top: 50%;
        }
      `}
      </style>
    );

    let fname = name || nameFromURL(src);
    if (!fname) {
      fname = "(Missing filename)";
    }

    const docDiv = (
      <div className="ModalDocument">
        <div className="jnf-document-page">
          {localURL
            ? <iframe id="jnf-embedded-pdf" className="jnf-document-page-iframe" title="document-page" src={localURL}></iframe>
            : <LoadingIndicator isLoading={true} />
          }
        </div>
        <div className="help is-danger"><ErrorDisplay errors={[fetchError]}/></div>
        {printModalStyle}
      </div>
    );

    const footer = <ModalFooter {...{ handleCancel, printContent, downloadURL }} filename={fname} />

    return (
      <Modal {...{ handleCancel, footer }} title={fname}>
        {docDiv}
      </Modal>
    );
  }
}

export default ModalDocument;
