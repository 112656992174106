import React from 'react';
import { Link } from 'react-router-dom';

//import backgroundImage from '../assets/images/hero-background.jpg';
import newFaxPhoneImage from '../assets/images/new-fax-iphone-x.png';
//import newFaxImage from '../assets/images/screenshot-new-fax-macbook.png';
import screenshotNewFaxImage from '../assets/images/screenshot-new-fax-2.png';
import appleAppStoreBadge from '../assets/images/apple-app-store-badge.svg';
import googlePlayStoreBadge from '../assets/images/google-play-store-badge.svg';

import { PAGES, APP_STORE_URLS } from '../utils/constants.js';

const LandingPage = () => (
  <div className="Landing">

    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column content is-6">
              <h1>
                Send and receive faxes online
              </h1>
              <h2>
                Fast. Convenient. Reliable.
              </h2>
              <br />
              <Link className="button is-primary is-large" to={PAGES.signup}>Get Started</Link>
              <p>
                <br />or <Link className="" to={PAGES.login}>Log In</Link>
              </p>
            </div>

            {/*
            <div className="column">
              <img src={newFaxImage} alt="New fax screenshot"/>
            </div>
            */}
            <div className="browser">
              <div className="browser-header">
                <div className="browser-box">
                  <div className="browser-button browser-button-red"></div>
                  <div className="browser-button browser-button-yellow"></div>
                  <div className="browser-button browser-button-green"></div>
                </div>
                <div className="browser-search"></div>
                <div className="browser-box"></div>
              </div>
              <div className="browser-content">
                <img src={screenshotNewFaxImage} alt="New fax screenshot" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="content">
          <h2>Benefits</h2>
          <ul>
            <li>Send and receive faxes from your browser. Don't waste time and money going to a store!</li>
            <li>Fast, easy, and reliable faxing.</li>
            <li>Simple and transparent pricing.</li>
            <li>Choose your own local (or remote) number.</li>
          </ul>
        </div>
      </div>
    </section>


    <section className="section">
      <div className="container">
        <div className="content">
          <h2>
            Fax on the go with our iOS and Android apps
          </h2>

          <div className="columns">

            <div className="column">
              <ul>
                <li>Send and receive faxes anywhere, from any device</li>
                <li>Mobile apps include built-in scanner</li>
              </ul>
              <br /><br />
              <a href={APP_STORE_URLS.iosFax} target="_blank" rel="noopener noreferrer">
                <img className="jnf-store-badge" src={appleAppStoreBadge} alt="Apple App Store badge" />
              </a>
              <br /><br />
              <a href={APP_STORE_URLS.androidFax} target="_blank" rel="noopener noreferrer">
                <img className="jnf-store-badge" src={googlePlayStoreBadge} alt="Google Play Store badge" />
              </a>
            </div>
            <div className="column">
              <img src={newFaxPhoneImage} alt="New fax screenshot" />
            </div>
          </div>

        </div>
      </div>
    </section>


    <section className="section">
      <div className="container">
        <div className="content">
          <h2>Pricing</h2>
          <p>JotNot Fax has a subscription option for getting a fax number and
            sending and receiving faxes.  If you only need to send faxes,
            there is a convenient and cost-effective pay-as-you-go option instead.</p>

          <h3>Subscription Plans</h3>
          <p>Each plan comes with a fax number and bundled
            pages that can be used for sending and receiving. Each plan can be purchased on either a monthly or
            annual basis, with a two-month discount for annual plans.</p>
          <ul>
            <li><b>Standard plan</b> ($14.99 monthly, $149.99 annually): includes 100 pages each month</li>
            <li><b>Premium plan</b> ($39.99 monthly, $399.99 annually): includes 300 pages each month</li>
            <li><b>Business plan</b> ($99.99, monthly, $999.99 annually): includes 1,000 pages each month</li>
          </ul>
          <p>When you subscribe, you will be given a choice of fax number from a list of available numbers in any U.S. or Canada area code.</p>
          <p>Subscriptions auto-renew unless you cancel more than 24 hours before the renewal date.  If you use more
            than the number of pages in a given month, you can add extra pages to that month by buying credits. Credits
            can be converted to pages at a rate of 5 pages per credit and are priced as below.</p>

          <h3>Pay-as-you-go (Sending only)</h3>
          <p>Pay-as-you-go is only for sending faxes, and you don't get your own fax number. We charge credits for every fax you
            send.  Without a subscription, sending a fax costs 1 credit for every 5 pages. For example:</p>
          <ul>
            <li>A fax with 1-5 pages costs 1 credit</li>
            <li>A fax with 6-10 pages costs 2 credits</li>
            <li>A fax with 11-16 pages costs 3 credits, and so on</li>
          </ul>
          <p>We provide free fax cover pages -- cover pages do not count towards the page count for the purposes of
            calculating the credits needed for a fax.</p>
          <p>You can purchase credits in packs of 3, 10, 20, or 100
            credits. Depending on which pack you choose, credits cost between 80¢ and $1.33 per credit, with larger packs
            offering cheaper pricing.</p>
          {/*
          <p>Packs of 3, 10, 20, and 100 credits are available for purchase. Larger packs offer cheaper pricing per credit.</p>
          <ul>
            <li>3 credit pack: $3.99 ($1.33 per credit, or as low as 27¢ per page)</li>
            <li>10 credit pack: $9.99 ($1.00 per credit, or as low as 20¢ per page)</li>
            <li>20 credit pack: $17.99 (90¢ per credit, or as low as 18¢ per page)</li>
            <li>100 credit pack: $79.99 (80¢ per credit, or as low as 16¢ per page)</li>
          </ul>
          */}
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container has-text-centered">
        <Link className="button is-primary is-large" to={PAGES.signup}>Sign Up</Link>
      </div>
    </section>

  </div>

)

export default LandingPage;
