const CREDIT_PACKS = Object.freeze({
  "com.mobitech3000.JotNotFaxWeb.FAXCREDIT.3": Object.freeze({
    name: '3 Fax Credits Pack',
    credits: 3,
    price: 3.99,
    //revenue: 3.57,
    perCredit: 1.33,
    message: ''
  }),
  "com.mobitech3000.JotNotFaxWeb.FAXCREDIT.10": Object.freeze({
    name: '10 Fax Credits Pack',
    credits: 10,
    price: 9.99,
    //revenue: 9.40,
    perCredit: 1.00,
    message: 'Save 25% off 3 fax credits'
  }),
  "com.mobitech3000.JotNotFaxWeb.FAXCREDIT.20": Object.freeze({
    name: '20 Fax Credits Pack',
    credits: 20,
    price: 17.99,
    //revenue: 17.17,
    perCredit: 0.90,
    message: ''
  }),
  "com.mobitech3000.JotNotFaxWeb.FAXCREDIT.100": Object.freeze({
    name: '100 Fax Credits Pack',
    credits: 100,
    price: 79.99,
    //revenue: 77.37,
    perCredit: 0.80,
    message: ''
  })
});

const getTotalCredits = (cart) => {
  let credits = 0;
  for (let packid in cart) {
    credits += CREDIT_PACKS[packid].credits * cart[packid];
  }
  return credits;
};

const getTotalPrice = (cart) => {
  let price = 0;
  for (let packid in cart) {
    price += CREDIT_PACKS[packid].price * cart[packid];
  }
  return price;
};

export {CREDIT_PACKS, getTotalPrice, getTotalCredits};
