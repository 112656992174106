import React, { Component } from 'react';

import ErrorDisplay from './ErrorDisplay';
import Alert from './Alert';

import { ENDPOINTS, postData } from '../utils/fetching';
import { LOCAL_MODE } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

class ForgotPassword extends Component {

  state = {
    isPosting: false,
    error: null,
    isVisibleEmailAlert: false,
  };

  submitForm = (event) => {
    event.preventDefault();

    if (LOCAL_MODE) {
      this.setState({ error: null, isVisibleEmailAlert: true });
      return;
    }

    this.setState({ isPosting: true });
    const formData = new FormData(event.target);
    postData(ENDPOINTS.forgotPassword, formData)
      .then((json) => {
        this.setState({ error: null, isPosting: false, isVisibleEmailAlert: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ error, isPosting: false });
      });
  };

  hideAlert = (event) => {
    event.preventDefault();
    this.setState({ isVisibleEmailAlert: false });
  };

  render() {
    const { isVisibleEmailAlert, isPosting, error } = this.state;
    const { hideAlert } = this;

    return (
      <section className="ForgotPassword section jnf-first">
        <div className="container jnf-narrow-center-wrapper">
          <h1>Reset Your Password</h1>
          <p>Please enter the email address for your JotNot Fax account. We'll email you a link to reset your password.</p>
          <form name="reset-password-form" onSubmit={this.submitForm}>

            <div className="field">
              <label className="label" htmlFor="email-input">Email</label>
              <div className="control">
                <input className="input" type="email" name="email" id="email-input" placeholder="Enter Email Address" autoComplete="username email" required />
              </div>
              <div className="help is-danger"><ErrorDisplay errors={[error]} /></div>
            </div>
            <button className="button is-primary" name="submit">Submit</button>

          </form>
          <Alert
            alertType='ForgotPassword_EmailSent'
            isVisible={isVisibleEmailAlert}
            handleConfirm={hideAlert}
          />
          <LoadingIndicator isLoading={isPosting} />
        </div>
      </section>
    );
  }
}

export default ForgotPassword;
