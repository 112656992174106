import React, { Component } from 'react';

import StorePromoForm from './StorePromoForm';
import { CREDIT_PACKS } from '../utils/creditPacks';
import SUBSCRIPTIONS from '../utils/subscriptions';


class StoreShoppingCart extends Component {

  render() {

    const {
      cartList, cartSummaryText, footerPar, showQuantity,
      promoCode, promoText, requireSubscriptionPromo,
      cartPriceText, promoPriceText,
      totalText, totalPriceText,
      handleApplyPromoCompleted,
    } = this.props;

    const subTotalText = (promoText && promoPriceText) ? "Subtotal" : "Total";
    const leftColClass = showQuantity ? "column is-3" : "column is-7";
    const leftTwoColClass = showQuantity ? "column is-9" : "column is-7";
    // const totalText = showQuantity ? "Total" :
    //   <p>Total<br/>&nbsp;&nbsp;for first 3 months:<br/>&nbsp;&nbsp;thereafter:</p>;

    // const totalPriceText = showQuantity ? quotedPriceText :
    //   <p><br/>{quotedPriceText}<br/>{cartPriceText}</p>;

    return (
      <div className="StoreShoppingCart">
        <h2>Shopping Cart</h2>
        <div className="columns is-mobile is-variable is-3 jnf-cart-header">
          <span className={leftColClass}>Item</span>
          {showQuantity && <span className="column is-6 has-text-centered">Quantity</span>}
          <span className="column has-text-centered">Price</span>
        </div>
        <div>
          {cartList}
          <div className="columns is-mobile is-variable is-3 jnf-cart-total">
            <span className={leftColClass}>{subTotalText}</span>
            {showQuantity && <span className="column is-6 has-text-centered jnf-cart-total-credits">{cartSummaryText}</span>}
            <span className="column has-text-centered jnf-cart-total-cost">{cartPriceText}</span>
          </div>
          {(promoCode && promoText && promoPriceText)
            ? (
                <div>
                  <div className="columns is-mobile is-variable is-3 jnf-cart-item" key="promo">
                    <span className={leftTwoColClass}>{promoText}</span>
                    <span className="column has-text-centered">-{promoPriceText}</span>
                  </div>
                  <div className="columns is-mobile is-variable is-3 jnf-cart-total">
                    <span className={leftColClass}>{totalText}</span>
                    {showQuantity && <span className="column is-6 has-text-centered jnf-cart-total-credits">{cartSummaryText}</span>}
                    <span className="column has-text-centered jnf-cart-total-cost">{totalPriceText}</span>
                  </div>
                </div>
              )
            : null
          }

          <div className="jnf-shopping-cart-footer content">
            {footerPar}
            {!promoCode ? <StorePromoForm {...{handleApplyPromoCompleted, requireSubscriptionPromo}} /> : null}
          </div>

        </div>
      </div>
    );
  }
}

function StoreShoppingCartCreditPack({ packid, count, handleIncrementPack, handleDecrementPack }) {
  return (
    <div className="columns is-mobile is-variable is-3 jnf-cart-item">
      <span className="column is-3">{CREDIT_PACKS[packid].credits} Pack {/*(${CREDIT_PACKS[packid].price.toFixed(2)})*/}</span>
      <span className="column is-6 jnf-quantity-buttons">
        <span className="field has-addons">
          <div className="control">
            <button className="button jnf-cart-button" data-packid={packid} onClick={handleDecrementPack}> &#x2012; </button>
          </div>
          <div className="control">
            <span className="button jnf-item-count">{count}</span>
          </div>
          <div className="control">
            <button className="button jnf-cart-button" data-packid={packid} onClick={handleIncrementPack}> + </button>
          </div>
        </span>
      </span>
      <span className="column has-text-centered">${(count * CREDIT_PACKS[packid].price).toFixed(2)}</span>
    </div>
  );
}

function StoreShoppingCartSubscription({ subid, faxNumber }) {
  const sub = SUBSCRIPTIONS[subid];
  const durationText = sub.duration === "year" ? " / yr" : " / mo";
  return (
    <div className="StoreShoppingCartSubscription">
      <div className="columns is-mobile is-variable is-3 jnf-cart-item">
        <span className="column is-7">
          <p>{sub.name}<br/>{sub.pages} pgs / mo</p>
        </span>
        <span className="column has-text-centered">${sub.price.toFixed(2)}{durationText}</span>
      </div>
    </div>
  );
}

export { StoreShoppingCartCreditPack, StoreShoppingCartSubscription };

export default StoreShoppingCart;
