import React, { Component } from 'react';
import moment from 'moment-timezone';
import PDFJS from 'pdfjs-dist';

import Modal from './Modal';
import ModalCoverPage from './ModalCoverPage';
import ModalFooter from './ModalFooter';
import ErrorDisplay from './ErrorDisplay';

function ModalFaxReceipt({ fax, isVisible, displayModal, handleCancel }) {

  if (!isVisible) { return null; }

  const status = parseInt(fax.status, 10);
  let statusMessage, faxTime, faxTimeMessage;
  const localTZ = moment.tz.guess();
  if (status === 4) {
    statusMessage = 'Status: Success – Fax successfully sent';
    faxTime = moment.tz(fax.completetime, 'America/New_York');
    faxTimeMessage = 'Completed: ' + faxTime.tz(localTZ).format('lll');
  } else if (status < 4) {
    statusMessage = 'Status: Pending';
    faxTime = moment.tz(fax.posted, 'UTC');
    faxTimeMessage = 'Started: ' + faxTime.tz(localTZ).format('lll');
  } else if (status > 4) {
    statusMessage = 'Status: Error';
    faxTime = moment.tz(fax.posted, 'UTC');
    faxTimeMessage = 'Attempted: ' + faxTime.tz(localTZ).format('lll');
  }

  const printModalStyle = (
    <style type="text/css" media="print">
      {`
      .jnf-print-only { display: block !important; }
      .ModalFaxReceipt { border: none !important; }
      .jnf-cover-page {
        padding-top: 101% !important;
        margin: 0 11.364% !important;
      }
      .jnf-cover-page-iframe { border: 1px solid #888 !important; }
      `}
    </style>
  );

  const printContent = () => {
    window.print();
  };

  const faxReceiptContent = (
    <div className="ModalFaxReceipt">
      <h1 className="jnf-print-only">Fax Receipt</h1>
      <h2>
        <strong>{statusMessage}</strong>
      </h2>

      <div className="jnf-fax-receipt-details">
        <p className="columns">
          <span className="column"><strong>{faxTimeMessage}</strong></span>
        </p>
        <p className="columns">
          <span className="column">Recipient: {fax.recipient_name}</span>
          <span className="column">Pages: {fax.xmitpages}</span>
        </p>
        <p className="columns">
          <span className="column">Number: +{fax.recipient_country_code} {fax.recipient_fax}</span>
          <span className="column">Duration: {fax.xmittime}</span>
        </p>
        <p className="columns">
          <span className="column">Fax ID: {fax.fax_key}</span>
        </p>
      </div>

      {fax.cover_sheet
        ? <ModalCoverPage coverPageURL={fax.cp_url} isVisible={true} hasModal={false} />
        : <PdfFirstPage src={fax.file_url} />
      }
      {printModalStyle}
    </div>
  );

  const footer = <ModalFooter {...{ handleCancel, printContent }} />

  return (
    <Modal {...{ handleCancel, footer }} title="Fax Receipt">
      {faxReceiptContent}
    </Modal>
  );
}


class PdfFirstPage extends Component {

  state = { error: null };

  componentDidMount() {
    if (!this.props.src) {
      this.setState({ error: new Error('Error: Document missing.') });
      return;
    }

    PDFJS.getDocument(this.props.src).promise.then(pdf => {
      // pdf page numbering starts at 1
      pdf.getPage(1).then((page) => {
        const viewport = page.getViewport({ scale: 1, });
        const canvas = document.getElementById("jnf-view-pdf-first-page-canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page.render(renderContext);
      });
    })
    .catch(error => {
      console.log(error);
      this.setState({ error });
    });
  }

  render() {
    const { error } = this.state;
    return (
      <div className="PdfFirstPage">
        <ErrorDisplay errors={[error]} type="bar"/>
        <canvas id="jnf-view-pdf-first-page-canvas"></canvas>
      </div>
    );
  }
}

export default ModalFaxReceipt;
