const sampleFaxData = [
  {
    received: "2019-02-13 00:22:29",
    urlsafe_key: "ykslIB2SLB7rxmPOePhkGkUI7hlSQxM6",
    media_url: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    num_pages: 19,
    fax_from: "+16023696544",
    is_downloaded: "True",
    is_paid_for: "True",
    is_usage_checked: "True",
    has_been_viewed: "True",
  },
  {
    received: "2019-01-13 00:22:29",
    urlsafe_key: "ykslIB2SLB7rxmPOePhkasdfdaI7hlSQxM6",
    media_url: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    num_pages: 19,
    fax_from: "+16023696544",
    is_downloaded: "True",
    is_paid_for: "False",
    is_usage_checked: "False",
    has_been_viewed: "True",
  },
  {
    received: "2018-12-13 00:22:29",
    urlsafe_key: "ykslIB2SLB7rxmPOePhkGkUI7hlSQxasd",
    media_url: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    num_pages: 19,
    fax_from: "+16023696544",
    is_downloaded: "True",
    is_paid_for: "False",
    is_usage_checked: "True",
    has_been_viewed: "True",
  },
  {
    received: "2018-09-13 00:22:29",
    urlsafe_key: "ykslIB2SLB7rxmPOePhkGkUI7hlSQxwq",
    media_url: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    num_pages: 19,
    fax_from: "+16023696544",
    is_downloaded: "False",
    is_paid_for: "True",
    is_usage_checked: "True",
    has_been_viewed: "True",
  },
  {
    received: "2018-08-13 00:22:29",
    urlsafe_key: "ykslIB2SLB7rxmPOePhkGkUI7hlSQxxcvb",
    media_url: "/sample-fax-files/Blue-Shield-small-business-guide-17pp.pdf",
    num_pages: 19,
    fax_from: "+16023696544",
    is_downloaded: "True",
    is_paid_for: "True",
    is_usage_checked: "True",
    has_been_viewed: "True",
  },
];

export default sampleFaxData;