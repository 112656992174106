import React from 'react';

function LoadingIndicator({ isLoading }) {
  // isLoading = true; // For debuggint: turn on all LoadingIndicators
  return (
    isLoading
      ?
      <div className={'LoadingIndicator has-text-centered ' + (isLoading ? 'is-loading' : '')}>
        <div>
          <i className="jnf-icon-pending" />
        </div>
      </div>
      :
      null
  );
}

export default LoadingIndicator;