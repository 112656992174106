const PAGES = Object.freeze({
  home: '/home',
  inbox: '/inbox',
  newFax: '/new-fax',
  store: '/store',
  storeCredits: '/store/credits',
  storeSubscriptions: '/store/subscriptions',
  settings: '/settings',
  settingsAccount: '/settings/account',
  settingsCoverPage: '/settings/cover-page',
  settingsSupport: '/settings/support',
  settingsApps: '/settings/apps',
  faxNumber: '/faxnumber',
  receipt: '/receipt',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  changeEmail: '/change-email',
  login: '/login',
  signup: '/signup',
  accountVerified: '/account-verified-confirmation',
  landing: '/',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
});

const APP_BASE_PATH = "/app/v3";

const INPUT_MAX_LENGTH = 250;

const LOCAL_MODE = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");

const APP_STORE_URLS = {
  iosFax: 'https://itunes.apple.com/us/app/jotnot-fax/id409184142',
  androidFax: 'https://play.google.com/store/apps/details?id=com.mobitech3000.jotnotfax.android',
  iosScannerPro: 'https://itunes.apple.com/US/app/id307868751',
  androidScannerPro: 'https://play.google.com/store/apps/details?id=com.mobitech3000.jotnotscanner.android',
  iosInvoice: 'https://itunes.apple.com/US/app/id1117521184',
};

export { APP_BASE_PATH, PAGES, INPUT_MAX_LENGTH, LOCAL_MODE, APP_STORE_URLS };