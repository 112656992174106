import React, { Component } from 'react';
import { StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements';
import ReactGA from 'react-ga';

import ErrorDisplay from './ErrorDisplay';
import StorePaymentMethodList from './StorePaymentMethodList';

import { ENDPOINTS, getURL, postData } from '../utils/fetching';
import { LOCAL_MODE, INPUT_MAX_LENGTH } from '../utils/constants';
import LoadingIndicator from '../utils/LoadingIndicator';

import samplePaymentMethodData from '../assets/samplePaymentMethodData2';

const stripe_account_key =
  (LOCAL_MODE || window.location.hostname === "dev-fax.jotnot.com")
  ? 'pk_test_O9LfDa9dEjAyILMc4V7H5ySp' : 'pk_live_7Q7Td4QK4ut4r9K4mSPzWUOJ';

class StoreCheckoutForm extends Component {

  state = {
    stripe: null,
    paymentMethods: null,
    isLoading: false,
    error: null,
    validationError: null,
  };

  componentDidMount() {
    // TODO:  move this loading even later?
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripe_account_key) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(stripe_account_key) });
      });
    }

    this.fetchPaymentMethods();
  }

  componentDidUpdate(prevProps, prevState) {
    const { paymentIntentSecret, handleAuthorizationResult } = this.props;
    if(paymentIntentSecret && paymentIntentSecret !== prevProps.paymentIntentSecret) {
      const { stripe } = this.state;
      if(!stripe) { return; }
      stripe.handleCardPayment(paymentIntentSecret)
        .then((result) => {
          handleAuthorizationResult && handleAuthorizationResult(result);
        }).catch((error) => {
          this.setState({ validationError: error })
        });
    }
  }

  fetchPaymentMethods = () => {
    this.setState({ isLoading: true });

    if(LOCAL_MODE) {
      this.setState({
        isLoading: false, error: null,
        paymentMethods: samplePaymentMethodData,
      });
      if(samplePaymentMethodData && samplePaymentMethodData.length > 0) {
        this.props.paymentMethodSelected && this.props.paymentMethodSelected(samplePaymentMethodData[0].id);
      }
      return Promise.resolve(samplePaymentMethodData);
    }

    return getURL(ENDPOINTS.paymentMethods)
      .then((json) => {
        this.setState({
          isLoading: false, error: null,
          paymentMethods: json.results,
        });
        return json.results;
      })
      .catch((error) => {
        this.setState({ isLoading: false, error });
        return this.state.paymentMethods;
      });
  };

  deletePaymentMethod = (pmID) => {

    this.setState({ isLoading: true });

    if(LOCAL_MODE) {
      const { paymentMethods: oldPMs } = this.state;
      console.debug("handleDeleteClicked: oldPMs:", oldPMs);
      const foundPM = !oldPMs ? null : oldPMs.find((pm) => {
        return pm.id === pmID;
      })
      if(!foundPM) {
        const error = pmID ? new Error('Could not find payment method with id ' + pmID) : null;
        this.setState({ isLoading: false, error });
        return;
      }

      const paymentMethods = !oldPMs ? null : oldPMs.filter((pm) => {
        return pm.id !== pmID;
      });

      this.setState({ isLoading: false, error: null, paymentMethods });
      return Promise.resolve(paymentMethods);
    }

    postData(ENDPOINTS.paymentMethods + "/" + pmID, null, "DELETE")
      .then((json) => {
        this.setState((prevState) => {
          const paymentMethods = !prevState.paymentMethods ? null : prevState.paymentMethods.filter((pm) => {
            return pm.id !== pmID;
          });
          return { isLoading: false, error: null, paymentMethods };
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false, error });
      });
  };

  addPaymentMethod = (paymentMethodID, billingName) => {
    const paymentMethod = {
      id: paymentMethodID,
      billing_details: {
        name: billingName,
        address: {
          postal_code: "XXXXX",
        },
      },
      card : {
        brand: "",
        last4: "",
        exp_month: "XX",
        exp_year: "XX",
      },
    };
    let isPMAlreadyAdded = false;
    this.setState((prevState) => {
      isPMAlreadyAdded = prevState.paymentMethods && undefined !== prevState.paymentMethods.find((pm) => {
        return pm && pm.id === paymentMethodID;
      });
      console.log("isPMAlreadyAdded in set state: ", isPMAlreadyAdded);
      const paymentMethods = isPMAlreadyAdded ? prevState.paymentMethods :
        (prevState.paymentMethods ? prevState.paymentMethods.concat([paymentMethod]): [paymentMethod]);
      return { paymentMethods };
    }, () => {
      // keep polling payment methods on server until new one shows up
      console.log("isPMAlreadyAdded in set state callback: ", isPMAlreadyAdded);
      if (!isPMAlreadyAdded) {
        setTimeout(() => {
          return this.fetchPaymentMethods()
            .then((paymentMethods) => {
              return this.addPaymentMethod(paymentMethodID, billingName);
            });
        }, 1000);
      } else {
        this.props.paymentMethodSelected && this.props.paymentMethodSelected(paymentMethodID);
      }
    });
  };

  render() {
    const { selectedPaymentMethodID, isPending, cartError, handlePurchaseClicked, paymentMethodSelected } = this.props;
    const { stripe, paymentMethods, isLoading, error, validationError } = this.state;
    const { addPaymentMethod, deletePaymentMethod } = this;

    const purchaseError = cartError ? cartError : validationError;

    return (
      <StripeProvider {...{stripe}}>
        <div>
          <StorePaymentMethodList {...{
            selectedPaymentMethodID, paymentMethods, isLoading, error,
            paymentMethodSelected, deletePaymentMethod
          }} />

          { selectedPaymentMethodID
            ?
              <div>
                <button
                  className="button jnf-primary-button jnf-full-width-button jnf-button-purchase"
                  type="button" disabled={isPending} onClick={handlePurchaseClicked}
                >Purchase</button>
                <div className="help is-danger">
                  <ErrorDisplay errors={[purchaseError]} />
                </div>
                <LoadingIndicator isLoading={isPending} />
              </div>
            :
              <div className="jnf-credit-card">
                <h2>Add New Card</h2>
                {/* stripe styling options here:  https://stripe.com/docs/stripe-js/reference */}
                <Elements>
                  <InjectedCheckoutForm {...this.props} {...{addPaymentMethod}} />
                </Elements>
              </div>
          }
        </div>
      </StripeProvider>
    );
  }
}


class _CheckoutFormElements extends Component {

  state = {
    billingName: '',
    validationError: null,
    nameError: null,
    isLoading: false,
    setupIntentSecret: null,
  };

  handleSaveClicked = (event) => {
    event.preventDefault();

    const { billingName, isLoading, setupIntentSecret } = this.state;

    if(isLoading) { return; }
    this.setState({ isLoading: true, validationError: null, nameError: null });

    if (!billingName || billingName.length < 1) {
      const nameError = new Error("Name is missing.  Please enter the name on your card.");
      this.setState({ nameError, isLoading: false });
      ReactGA.exception({ description: nameError.message });
      return;
    }

    if(setupIntentSecret) {
      this.handleCardSetup(setupIntentSecret);
      return;
    }

    postData(ENDPOINTS.paymentMethods)
      .then((json) => {
        if(json && json.client_secret) {
          this.setState({ setupIntentSecret: json.client_secret });
          return this.handleCardSetup(json.client_secret);
        }
        else {
          throw new Error("Unable to process card. Please try again.");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false, validationError: error });
      });
  };

  handleCardSetup = (setupIntentSecret) => {
    const { stripe } = this.props;
    const { billingName } = this.state;

    return stripe.handleCardSetup(
      setupIntentSecret, {
        payment_method_data: {
          billing_details: {name: billingName}
        }
    })
      .then((result) => {
        if(result.setupIntent && result.setupIntent.status === "canceled") {
          this.setState({ setupIntentSecret: null });
        }

        if(result.error) {
          throw new Error(result.error.message);
        } else if(result.setupIntent.payment_method) {
          this.setState({ setupIntentSecret: null, isLoading: false });
          this.props.addPaymentMethod(result.setupIntent.payment_method, billingName);
        } else {
          throw new Error("Unable to process card. Please try again.");
        }
      });
  };

  handleNameChange = (event) => {
    const newName = event.currentTarget.value;
    if (newName && newName.length > 0) {
      // clear nameError
      this.setState({ billingName: newName, nameError: null });
    } else {
      this.setState({ billingName: newName });
    }
  };

  handleCardChange = (event) => {
    this.setState({ validationError: event.error });
  };

  render() {

    const { billingName, isLoading, nameError, validationError } = this.state;

    const { handleNameChange, handleCardChange, handleSaveClicked } = this;

    const error = nameError ? nameError : validationError;

    console.log(nameError, validationError);

    const cardElementStyle = {
      // https://stripe.com/docs/stripe-js/reference#element-options
      base: {
        fontSize: '16px',
        color: '#363636',
        fontFamily: 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
      }
    };

    return (
      <form className="StoreCheckoutForm" onSubmit={handleSaveClicked}>

        <div className="field jnf-credit-card-name">
          <label className="label" htmlFor="name">Name</label>
          <input className="input" type="text" name="name"
            defaultValue={billingName} placeholder="Name on the card"
            onChange={handleNameChange} maxLength={INPUT_MAX_LENGTH} required disabled={isLoading} />
        </div>

        <div className="field jnf-credit-card-details">
          <label className="label">Card Number</label>
          <div className="input jnf-stripe-card-element">
            <CardElement onChange={handleCardChange} style={cardElementStyle} disabled={isLoading} />
          </div>
        </div>

        <button className="button jnf-primary-button jnf-full-width-button jnf-button-purchase" type="button" disabled={isLoading} onClick={handleSaveClicked}>Save Billing Information</button>

        <div className="help is-danger">
          <ErrorDisplay errors={[error]} />
        </div>
        <LoadingIndicator isLoading={isLoading} />
      </form>
    );
  }
}


const InjectedCheckoutForm = injectStripe(_CheckoutFormElements);


export default StoreCheckoutForm;
